.e-dropdownbase .e-list-item .e-list-icon {
  padding: 0 16px 0 0;
}

.e-small .e-dropdownbase .e-list-item .e-list-icon {
  padding: 0 12px 0 0;
}

.e-bigger.e-small .e-dropdownbase .e-list-item .e-list-icon {
  padding: 0 16px 0 0;
}

.e-bigger .e-dropdownbase,
.e-dropdownbase.e-bigger {
  min-height: 45px;
}
.e-bigger .e-dropdownbase .e-list-item,
.e-dropdownbase.e-bigger .e-list-item {
  line-height: 48px;
  text-indent: 16px;
}

.e-bigger .e-dropdownbase .e-list-group-item, .e-bigger .e-dropdownbase .e-fixed-head,
.e-dropdownbase.e-bigger .e-list-group-item,
.e-dropdownbase.e-bigger .e-fixed-head {
  line-height: 48px;
  text-indent: 16px;
}

.e-bigger .e-dropdownbase .e-list-item .e-list-icon,
.e-dropdownbase.e-bigger .e-list-item .e-list-icon {
  font-size: 20px;
}

.e-dropdownbase {
  display: block;
  height: 100%;
  min-height: 36px;
  position: relative;
  width: 100%;
}
.e-dropdownbase .e-list-parent {
  margin: 0;
  padding: 0;
}

.e-dropdownbase .e-list-group-item, .e-dropdownbase .e-fixed-head {
  cursor: default;
}

.e-dropdownbase .e-list-item {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.e-dropdownbase .e-list-item .e-list-icon {
  font-size: 16px;
  vertical-align: middle;
}

.e-dropdownbase .e-fixed-head {
  position: absolute;
  top: 0;
}

.e-dropdownbase.e-content {
  overflow: auto;
  position: relative;
}

.e-popup.e-ddl .e-dropdownbase.e-nodata,
.e-popup.e-mention .e-dropdownbase.e-nodata {
  color: #000;
  cursor: default;
  font-family: inherit;
  font-size: 14px;
  padding: 14px 16px;
  text-align: center;
}

.e-mention.e-popup {
  background: #fff;
  border: 0;
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.21);
  margin-top: 2px;
  position: absolute;
}

.e-mention .e-dropdownbase .e-list-item .e-highlight {
  display: inline;
  font-weight: bold;
  vertical-align: baseline;
}

.e-mention .e-mention-chip,
.e-mention .e-mention-chip:hover {
  background: #eee;
  border-radius: 2px;
  border: none;
  color: #e3165b;
  cursor: default;
}

.e-mention.e-editable-element {
  border: 2px solid #e0e0e0;
  height: auto;
  min-height: 120px;
  width: 100%;
}

.e-form-mirror-div {
  white-space: pre-wrap;
}

.e-rtl .e-dropdownbase.e-dd-group .e-list-item {
  padding-right: 2em;
}

.e-dropdownbase.e-dd-group .e-list-item {
  padding-left: 2em;
  text-indent: 0;
}

.e-small .e-dropdownbase.e-dd-group .e-list-item {
  padding-left: 2em;
}

.e-bigger .e-dropdownbase.e-dd-group .e-list-item {
  padding-left: 2em;
}

.e-small.e-bigger .e-dropdownbase.e-dd-group .e-list-item {
  padding-left: 2em;
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group .e-dropdownbase.e-dd-group .e-list-group-item {
  text-indent: 0;
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group .e-dropdownbase.e-dd-group .e-list-group-item {
  cursor: pointer;
  font-weight: normal;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.e-rtl.e-multiselect-group .e-dropdownbase.e-dd-group .e-list-item {
  padding-right: 2em;
}

.e-rtl .e-dropdownbase .e-list-item {
  padding-left: 16px;
  padding-right: 0;
}
.e-small.e-rtl .e-dropdownbase .e-list-item,
.e-small .e-rtl .e-dropdownbase .e-list-item,
.e-rtl .e-small .e-dropdownbase .e-list-item {
  padding-left: 16px;
  padding-right: 0;
}
.e-small.e-bigger.e-rtl .e-dropdownbase .e-list-item,
.e-small.e-bigger .e-rtl .e-dropdownbase .e-list-item,
.e-rtl .e-small.e-bigger .e-dropdownbase .e-list-item,
.e-small .e-rtl.e-bigger .e-dropdownbase .e-list-item,
.e-bigger .e-rtl.e-small .e-dropdownbase .e-list-item {
  padding-left: 16px;
  padding-right: 0;
}
.e-dropdownbase {
  border-color: #e0e0e0;
}
.e-dropdownbase .e-list-item {
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-tap-highlight-color: transparent;
  border-bottom: 0;
  border-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  font-family: inherit;
  font-size: 13px;
  line-height: 36px;
  min-height: 36px;
  padding-right: 16px;
  text-indent: 16px;
  background-color: #fff;
}

.e-dropdownbase .e-list-group-item,
.e-fixed-head {
  border-color: #fff;
  color: rgba(0, 0, 0, 0.54);
  font-family: inherit;
  font-size: 13px;
  font-weight: 600;
  line-height: 36px;
  min-height: 36px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #fff;
}

.e-dropdownbase .e-list-item.e-active, .e-dropdownbase .e-list-item.e-active.e-hover {
  background-color: #eee;
  border-color: #fff;
  color: #e3165b;
}

.e-dropdownbase .e-list-item.e-hover {
  background-color: #eee;
  border-color: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.e-dropdownbase .e-list-item:last-child {
  border-bottom: 0;
}

.e-dropdownbase .e-list-item.e-item-focus {
  background-color: #eee;
}

.e-bigger .e-dropdownbase .e-list-group-item, .e-bigger .e-dropdownbase .e-fixed-head {
  font-size: 14px;
  padding-left: 16px;
}

.e-multi-column.e-ddl.e-popup.e-popup-open table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.e-multi-column.e-ddl.e-popup.e-popup-open th,
.e-multi-column.e-ddl.e-popup.e-popup-open td {
  display: table-cell;
  overflow: hidden;
  padding-right: 16px;
  text-indent: 10px;
  text-overflow: ellipsis;
}

.e-multi-column.e-ddl.e-popup.e-popup-open th {
  line-height: 36px;
  text-align: left;
}

.e-multi-column.e-ddl.e-popup.e-popup-open .e-ddl-header {
  background-color: #fff;
  border-color: #e0e0e0;
  border-style: solid;
  border-width: 0 0 1px 0;
  color: rgba(0, 0, 0, 0.54);
  font-family: inherit;
  font-size: 13px;
  font-weight: 600;
  text-indent: 10px;
}

.e-multi-column.e-ddl.e-popup.e-popup-open .e-dropdownbase .e-list-item {
  padding-right: 0;
}

.e-multi-column.e-ddl.e-popup.e-popup-open.e-scroller .e-ddl-header {
  padding-right: 16px;
}

.e-multi-column.e-ddl.e-popup.e-popup-open .e-ddl-header,
.e-multi-column.e-ddl.e-popup.e-popup-open.e-ddl-device .e-ddl-header {
  padding-right: 0;
}

.e-multi-column.e-ddl.e-popup.e-popup-open .e-text-center {
  text-align: center;
}

.e-multi-column.e-ddl.e-popup.e-popup-open .e-text-right {
  text-align: right;
}

.e-multi-column.e-ddl.e-popup.e-popup-open .e-text-left {
  text-align: left;
}

.e-small .e-dropdownbase .e-list-item,
.e-dropdownbase.e-small .e-list-item {
  color: #000;
  line-height: 26px;
  min-height: 26px;
  text-indent: 12px;
}

.e-small .e-dropdownbase .e-list-group-item, .e-small .e-dropdownbase .e-fixed-head,
.e-dropdownbase.e-small .e-list-group-item,
.e-dropdownbase.e-small .e-fixed-head {
  font-size: 13px;
  line-height: 26px;
  min-height: 26px;
  padding-left: 16px;
}

.e-small .e-dropdownbase .e-list-item .e-list-icon,
.e-dropdownbase.e-small .e-list-item .e-list-icon {
  font-size: 14px;
}

.e-bigger.e-small .e-dropdownbase .e-list-item,
.e-dropdownbase.e-small.e-bigger .e-list-item {
  color: #000;
  line-height: 40px;
  min-height: 40px;
  text-indent: 16px;
}

.e-bigger.e-small .e-dropdownbase .e-list-group-item, .e-bigger.e-small .e-dropdownbase .e-fixed-head,
.e-dropdownbase.e-small.e-bigger .e-list-group-item,
.e-dropdownbase.e-small.e-bigger .e-fixed-head {
  line-height: 40px;
  min-height: 40px;
  padding-left: 16px;
}

.e-bigger.e-small .e-dropdownbase .e-list-item .e-list-icon,
.e-dropdownbase.e-small.e-bigger .e-list-item .e-list-icon {
  font-size: 18px;
}

.e-bigger.e-small .e-dropdownbase .e-list-group-item, .e-bigger.e-small .e-dropdownbase .e-fixed-head,
.e-dropdownbase.e-bigger.e-small .e-list-group-item,
.e-dropdownbase.e-bigger.e-small .e-fixed-head {
  font-size: 13px;
}

.e-ddl.e-popup.e-multiselect-group .e-list-group-item {
  background-color: #fff;
  border-bottom: 0;
  border-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  font-family: inherit;
  text-indent: 16px;
  font-size: 13px;
  padding-right: 16px;
}

.e-ddl.e-popup.e-multiselect-group .e-list-group-item.e-item-focus {
  background-color: #eee;
}

.e-ddl.e-popup.e-multiselect-group .e-list-group-item.e-active,
.e-ddl.e-popup.e-multiselect-group .e-list-group-item.e-active.e-hover {
  background-color: #eee;
  border-color: #fff;
  color: #e3165b;
}

.e-ddl.e-popup.e-multiselect-group .e-list-group-item.e-hover {
  background-color: #eee;
  border-color: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.e-selectall-parent.e-item-focus {
  background-color: #eee;
}

/* stylelint-disable property-no-vendor-prefix */
@keyframes e-input-ripple {
  100% {
    opacity: 0;
    transform: scale(4);
  }
}
@keyframes slideTopUp {
  from {
    transform: translate3d(0, 0, 0) scale(1);
  }
  to {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
/* stylelint-disable property-no-vendor-prefix */
@keyframes material-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fabric-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.e-ddl.e-control-wrapper .e-ddl-icon::before {
  transform: rotate(0deg);
  transition: transform 300ms ease;
}

.e-ddl.e-control-wrapper.e-icon-anim .e-ddl-icon::before {
  transform: rotate(180deg);
  transition: transform 300ms ease;
}

.e-dropdownbase .e-list-item.e-active.e-hover {
  color: #e3165b;
}

.e-input-group:not(.e-disabled) .e-control.e-dropdownlist ~ .e-ddl-icon:active,
.e-input-group:not(.e-disabled) .e-control.e-dropdownlist ~ .e-ddl-icon:hover,
.e-input-group:not(.e-disabled) .e-back-icon:active,
.e-input-group:not(.e-disabled) .e-back-icon:hover,
.e-popup.e-ddl .e-input-group:not(.e-disabled) .e-clear-icon:active,
.e-popup.e-ddl .e-input-group:not(.e-disabled) .e-clear-icon:hover {
  background: transparent;
}

.e-input-group .e-ddl-icon:not(:active)::after {
  animation: none;
}

.e-ddl.e-popup {
  border: 0;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  margin-top: 2px;
}

.e-popup.e-ddl .e-dropdownbase {
  min-height: 26px;
}

.e-bigger .e-popup.e-ddl-device-filter .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
  border-color: #e0e0e0;
}

.e-bigger .e-popup.e-ddl-device-filter {
  margin-top: 0;
}

.e-bigger .e-ddl-device .e-input-group,
.e-bigger .e-ddl-device .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
  background: #f5f5f5;
  border-width: 0;
  box-shadow: none;
  margin-bottom: 0;
}

.e-bigger .e-ddl-device .e-input-group .e-back-icon,
.e-bigger .e-ddl-device .e-input-group input.e-input,
.e-bigger .e-ddl-device .e-input-group .e-clear-icon {
  background-color: #f5f5f5;
}

.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon {
  margin: 6px 6px 5px;
  min-height: 12px;
  min-width: 12px;
  padding: 6px;
}

.e-bigger .e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
.e-bigger.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon {
  min-height: 16px;
  min-width: 16px;
}

.e-bigger .e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-filter,
.e-bigger.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-filter {
  padding: 8px 16px 8px 0;
}

.e-input-group.e-ddl,
.e-input-group.e-ddl .e-input,
.e-input-group.e-ddl .e-ddl-icon {
  background: transparent;
}

.e-ddl.e-ddl-device.e-ddl-device-filter .e-input-group:hover:not(.e-disabled):not(.e-float-icon-left),
.e-ddl.e-ddl-device.e-ddl-device-filter .e-input-group.e-control-wrapper:hover:not(.e-disabled):not(.e-float-icon-left) {
  border-bottom-width: 0;
}

.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-small .e-clear-icon,
.e-small .e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
.e-small.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-input-focus.e-small .e-clear-icon,
.e-small .e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-input-focus .e-clear-icon,
.e-small.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-input-focus .e-clear-icon {
  margin: 0;
}

.e-small .e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group .e-input-filter,
.e-small.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group .e-input-filter,
.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-small .e-input-filter,
.e-small .e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-input-focus .e-input-filter,
.e-small.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-input-focus .e-input-filter,
.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-small.e-input-focus .e-input-filter {
  padding: 5px 5px 5px 12px;
}

.e-bigger.e-small .e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
.e-bigger.e-small.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
.e-bigger .e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-small .e-clear-icon,
.e-bigger.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-small .e-clear-icon,
.e-small .e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-bigger .e-clear-icon,
.e-small.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-bigger .e-clear-icon,
.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-bigger.e-small .e-clear-icon {
  min-height: 18px;
  min-width: 18px;
}

.e-bigger.e-small .e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group .e-input-filter,
.e-bigger.e-small.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group .e-input-filter,
.e-bigger .e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-small .e-input-filter,
.e-bigger.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-small .e-input-filter,
.e-small .e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-bigger .e-input-filter,
.e-small.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-bigger .e-input-filter,
.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-bigger.e-small .e-input-filter {
  padding: 8px 16px;
}

.e-ddl.e-popup.e-outline .e-filter-parent {
  padding: 4px 8px;
}

.e-ddl .e-search-icon::before {
  content: "\e993";
}

.e-ddl .e-back-icon::before {
  content: "\e977";
}

.e-ddl.e-input-group.e-control-wrapper .e-ddl-icon::before {
  content: "\e969";
  font-family: "e-icons";
}

.e-bigger .e-input-group.e-ddl .e-input-filter, .e-bigger .e-input-group.e-ddl .e-input-filter:focus {
  margin-left: -20px;
}

.e-bigger .e-ddl.e-popup .e-list-item {
  font-size: 14px;
}
.e-bigger .e-ddl.e-popup .e-list-group-item {
  font-size: 14px;
}
.e-bigger .e-ddl.e-popup .e-input-group {
  padding: 4px 0;
}

.e-bigger.e-ddl.e-popup .e-list-item {
  font-size: 14px;
}
.e-bigger.e-ddl.e-popup .e-list-group-item {
  font-size: 14px;
}
.e-bigger.e-ddl.e-popup .e-input-group {
  padding: 4px 0;
}

.e-popup.e-wide-popup.e-ddl-device.e-popup-close {
  display: block;
  visibility: hidden;
}

.e-popup-full-page {
  bottom: 0;
  left: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  right: 0;
  top: 0;
}
.e-popup-full-page .e-popup-full-page.e-ddl.e-popup.e-ddl-device-filter {
  margin: 0;
}

.e-ddl.e-control-wrapper .e-ddl-disable-icon {
  position: relative;
}

.e-ddl.e-control-wrapper .e-ddl-disable-icon::before {
  content: "";
}

.e-ddl-device-filter .e-filter-parent {
  background-color: #f5f5f5;
}

/* stylelint-disable property-no-vendor-prefix */
.e-ddl input.e-input::-webkit-contacts-auto-fill-button {
  display: none;
  pointer-events: none;
  position: absolute;
  right: 0;
  visibility: hidden;
}

/* stylelint-enable property-no-vendor-prefix */
.e-filter-parent {
  border: 0;
  border-top-width: 0;
  box-shadow: 0 1.5px 5px -2px rgba(0, 0, 0, 0.3);
  display: block;
  padding: 0;
}

.e-ddl.e-input-group:not(.e-disabled) {
  cursor: pointer;
}

.e-ddl.e-popup.e-ddl-device-filter .e-input-group.e-input-focus::before, .e-ddl.e-popup.e-ddl-device-filter .e-input-group.e-input-focus::after {
  width: 0;
}

.e-ddl.e-popup {
  background: #fff;
  position: absolute;
}
.e-ddl.e-popup .e-search-icon {
  margin: 0;
  opacity: 0.57;
  padding: 12px 8px 8px;
}

.e-ddl.e-popup .e-filter-parent .e-back-icon {
  padding: 0 8px;
}

.e-ddl.e-popup.e-rtl .e-filter-parent .e-input-group.e-control-wrapper .e-input-filter, .e-ddl.e-popup .e-filter-parent .e-input-filter, .e-ddl.e-popup .e-filter-parent .e-input-filter:focus, .e-ddl.e-popup .e-filter-parent .e-input-group.e-input-focus .e-input-filter, .e-ddl.e-popup .e-filter-parent .e-input-group.e-control-wrapper.e-input-focus .e-input-filter {
  padding: 8px 16px 8px;
}

.e-ddl.e-popup .e-input-group {
  margin-bottom: 0;
}

.e-ddl.e-popup .e-ddl-footer, .e-ddl.e-popup .e-ddl-header {
  cursor: default;
}

/* stylelint-disable property-no-vendor-prefix */
.e-ddl.e-input-group .e-ddl-hidden,
.e-ddl.e-float-input .e-ddl-hidden {
  -webkit-appearance: initial;
  border: 0;
  height: 0;
  padding: 0;
  visibility: hidden;
  width: 0;
}

.e-ddl.e-input-group,
.e-ddl.e-input-group.e-input-focus:focus {
  outline: none;
}

.e-dropdownbase .e-list-item .e-highlight {
  display: inline;
  font-weight: bold;
  vertical-align: baseline;
}

.e-ddl.e-input-group input[readonly] ~ .e-clear-icon:not(.e-clear-icon-hide),
.e-float-input input[readonly] ~ .e-clear-icon:not(.e-clear-icon-hide),
.e-float-input.e-input-group input[readonly] ~ .e-clear-icon:not(.e-clear-icon-hide) {
  opacity: 1;
}

.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon,
.e-input-group input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon,
.e-input-group.e-control-wrapper input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon,
.e-float-input input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon,
.e-float-input.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon,
.e-float-input.e-input-group input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon,
.e-float-input.e-input-group.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon {
  display: -ms-flexbox;
  display: flex;
}

.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
.e-input-group input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
.e-input-group.e-control-wrapper input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
.e-float-input input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
.e-float-input.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
.e-float-input.e-input-group input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
.e-float-input.e-input-group.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide {
  display: none;
}

.e-input-group.e-static-clear input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
.e-input-group.e-static-clear.e-control-wrapper input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
.e-float-input.e-static-clear input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
.e-float-input.e-static-clear.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
.e-float-input.e-static-clear.e-input-group input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
.e-float-input.e-static-clear.e-input-group.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
}

.e-ddl.e-input-group .e-input-value,
.e-ddl.e-input-group .e-input-value:focus {
  font-family: inherit;
  font-size: 14px;
  height: auto;
  margin: 0;
  outline: none;
  width: 100%;
}
.e-ddl.e-input-group input[readonly].e-input,
.e-ddl.e-input-group input[readonly],
.e-ddl.e-input-group .e-dropdownlist {
  pointer-events: none;
}

ejs-autocomplete,
ejs-combobox,
ejs-dropdownlist {
  display: block;
}

.e-small .e-ddl.e-popup .e-list-item,
.e-input-group.e-ddl.e-small .e-list-item {
  font-size: 12px;
}
.e-small .e-ddl.e-popup .e-list-group-item,
.e-input-group.e-ddl.e-small .e-list-group-item {
  font-size: 12px;
}

.e-small.e-ddl.e-popup .e-list-item,
.e-input-group.e-ddl.e-small .e-list-item {
  font-size: 12px;
}
.e-small.e-ddl.e-popup .e-list-group-item,
.e-input-group.e-ddl.e-small .e-list-group-item {
  font-size: 12px;
}

.e-bigger.e-small .e-ddl.e-popup .e-list-item,
.e-bigger .e-input-group.e-ddl.e-small .e-list-item {
  font-size: 13px;
}
.e-bigger.e-small .e-ddl.e-popup .e-list-group-item,
.e-bigger .e-input-group.e-ddl.e-small .e-list-group-item {
  font-size: 13px;
}

.e-bigger.e-small.e-ddl.e-popup .e-list-item,
.e-bigger .e-input-group.e-ddl.e-small .e-list-item {
  font-size: 13px;
}
.e-bigger.e-small.e-ddl.e-popup .e-list-group-item,
.e-bigger .e-input-group.e-ddl.e-small .e-list-group-item {
  font-size: 13px;
}

.e-content-placeholder.e-ddl.e-placeholder-ddl,
.e-content-placeholder.e-autocomplete.e-placeholder-autocomplete,
.e-content-placeholder.e-combobox.e-placeholder-combobox {
  background-size: 300px 33px;
  min-height: 33px;
}

.e-bigger .e-content-placeholder.e-ddl.e-placeholder-ddl,
.e-bigger.e-content-placeholder.e-ddl.e-placeholder-ddl,
.e-bigger .e-content-placeholder.e-autocomplete.e-placeholder-autocomplete,
.e-bigger.e-content-placeholder.e-autocomplete.e-placeholder-autocomplete,
.e-bigger .e-content-placeholder.e-combobox.e-placeholder-combobox,
.e-bigger.e-content-placeholder.e-combobox.e-placeholder-combobox {
  background-size: 300px 40px;
  min-height: 40px;
}

.e-popup {
  border-color: #e0e0e0;
}

.e-float-input.e-input-group.e-ddl.e-control.e-icon-anim > .e-float-text,
.e-float-input.e-input-focus.e-input-group.e-ddl.e-control.e-keyboard > .e-float-text {
  color: #e3165b;
}

/* stylelint-disable property-no-vendor-prefix */
@keyframes e-input-ripple {
  100% {
    opacity: 0;
    transform: scale(4);
  }
}
@keyframes slideTopUp {
  from {
    transform: translate3d(0, 0, 0) scale(1);
  }
  to {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
/* stylelint-disable property-no-vendor-prefix */
@keyframes material-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fabric-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*! TreeView's material theme wise override definitions and variables */
/* stylelint-disable */
/* stylelint-disable */
.e-ddt .e-ddt-icon::before {
  content: "\e969";
}
.e-ddt .e-chips-close::before {
  content: "\e7e9";
}

.e-ddt {
  cursor: pointer;
  outline: none;
}
.e-ddt .e-ddt-icon::before {
  transform: rotate(0deg);
  transition: transform 300ms ease;
}
.e-ddt.e-icon-anim .e-ddt-icon::before {
  transform: rotate(180deg);
  transition: transform 300ms ease;
}
.e-ddt .e-ddt-hidden {
  border: 0;
  height: 0;
  visibility: hidden;
  width: 0;
}
.e-ddt.e-input-group.e-control-wrapper .e-clear-icon, .e-ddt.e-float-input.e-control-wrapper .e-clear-icon {
  box-sizing: content-box;
  min-height: 22px;
}
.e-ddt.e-input-group.e-control-wrapper .e-input-group-icon.e-ddt-icon, .e-ddt.e-float-input.e-control-wrapper .e-input-group-icon.e-ddt-icon {
  border: 0;
}
.e-ddt.e-input-group.e-control-wrapper .e-icon-hide, .e-ddt.e-float-input.e-control-wrapper .e-icon-hide {
  display: none;
}
.e-ddt.e-input-group.e-control-wrapper.e-show-chip .e-clear-icon, .e-ddt.e-input-group.e-control-wrapper.e-show-text .e-clear-icon, .e-ddt.e-float-input.e-control-wrapper.e-show-chip .e-clear-icon, .e-ddt.e-float-input.e-control-wrapper.e-show-text .e-clear-icon {
  bottom: 0;
  position: absolute;
  right: 0;
}
.e-ddt.e-input-group.e-control-wrapper.e-show-chip.e-show-dd-icon .e-clear-icon, .e-ddt.e-input-group.e-control-wrapper.e-show-text.e-show-dd-icon .e-clear-icon, .e-ddt.e-float-input.e-control-wrapper.e-show-chip.e-show-dd-icon .e-clear-icon, .e-ddt.e-float-input.e-control-wrapper.e-show-text.e-show-dd-icon .e-clear-icon {
  right: 18px;
}
.e-ddt.e-input-group.e-control-wrapper.e-show-chip .e-ddt-icon, .e-ddt.e-input-group.e-control-wrapper.e-show-text .e-ddt-icon, .e-ddt.e-float-input.e-control-wrapper.e-show-chip .e-ddt-icon, .e-ddt.e-float-input.e-control-wrapper.e-show-text .e-ddt-icon {
  bottom: 2px;
  position: absolute;
  right: 0;
}
.e-ddt.e-input-group.e-control-wrapper.e-show-chip.e-input-focus input.e-dropdowntree.e-chip-input,
.e-ddt.e-input-group.e-control-wrapper.e-show-chip input.e-dropdowntree.e-chip-input, .e-ddt.e-input-group.e-control-wrapper.e-show-text.e-input-focus input.e-dropdowntree.e-chip-input,
.e-ddt.e-input-group.e-control-wrapper.e-show-text input.e-dropdowntree.e-chip-input, .e-ddt.e-float-input.e-control-wrapper.e-show-chip.e-input-focus input.e-dropdowntree.e-chip-input,
.e-ddt.e-float-input.e-control-wrapper.e-show-chip input.e-dropdowntree.e-chip-input, .e-ddt.e-float-input.e-control-wrapper.e-show-text.e-input-focus input.e-dropdowntree.e-chip-input,
.e-ddt.e-float-input.e-control-wrapper.e-show-text input.e-dropdowntree.e-chip-input {
  padding-left: 0;
  padding-right: 0;
}
.e-ddt.e-input-group.e-control-wrapper.e-valid-input:hover:not(.e-disabled) .e-clear-icon, .e-ddt.e-float-input.e-control-wrapper.e-valid-input:hover:not(.e-disabled) .e-clear-icon {
  display: -ms-flexbox;
  display: flex;
}
.e-ddt .e-chips-wrapper {
  width: 100%;
}
.e-ddt.e-show-chip .e-chips-close {
  line-height: 16px;
  min-height: 16px;
  min-width: 16px;
  text-align: center;
}
.e-ddt.e-show-chip .e-chips-close::before {
  font-size: 16px;
}
.e-ddt.e-show-chip .e-chips {
  -ms-flex-align: center;
      align-items: center;
  border-radius: 16px;
  box-sizing: border-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  float: left;
  height: 24px;
  margin: 3px 1px;
  max-width: calc(100% - 2px);
  overflow: hidden;
  padding: 0 4px 0 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-ddt.e-show-chip .e-chips > .e-chipcontent {
  max-width: 100%;
  overflow: hidden;
  padding: 0 4px 0 4px;
  text-indent: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-ddt.e-outline .e-overflow.e-show-text {
  padding: 10px 12px 9px;
}
.e-ddt.e-outline .e-overflow.e-total-count {
  padding: 10px 12px;
}
.e-ddt.e-outline.e-input-group.e-control-wrapper.e-show-dd-icon .e-clear-icon, .e-ddt.e-outline.e-float-input.e-control-wrapper.e-show-dd-icon .e-clear-icon {
  right: 30px;
}
.e-ddt.e-outline.e-input-group.e-control-wrapper:not(.e-show-chip).e-show-dd-icon.e-input-focus .e-clear-icon, .e-ddt.e-outline.e-float-input.e-control-wrapper:not(.e-show-chip).e-show-dd-icon.e-input-focus .e-clear-icon {
  min-height: 20px;
}
.e-ddt.e-outline.e-show-chip .e-overflow {
  padding: 4px 12px;
}
.e-ddt.e-outline.e-show-chip .e-chips-wrapper {
  padding: 4px 12px;
}
.e-ddt.e-filled.e-float-input.e-control-wrapper .e-overflow.e-show-text {
  line-height: 21px;
  padding: 14px 2px 5px 0;
}
.e-ddt.e-filled.e-float-input.e-control-wrapper .e-overflow .e-remain.e-wrap-count {
  padding-top: 10px;
}
.e-ddt.e-filled.e-float-input.e-control-wrapper:not(.e-show-chip) .e-ddt-icon, .e-ddt.e-filled.e-float-input.e-control-wrapper:not(.e-show-chip).e-show-dd-icon .e-clear-icon {
  padding-top: 8px;
}
.e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-ddt-icon, .e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-text .e-ddt-icon {
  bottom: 6px;
  right: 10px;
}
.e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-clear-icon, .e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-text .e-clear-icon {
  bottom: 5px;
  right: 38px;
}
.e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip:not(.e-show-dd-icon) .e-clear-icon, .e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-text:not(.e-show-dd-icon) .e-clear-icon {
  bottom: 9px;
  right: 10px;
}
.e-ddt.e-filled .e-chips-wrapper .e-chips,
.e-ddt.e-filled .e-overflow .e-chips {
  margin-top: 8px;
  padding: 0 8px;
}
.e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-chips-wrapper,
.e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-overflow {
  padding-top: 8px;
}
.e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-chips-wrapper .e-chips,
.e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-overflow .e-chips {
  height: 18px;
}
.e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-chips-wrapper .e-chips > .e-chipcontent,
.e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-overflow .e-chips > .e-chipcontent {
  font-size: 12px;
  padding: 0 4px 0 0;
}
.e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-chips-wrapper .e-chips-close::before,
.e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-overflow .e-chips-close::before {
  font-size: 12px;
  height: 12px;
  width: 12px;
}
.e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-overflow.e-show-text {
  line-height: 39px;
  padding: 0;
}
.e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-overflow .e-remain.e-wrap-count {
  line-height: 39px;
  padding-top: 0;
}
.e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-chips-wrapper .e-chips,
.e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-overflow .e-chips {
  height: 28px;
  margin-top: 8px;
  padding: 0 8px;
}
.e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-chips-wrapper .e-chips > .e-chipcontent,
.e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-overflow .e-chips > .e-chipcontent {
  font-size: 13px;
  padding: 0 8px 0 0;
}
.e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-chips-wrapper .e-chips-close::before,
.e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-overflow .e-chips-close::before {
  font-size: 14px;
  height: 14px;
  width: 14px;
}
.e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon.e-show-text .e-ddt-icon, .e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon.e-show-chip .e-ddt-icon {
  bottom: 10px;
  right: 10px;
}
.e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-clear-icon {
  bottom: 9px;
  right: 38px;
}
.e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input):not(.e-show-dd-icon) .e-clear-icon {
  bottom: 9px;
  right: 10px;
}
.e-ddt .e-overflow {
  white-space: nowrap;
}
.e-ddt .e-overflow.e-show-text {
  padding: 6px 0 0 0;
}
.e-ddt .e-overflow.e-total-count {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.e-ddt .e-overflow.e-total-count .e-remain {
  padding: 0 16px 0 0;
}
.e-ddt .e-overflow.e-wrap-count {
  padding-top: 6px;
}
.e-ddt .e-overflow .e-remain {
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  padding: 0 0 0 16px;
}
.e-ddt .e-overflow .e-remain.e-wrap-count {
  padding-top: 6px;
}
.e-ddt.e-show-chip .e-overflow.e-total-count .e-remain {
  padding: 6px 0 0 0;
}
.e-ddt.e-show-dd-icon .e-chips-wrapper .e-chips:last-child {
  margin-right: 18px;
  max-width: calc(100% - 19px);
}
.e-ddt.e-show-clear .e-chips-wrapper .e-chips:last-child {
  margin-right: 26px;
  max-width: calc(100% - 27px);
}
.e-ddt.e-show-clear.e-show-dd-icon .e-chips-wrapper .e-chips:last-child {
  margin-right: 45px;
  max-width: calc(100% - 45px);
}
.e-ddt input[readonly],
.e-ddt .e-dropdowntree,
.e-ddt input[readonly].e-input {
  pointer-events: none;
}
.e-ddt input[readonly].e-chip-input,
.e-ddt .e-dropdowntree.e-chip-input,
.e-ddt input[readonly].e-input.e-chip-input {
  width: 0;
}
.e-ddt.e-popup {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 0px rgba(0, 0, 0, 0.14), 0 3px 14px 0px rgba(0, 0, 0, 0.12);
  position: absolute;
}
.e-ddt.e-popup .e-selectall-parent {
  border-bottom: 1px solid;
  cursor: pointer;
  display: block;
  line-height: 36px;
  overflow: hidden;
  position: relative;
  text-indent: 38px;
  white-space: nowrap;
  width: 100%;
}
.e-ddt.e-popup .e-selectall-parent.e-hide-selectall {
  display: none;
}
.e-ddt.e-popup .e-selectall-parent .e-checkbox-wrapper {
  position: relative;
  text-indent: 0;
}
.e-ddt.e-popup .e-selectall-parent .e-all-text {
  font-family: inherit;
  font-size: 14px;
  margin: 10px;
}
.e-ddt.e-popup .e-filter-wrap .e-input, .e-ddt.e-popup .e-filter-wrap .e-input:focus {
  padding: 8px 16px 8px;
}
.e-ddt.e-popup .e-filter-wrap {
  border: 0;
  border-top-width: 0;
  display: block;
  padding: 0;
}
.e-ddt.e-popup .e-filter-wrap .e-input-group:not(.e-disabled):not(.e-float-icon-left)::before,
.e-ddt.e-popup .e-filter-wrap .e-input-group:not(.e-disabled):not(.e-float-icon-left)::after,
.e-ddt.e-popup .e-filter-wrap .e-input-group.e-control-wrapper:not(.e-disabled):not(.e-float-icon-left)::before,
.e-ddt.e-popup .e-filter-wrap .e-input-group.e-control-wrapper:not(.e-disabled):not(.e-float-icon-left)::after {
  bottom: -1px;
  height: 1px;
}
.e-ddt.e-popup .e-filter-wrap .e-input-group {
  margin-bottom: 0;
}
.e-ddt.e-popup .e-filter-wrap .e-input-group .e-clear-icon {
  margin: 6px 6px 5px;
  min-height: 12px;
  min-width: 12px;
  padding: 6px;
}
.e-ddt.e-popup .e-popup-content {
  overflow: auto;
  position: relative;
}
.e-ddt.e-popup .e-popup-content .e-ddt-nodata {
  display: none;
}
.e-ddt.e-popup .e-popup-content.e-no-data {
  cursor: default;
  font-family: inherit;
  font-size: 14px;
  padding: 14px 16px;
  text-align: center;
}
.e-ddt.e-popup .e-popup-content.e-no-data .e-treeview {
  display: none;
}
.e-ddt.e-popup .e-popup-content.e-no-data .e-ddt-nodata {
  display: block;
}
.e-ddt.e-popup .e-treeview {
  display: inline-table;
  width: 100%;
}
.e-ddt.e-popup .e-treeview .e-list-item {
  padding: 0;
}
.e-ddt.e-popup .e-treeview .e-list-item .e-ul {
  margin: 0;
  padding: 0 0 0 14px;
}
.e-ddt.e-popup .e-treeview .e-fullrow {
  height: 36px;
}
.e-ddt.e-popup .e-treeview > .e-ul {
  overflow: hidden;
  padding: 0 8px;
}
.e-ddt.e-popup .e-treeview.e-fullrow-wrap .e-text-content {
  padding-bottom: 2px;
  padding-top: 2px;
}
.e-ddt.e-popup .e-ddt-footer,
.e-ddt.e-popup .e-ddt-header {
  cursor: default;
}
.e-ddt.e-rtl.e-show-chip .e-chips {
  float: right;
}
.e-ddt.e-rtl.e-show-chip .e-overflow .e-remain, .e-ddt.e-rtl.e-show-text .e-overflow .e-remain {
  padding: 0 16px 0 0;
}
.e-ddt.e-rtl.e-show-chip .e-overflow .e-remain.e-wrap-count, .e-ddt.e-rtl.e-show-text .e-overflow .e-remain.e-wrap-count {
  padding-top: 6px;
}
.e-ddt.e-rtl.e-show-chip .e-overflow.e-wrap-count, .e-ddt.e-rtl.e-show-text .e-overflow.e-wrap-count {
  position: absolute;
  right: auto;
}
.e-ddt.e-rtl.e-show-text .e-overflow.e-total-count .e-remain {
  padding: 0 0 0 16px;
}
.e-ddt.e-rtl.e-show-chip .e-overflow.e-total-count .e-remain {
  padding: 6px 0 0 0;
}
.e-ddt.e-rtl.e-outline.e-input-group.e-control-wrapper.e-show-dd-icon .e-clear-icon, .e-ddt.e-rtl.e-outline.e-input-group.e-control-wrapper.e-show-dd-icon.e-show-text .e-clear-icon, .e-ddt.e-rtl.e-outline.e-input-group.e-control-wrapper.e-show-dd-icon.e-show-chip .e-clear-icon, .e-ddt.e-rtl.e-outline.e-float-input.e-control-wrapper.e-show-dd-icon .e-clear-icon, .e-ddt.e-rtl.e-outline.e-float-input.e-control-wrapper.e-show-dd-icon.e-show-text .e-clear-icon, .e-ddt.e-rtl.e-outline.e-float-input.e-control-wrapper.e-show-dd-icon.e-show-chip .e-clear-icon {
  left: 30px;
}
.e-ddt.e-rtl.e-filled.e-float-input.e-control-wrapper .e-overflow.e-show-text {
  padding: 14px 0 5px 2px;
}
.e-ddt.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-ddt-icon, .e-ddt.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-text .e-ddt-icon {
  left: 10px;
  right: auto;
}
.e-ddt.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-chip.e-show-dd-icon .e-clear-icon, .e-ddt.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-text.e-show-dd-icon .e-clear-icon {
  left: 38px;
  right: auto;
}
.e-ddt.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-chip:not(.e-show-dd-icon) .e-clear-icon, .e-ddt.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-text:not(.e-show-dd-icon) .e-clear-icon {
  left: 10px;
  right: auto;
}
.e-ddt.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-chips-wrapper .e-chips > .e-chipcontent,
.e-ddt.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-overflow .e-chips > .e-chipcontent {
  padding: 0 0 0 4px;
}
.e-ddt.e-rtl.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-chips-wrapper .e-chips > .e-chipcontent,
.e-ddt.e-rtl.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-overflow .e-chips > .e-chipcontent {
  padding: 0 0 0 8px;
}
.e-ddt.e-rtl.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon.e-show-text .e-ddt-icon, .e-ddt.e-rtl.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon.e-show-chip .e-ddt-icon {
  left: 10px;
  right: auto;
}
.e-ddt.e-rtl.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon .e-clear-icon {
  left: 38px;
  right: auto;
}
.e-ddt.e-rtl.e-filled.e-input-group.e-control-wrapper:not(.e-float-input):not(.e-show-dd-icon) .e-clear-icon {
  left: 10px;
  right: auto;
}
.e-ddt.e-rtl.e-show-dd-icon .e-chips-wrapper .e-chips:last-child {
  margin-left: 18px;
  margin-right: 1px;
}
.e-ddt.e-rtl.e-show-clear .e-chips-wrapper .e-chips:last-child {
  margin-left: 26px;
  margin-right: 1px;
}
.e-ddt.e-rtl.e-show-clear.e-show-dd-icon .e-chips-wrapper .e-chips:last-child {
  margin-left: 45px;
  margin-right: 1px;
}
.e-ddt.e-rtl.e-input-group.e-control-wrapper.e-show-chip .e-clear-icon,
.e-ddt.e-rtl.e-input-group.e-control-wrapper.e-show-chip .e-ddt-icon, .e-ddt.e-rtl.e-input-group.e-control-wrapper.e-show-text .e-clear-icon,
.e-ddt.e-rtl.e-input-group.e-control-wrapper.e-show-text .e-ddt-icon, .e-ddt.e-rtl.e-float-input.e-control-wrapper.e-show-chip .e-clear-icon,
.e-ddt.e-rtl.e-float-input.e-control-wrapper.e-show-chip .e-ddt-icon, .e-ddt.e-rtl.e-float-input.e-control-wrapper.e-show-text .e-clear-icon,
.e-ddt.e-rtl.e-float-input.e-control-wrapper.e-show-text .e-ddt-icon {
  left: 0;
  right: auto;
}
.e-ddt.e-rtl.e-input-group.e-control-wrapper.e-show-chip.e-show-dd-icon .e-clear-icon, .e-ddt.e-rtl.e-input-group.e-control-wrapper.e-show-text.e-show-dd-icon .e-clear-icon, .e-ddt.e-rtl.e-float-input.e-control-wrapper.e-show-chip.e-show-dd-icon .e-clear-icon, .e-ddt.e-rtl.e-float-input.e-control-wrapper.e-show-text.e-show-dd-icon .e-clear-icon {
  left: 18px;
}
.e-ddt.e-rtl.e-popup .e-treeview .e-list-item .e-ul {
  padding: 0 14px 0 0;
}

.e-ddt-icon-hide {
  display: none;
}

.e-bigger .e-ddt .e-chips,
.e-ddt.e-bigger .e-chips {
  height: 31px;
}
.e-bigger .e-ddt.e-input-group.e-control-wrapper.e-show-chip.e-show-dd-icon .e-clear-icon, .e-bigger .e-ddt.e-input-group.e-control-wrapper.e-show-text.e-show-dd-icon .e-clear-icon, .e-bigger .e-ddt.e-float-input.e-control-wrapper.e-show-chip.e-show-dd-icon .e-clear-icon, .e-bigger .e-ddt.e-float-input.e-control-wrapper.e-show-text.e-show-dd-icon .e-clear-icon,
.e-ddt.e-bigger.e-input-group.e-control-wrapper.e-show-chip.e-show-dd-icon .e-clear-icon,
.e-ddt.e-bigger.e-input-group.e-control-wrapper.e-show-text.e-show-dd-icon .e-clear-icon,
.e-ddt.e-bigger.e-float-input.e-control-wrapper.e-show-chip.e-show-dd-icon .e-clear-icon,
.e-ddt.e-bigger.e-float-input.e-control-wrapper.e-show-text.e-show-dd-icon .e-clear-icon {
  right: 20px;
}
.e-bigger .e-ddt.e-input-group.e-control-wrapper.e-show-chip .e-ddt-icon, .e-bigger .e-ddt.e-input-group.e-control-wrapper.e-show-text .e-ddt-icon, .e-bigger .e-ddt.e-float-input.e-control-wrapper.e-show-chip .e-ddt-icon, .e-bigger .e-ddt.e-float-input.e-control-wrapper.e-show-text .e-ddt-icon,
.e-ddt.e-bigger.e-input-group.e-control-wrapper.e-show-chip .e-ddt-icon,
.e-ddt.e-bigger.e-input-group.e-control-wrapper.e-show-text .e-ddt-icon,
.e-ddt.e-bigger.e-float-input.e-control-wrapper.e-show-chip .e-ddt-icon,
.e-ddt.e-bigger.e-float-input.e-control-wrapper.e-show-text .e-ddt-icon {
  bottom: 3px;
}
.e-bigger .e-ddt.e-input-group.e-control-wrapper .e-clear-icon, .e-bigger .e-ddt.e-float-input.e-control-wrapper .e-clear-icon,
.e-ddt.e-bigger.e-input-group.e-control-wrapper .e-clear-icon,
.e-ddt.e-bigger.e-float-input.e-control-wrapper .e-clear-icon {
  min-height: 26px;
}
.e-bigger .e-ddt .e-overflow.e-show-text,
.e-ddt.e-bigger .e-overflow.e-show-text {
  padding: 8px 0 0 0;
}
.e-bigger .e-ddt.e-outline .e-overflow.e-show-text,
.e-ddt.e-bigger.e-outline .e-overflow.e-show-text {
  padding: 15px 16px;
}
.e-bigger .e-ddt.e-outline .e-overflow.e-total-count,
.e-ddt.e-bigger.e-outline .e-overflow.e-total-count {
  padding: 15px 16px;
}
.e-bigger .e-ddt.e-outline.e-input-group.e-control-wrapper.e-show-dd-icon .e-clear-icon, .e-bigger .e-ddt.e-outline.e-float-input.e-control-wrapper.e-show-dd-icon .e-clear-icon,
.e-ddt.e-bigger.e-outline.e-input-group.e-control-wrapper.e-show-dd-icon .e-clear-icon,
.e-ddt.e-bigger.e-outline.e-float-input.e-control-wrapper.e-show-dd-icon .e-clear-icon {
  right: 36px;
}
.e-bigger .e-ddt.e-outline.e-show-chip .e-overflow,
.e-ddt.e-bigger.e-outline.e-show-chip .e-overflow {
  padding: 8px 15px;
}
.e-bigger .e-ddt.e-outline.e-show-chip .e-overflow.e-total-count .e-remain,
.e-ddt.e-bigger.e-outline.e-show-chip .e-overflow.e-total-count .e-remain {
  padding-top: 10px;
}
.e-bigger .e-ddt.e-outline.e-show-chip .e-overflow .e-remain.e-wrap-count,
.e-ddt.e-bigger.e-outline.e-show-chip .e-overflow .e-remain.e-wrap-count {
  padding-top: 10px;
}
.e-bigger .e-ddt.e-outline.e-show-chip .e-chips-wrapper,
.e-ddt.e-bigger.e-outline.e-show-chip .e-chips-wrapper {
  padding: 8px 15px;
}
.e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper .e-overflow.e-show-text,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper .e-overflow.e-show-text {
  line-height: 30px;
  padding: 19px 12px 0 0;
}
.e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper .e-overflow .e-remain.e-wrap-count,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper .e-overflow .e-remain.e-wrap-count {
  padding-top: 14px;
}
.e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper:not(.e-show-chip) .e-ddt-icon, .e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper:not(.e-show-chip).e-show-dd-icon .e-clear-icon,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper:not(.e-show-chip) .e-ddt-icon,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper:not(.e-show-chip).e-show-dd-icon .e-clear-icon {
  padding-top: 8px;
}
.e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-ddt-icon, .e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-text .e-ddt-icon,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-ddt-icon,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper.e-show-text .e-ddt-icon {
  bottom: 12px;
  right: 12px;
}
.e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-clear-icon, .e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-text .e-clear-icon,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-clear-icon,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper.e-show-text .e-clear-icon {
  bottom: 11px;
  right: 44px;
}
.e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip:not(.e-show-dd-icon) .e-clear-icon, .e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-text:not(.e-show-dd-icon) .e-clear-icon,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper.e-show-chip:not(.e-show-dd-icon) .e-clear-icon,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper.e-show-text:not(.e-show-dd-icon) .e-clear-icon {
  bottom: 14px;
  right: 12px;
}
.e-bigger .e-ddt.e-filled .e-chips-wrapper .e-chips,
.e-bigger .e-ddt.e-filled .e-overflow .e-chips,
.e-ddt.e-bigger.e-filled .e-chips-wrapper .e-chips,
.e-ddt.e-bigger.e-filled .e-overflow .e-chips {
  margin-top: 8px;
  padding: 0 8px;
}
.e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-chips-wrapper,
.e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-overflow,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-chips-wrapper,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-overflow {
  padding-top: 19px;
}
.e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-chips-wrapper .e-chips,
.e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-overflow .e-chips,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-chips-wrapper .e-chips,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-overflow .e-chips {
  height: 24px;
}
.e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-chips-wrapper .e-chips > .e-chipcontent,
.e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-overflow .e-chips > .e-chipcontent,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-chips-wrapper .e-chips > .e-chipcontent,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-overflow .e-chips > .e-chipcontent {
  font-size: 13px;
}
.e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-chips-wrapper .e-chips-close::before,
.e-bigger .e-ddt.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-overflow .e-chips-close::before,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-chips-wrapper .e-chips-close::before,
.e-ddt.e-bigger.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-overflow .e-chips-close::before {
  font-size: 14px;
  height: 14px;
  width: 14px;
}
.e-bigger .e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-overflow.e-show-text,
.e-ddt.e-bigger.e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-overflow.e-show-text {
  line-height: 55px;
}
.e-bigger .e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-overflow .e-remain.e-wrap-count,
.e-ddt.e-bigger.e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-overflow .e-remain.e-wrap-count {
  line-height: 55px;
  padding-top: 0;
}
.e-bigger .e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-chips-wrapper .e-chips,
.e-bigger .e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-overflow .e-chips,
.e-ddt.e-bigger.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-chips-wrapper .e-chips,
.e-ddt.e-bigger.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-overflow .e-chips {
  height: 32px;
  padding: 0 12px;
}
.e-bigger .e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-chips-wrapper .e-chips > .e-chipcontent,
.e-bigger .e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-overflow .e-chips > .e-chipcontent,
.e-ddt.e-bigger.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-chips-wrapper .e-chips > .e-chipcontent,
.e-ddt.e-bigger.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-overflow .e-chips > .e-chipcontent {
  font-size: 14px;
}
.e-bigger .e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-chips-wrapper .e-chips-close::before,
.e-bigger .e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-overflow .e-chips-close::before,
.e-ddt.e-bigger.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-chips-wrapper .e-chips-close::before,
.e-ddt.e-bigger.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-chip .e-overflow .e-chips-close::before {
  font-size: 16px;
  height: 16px;
  width: 16px;
}
.e-bigger .e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon.e-show-text .e-ddt-icon, .e-bigger .e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon.e-show-text:not(.e-show-dd-icon) .e-clear-icon, .e-bigger .e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon.e-show-chip .e-ddt-icon, .e-bigger .e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon.e-show-chip:not(.e-show-dd-icon) .e-clear-icon,
.e-ddt.e-bigger.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon.e-show-text .e-ddt-icon,
.e-ddt.e-bigger.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon.e-show-text:not(.e-show-dd-icon) .e-clear-icon,
.e-ddt.e-bigger.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon.e-show-chip .e-ddt-icon,
.e-ddt.e-bigger.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon.e-show-chip:not(.e-show-dd-icon) .e-clear-icon {
  bottom: 16px;
  right: 12px;
}
.e-bigger .e-ddt.e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-clear-icon,
.e-ddt.e-bigger.e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-clear-icon {
  bottom: 15px;
  right: 44px;
}
.e-bigger .e-ddt.e-show-dd-icon .e-chips-wrapper .e-chips:last-child,
.e-ddt.e-bigger.e-show-dd-icon .e-chips-wrapper .e-chips:last-child {
  margin-right: 20px;
  max-width: calc(100% - 21px);
}
.e-bigger .e-ddt.e-show-clear .e-chips-wrapper .e-chips:last-child,
.e-ddt.e-bigger.e-show-clear .e-chips-wrapper .e-chips:last-child {
  margin-right: 32px;
  max-width: calc(100% - 33px);
}
.e-bigger .e-ddt.e-show-clear.e-show-dd-icon .e-chips-wrapper .e-chips:last-child,
.e-ddt.e-bigger.e-show-clear.e-show-dd-icon .e-chips-wrapper .e-chips:last-child {
  margin-right: 52px;
  max-width: calc(100% - 53px);
}
.e-bigger .e-ddt.e-popup .e-selectall-parent,
.e-ddt.e-bigger.e-popup .e-selectall-parent {
  text-indent: 43px;
}
.e-bigger .e-ddt.e-popup .e-treeview .e-fullrow,
.e-ddt.e-bigger.e-popup .e-treeview .e-fullrow {
  height: 44px;
}
.e-bigger .e-ddt.e-rtl.e-outline.e-input-group.e-control-wrapper.e-show-dd-icon .e-clear-icon, .e-bigger .e-ddt.e-rtl.e-outline.e-input-group.e-control-wrapper.e-show-dd-icon.e-show-text .e-clear-icon, .e-bigger .e-ddt.e-rtl.e-outline.e-input-group.e-control-wrapper.e-show-dd-icon.e-show-chip .e-clear-icon, .e-bigger .e-ddt.e-rtl.e-outline.e-float-input.e-control-wrapper.e-show-dd-icon .e-clear-icon, .e-bigger .e-ddt.e-rtl.e-outline.e-float-input.e-control-wrapper.e-show-dd-icon.e-show-text .e-clear-icon, .e-bigger .e-ddt.e-rtl.e-outline.e-float-input.e-control-wrapper.e-show-dd-icon.e-show-chip .e-clear-icon,
.e-ddt.e-bigger.e-rtl.e-outline.e-input-group.e-control-wrapper.e-show-dd-icon .e-clear-icon,
.e-ddt.e-bigger.e-rtl.e-outline.e-input-group.e-control-wrapper.e-show-dd-icon.e-show-text .e-clear-icon,
.e-ddt.e-bigger.e-rtl.e-outline.e-input-group.e-control-wrapper.e-show-dd-icon.e-show-chip .e-clear-icon,
.e-ddt.e-bigger.e-rtl.e-outline.e-float-input.e-control-wrapper.e-show-dd-icon .e-clear-icon,
.e-ddt.e-bigger.e-rtl.e-outline.e-float-input.e-control-wrapper.e-show-dd-icon.e-show-text .e-clear-icon,
.e-ddt.e-bigger.e-rtl.e-outline.e-float-input.e-control-wrapper.e-show-dd-icon.e-show-chip .e-clear-icon {
  left: 36px;
}
.e-bigger .e-ddt.e-rtl.e-filled.e-float-input.e-control-wrapper .e-overflow.e-show-text,
.e-ddt.e-bigger.e-rtl.e-filled.e-float-input.e-control-wrapper .e-overflow.e-show-text {
  padding: 19px 0 0 12px;
}
.e-bigger .e-ddt.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-ddt-icon, .e-bigger .e-ddt.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-chip:not(.e-show-dd-icon) .e-clear-icon, .e-bigger .e-ddt.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-text .e-ddt-icon, .e-bigger .e-ddt.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-text:not(.e-show-dd-icon) .e-clear-icon,
.e-ddt.e-bigger.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-chip .e-ddt-icon,
.e-ddt.e-bigger.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-chip:not(.e-show-dd-icon) .e-clear-icon,
.e-ddt.e-bigger.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-text .e-ddt-icon,
.e-ddt.e-bigger.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-text:not(.e-show-dd-icon) .e-clear-icon {
  left: 12px;
  right: auto;
}
.e-bigger .e-ddt.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-chip.e-show-dd-icon .e-clear-icon, .e-bigger .e-ddt.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-text.e-show-dd-icon .e-clear-icon,
.e-ddt.e-bigger.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-chip.e-show-dd-icon .e-clear-icon,
.e-ddt.e-bigger.e-rtl.e-filled.e-float-input.e-control-wrapper.e-show-text.e-show-dd-icon .e-clear-icon {
  left: 44px;
  right: auto;
}
.e-bigger .e-ddt.e-rtl.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon:not(.e-input-focus) .e-ddt-icon, .e-bigger .e-ddt.e-rtl.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon:not(.e-input-focus):not(.e-show-dd-icon) .e-clear-icon, .e-bigger .e-ddt.e-rtl.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon.e-show-chip .e-ddt-icon, .e-bigger .e-ddt.e-rtl.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon.e-show-chip:not(.e-show-dd-icon) .e-clear-icon,
.e-ddt.e-bigger.e-rtl.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon:not(.e-input-focus) .e-ddt-icon,
.e-ddt.e-bigger.e-rtl.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon:not(.e-input-focus):not(.e-show-dd-icon) .e-clear-icon,
.e-ddt.e-bigger.e-rtl.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon.e-show-chip .e-ddt-icon,
.e-ddt.e-bigger.e-rtl.e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-show-dd-icon.e-show-chip:not(.e-show-dd-icon) .e-clear-icon {
  left: 12px;
  right: auto;
}
.e-bigger .e-ddt.e-rtl.e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-clear-icon,
.e-ddt.e-bigger.e-rtl.e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-clear-icon {
  left: 44px;
  right: auto;
}
.e-bigger .e-ddt.e-rtl.e-show-dd-icon .e-chips-wrapper .e-chips:last-child,
.e-ddt.e-bigger.e-rtl.e-show-dd-icon .e-chips-wrapper .e-chips:last-child {
  margin-left: 20px;
  margin-right: 1px;
}
.e-bigger .e-ddt.e-rtl.e-show-clear .e-chips-wrapper .e-chips:last-child,
.e-ddt.e-bigger.e-rtl.e-show-clear .e-chips-wrapper .e-chips:last-child {
  margin-left: 32px;
  margin-right: 1px;
}
.e-bigger .e-ddt.e-rtl.e-show-clear.e-show-dd-icon .e-chips-wrapper .e-chips:last-child,
.e-ddt.e-bigger.e-rtl.e-show-clear.e-show-dd-icon .e-chips-wrapper .e-chips:last-child {
  margin-left: 52px;
  margin-right: 1px;
}
.e-bigger .e-ddt.e-rtl.e-show-chip.e-input-group.e-show-dd-icon .e-clear-icon, .e-bigger .e-ddt.e-rtl.e-show-text.e-input-group.e-show-dd-icon .e-clear-icon,
.e-ddt.e-bigger.e-rtl.e-show-chip.e-input-group.e-show-dd-icon .e-clear-icon,
.e-ddt.e-bigger.e-rtl.e-show-text.e-input-group.e-show-dd-icon .e-clear-icon {
  left: 20px;
  right: auto;
}

.e-ddt .e-chips {
  background-color: #eee;
}
.e-ddt .e-chips .e-chips-close::before {
  color: rgba(0, 0, 0, 0.54);
}
.e-ddt .e-chips > .e-chipcontent {
  color: rgba(0, 0, 0, 0.87);
}
.e-ddt .e-chips:hover {
  background-color: #d6d6d6;
}
.e-ddt .e-chips:hover > .e-chipcontent {
  color: rgba(0, 0, 0, 0.87);
}
.e-ddt.e-filled .e-chips {
  background-color: #cecece;
}
.e-ddt .e-overflow .e-remain {
  color: rgba(0, 0, 0, 0.54);
}
.e-ddt.e-popup {
  background: #fff;
  border-color: transparent;
}
.e-ddt.e-popup .e-popup-content.e-no-data {
  color: #000;
}
.e-ddt.e-popup .e-selectall-parent {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.e-ddt.e-popup .e-selectall-parent:focus {
  background: #f5f5f5;
}
.e-ddt.e-popup .e-selectall-parent .e-all-text {
  color: rgba(0, 0, 0, 0.87);
}

/* stylelint-disable-line no-empty-source */ /* stylelint-disable-line no-empty-source */
/* stylelint-disable property-no-vendor-prefix */
@keyframes e-input-ripple {
  100% {
    opacity: 0;
    transform: scale(4);
  }
}
@keyframes slideTopUp {
  from {
    transform: translate3d(0, 0, 0) scale(1);
  }
  to {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
/* stylelint-disable property-no-vendor-prefix */
@keyframes material-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fabric-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.e-multiselect.e-input-group.e-checkbox .e-multi-select-wrapper input[type=text] {
  padding: 1px 0;
}

.e-small .e-multiselect.e-input-group.e-checkbox .e-multi-select-wrapper input[type=text],
.e-small.e-multiselect.e-input-group.e-checkbox .e-multi-select-wrapper input[type=text] {
  padding: 0;
}

.e-multiselect.e-input-group .e-ddl-icon::before {
  content: "\e969";
  font-family: "e-icons";
}

.e-multi-select-wrapper .e-chips .e-chips-close::before {
  content: "\e7e9";
  cursor: pointer;
  left: 0;
  position: relative;
  top: 0;
}

.e-multi-select-wrapper .e-close-hooker::before {
  content: "\e7a7";
  cursor: pointer;
  left: 6px;
  position: relative;
  top: 6px;
}

.e-multiselect.e-input-group .e-ddl-disable-icon::before {
  content: "";
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  height: 38px;
  margin-top: -38px;
  right: 18px;
  top: 100%;
  width: 32px;
}

.e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  height: 38px;
  margin-top: -35px;
  right: 18px;
  top: 100%;
  width: 32px;
}

.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker {
  right: 30px;
}

.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker {
  right: 30px;
}

.e-bigger.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  height: 38px;
  margin-top: -55px;
  right: 12px;
  top: 100%;
  width: 18px;
}

.e-bigger.e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-bigger.e-small .e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-bigger.e-small.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-bigger.e-small .e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-bigger .e-small.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-small .e-bigger.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker {
  right: 48px;
}

.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  height: 54px;
  margin-top: -55px;
  right: 16px;
  top: 100%;
  width: 20px;
}

.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker {
  right: 60px;
}

.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  height: 34px;
  margin-top: -35px;
  right: 16px;
  top: 100%;
  width: 14px;
}

.e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-small.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-small .e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker {
  right: 48px;
}

.e-rtl.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl .e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-rtl .e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker {
  left: 52px;
  right: auto;
}

.e-rtl.e-bigger.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl.e-bigger.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl .e-bigger.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger.e-small .e-rtl.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl .e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger .e-rtl.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger .e-rtl .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl .e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-small .e-rtl .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-small .e-rtl.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl.e-bigger.e-small.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-rtl.e-bigger.e-small .e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-rtl .e-bigger.e-small.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-bigger.e-small .e-rtl.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-rtl.e-bigger .e-small.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-rtl .e-bigger .e-small.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-bigger .e-rtl.e-small.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-bigger .e-rtl .e-small.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-rtl.e-small .e-bigger.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-rtl .e-small .e-bigger.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-small .e-rtl .e-bigger.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-small .e-rtl.e-bigger.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker {
  left: 48px;
  right: auto;
}

.e-rtl.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl .e-bigger .e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger .e-rtl.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger .e-rtl .e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl.e-bigger.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-rtl.e-bigger .e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-rtl .e-bigger.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-rtl .e-bigger .e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-bigger .e-rtl.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-bigger .e-rtl .e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker {
  left: 60px;
  right: auto;
}

.e-rtl.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl .e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-small .e-rtl.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-small .e-rtl .e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-rtl.e-small.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-rtl.e-small .e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-rtl .e-small.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-rtl .e-small .e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-small .e-rtl.e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-small .e-rtl .e-multiselect.e-filled .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker {
  left: 48px;
  right: auto;
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker::before {
  content: "\e208";
  font-size: 16px;
  height: 16px;
  margin-top: -8px;
  top: 50%;
}

.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker::before,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker::before {
  content: "\e208";
  font-size: 14px;
  height: 14px;
  margin-top: -7px;
  top: 50%;
}

.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker::before,
.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker::before {
  content: "\e208";
  font-size: 20px;
  height: 20px;
  left: 0;
  margin-top: -10px;
  top: 50%;
}

.e-bigger.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker::before,
.e-bigger.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker::before,
.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker::before,
.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker::before {
  content: "\e208";
  font-size: 18px;
  height: 18px;
  left: 0;
  margin-top: -9px;
  top: 50%;
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips .e-chips-close::before {
  font-size: 14px;
}

.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips .e-chips-close::before {
  font-size: 13px;
}

.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-chips .e-chips-close::before {
  font-size: 16px;
}

.e-bigger.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-bigger.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-chips .e-chips-close::before {
  font-size: 14px;
}

.e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-multiselect.e-outline.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
  margin: 7px 12px 9px 0;
}

.e-multiselect.e-input-group.e-outline.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-bigger .e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-multiselect.e-input-group.e-outline.e-control-wrapper.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-bigger .e-multiselect.e-input-group.e-outline.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
  margin: 10px 16px 10px 0;
}

.e-multiselect.e-input-group.e-outline.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-multiselect.e-input-group.e-outline.e-control-wrapper.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-small .e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-small .e-multiselect.e-input-group.e-outline.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
  margin: 6px 10px 8px 0;
}

.e-multiselect.e-input-group.e-outline.e-small.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-bigger.e-small .e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-multiselect.e-input-group.e-outline.e-control-wrapper.e-small.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-small.e-bigger .e-multiselect.e-input-group.e-outline.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-bigger .e-multiselect.e-input-group.e-outline.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-bigger .e-multiselect.e-input-group.e-outline.e-control-wrapper.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-small .e-multiselect.e-input-group.e-outline.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-small .e-multiselect.e-input-group.e-outline.e-control-wrapper.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
  margin: 6px 12px 8px 0;
}

.e-rtl.e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl.e-multiselect.e-outline.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl .e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl .e-multiselect.e-outline.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
  margin: 7px 0 9px 12px;
}

.e-rtl.e-multiselect.e-input-group.e-outline.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-bigger .e-rtl.e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl.e-multiselect.e-input-group.e-outline.e-control-wrapper.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-bigger .e-rtl.e-multiselect.e-input-group.e-outline.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl .e-multiselect.e-input-group.e-outline.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-bigger.e-rtl .e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl .e-multiselect.e-input-group.e-outline.e-control-wrapper.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-bigger.e-rtl .e-multiselect.e-input-group.e-outline.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
  margin: 10px 0 10px 16px;
}

.e-rtl.e-multiselect.e-input-group.e-outline.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl.e-multiselect.e-input-group.e-outline.e-control-wrapper.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-small .e-rtl.e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-small .e-rtl.e-multiselect.e-input-group.e-outline.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl .e-multiselect.e-input-group.e-outline.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl .e-multiselect.e-input-group.e-outline.e-control-wrapper.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl.e-small .e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl.e-small .e-multiselect.e-input-group.e-outline.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
  margin: 6px 0 8px 10px;
}

.e-rtl.e-multiselect.e-input-group.e-outline.e-small.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-bigger.e-small .e-rtl.e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl.e-multiselect.e-input-group.e-outline.e-control-wrapper.e-small.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-small.e-bigger .e-rtl.e-multiselect.e-input-group.e-outline.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-bigger .e-rtl.e-multiselect.e-input-group.e-outline.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-bigger .e-rtl.e-multiselect.e-input-group.e-outline.e-control-wrapper.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-small .e-rtl.e-multiselect.e-input-group.e-outline.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-small .e-rtl.e-multiselect.e-input-group.e-outline.e-control-wrapper.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl .e-multiselect.e-input-group.e-outline.e-small.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl.e-bigger.e-small .e-multiselect.e-input-group.e-outline .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl .e-multiselect.e-input-group.e-outline.e-control-wrapper.e-small.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl.e-small.e-bigger .e-multiselect.e-input-group.e-outline.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl.e-bigger .e-multiselect.e-input-group.e-outline.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl.e-bigger .e-multiselect.e-input-group.e-outline.e-control-wrapper.e-small .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl.e-small .e-multiselect.e-input-group.e-outline.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-rtl.e-small .e-multiselect.e-input-group.e-outline.e-control-wrapper.e-bigger .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
  margin: 6px 0 8px 12px;
}

.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-filled.e-multiselect .e-down-icon .e-chips-close.e-close-hooker,
.e-bigger.e-small.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger.e-small .e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger .e-small.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-small .e-bigger.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  height: 20px;
  margin-top: -33px;
  right: 18px;
  width: 20px;
}

.e-bigger.e-small.e-filled.e-multiselect .e-down-icon .e-chips-close.e-close-hooker,
.e-bigger.e-small .e-filled.e-multiselect .e-down-icon .e-chips-close.e-close-hooker,
.e-bigger .e-small.e-filled.e-multiselect .e-down-icon .e-chips-close.e-close-hooker,
.e-small .e-bigger.e-filled.e-multiselect .e-down-icon .e-chips-close.e-close-hooker {
  height: 20px;
  margin-top: -35px;
  right: 45px;
  width: 20px;
}

.e-filled.e-float-input .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-filled.e-float-input.e-multiselect .e-down-icon .e-chips-close.e-close-hooker,
.e-bigger.e-small.e-filled.e-float-input .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger.e-small .e-filled.e-float-input .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger .e-small.e-filled.e-float-input .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-small .e-bigger.e-filled.e-float-input .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger.e-small.e-filled.e-float-input.e-multiselect .e-down-icon .e-chips-close.e-close-hooker,
.e-bigger.e-small .e-filled.e-float-input.e-multiselect .e-down-icon .e-chips-close.e-close-hooker,
.e-bigger .e-small.e-filled.e-float-input.e-multiselect .e-down-icon .e-chips-close.e-close-hooker,
.e-small .e-bigger.e-filled.e-float-input.e-multiselect .e-down-icon .e-chips-close.e-close-hooker {
  margin-top: -32px;
}

.e-small.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-small .e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  height: 18px;
  margin: -29px 0 8px;
  width: 18px;
}

.e-bigger.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger .e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  height: 24px;
  width: 24px;
}

.e-small.e-filled.e-float-input .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-small .e-filled.e-float-input.e-multiselect .e-down-icon .e-chips-close.e-close-hooker {
  margin-top: -26px;
}

.e-bigger.e-filled.e-float-input .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger .e-filled.e-float-input.e-multiselect .e-down-icon .e-chips-close.e-close-hooker {
  margin-top: -40px;
}

.e-bigger.e-small.e-filled.e-float-input.e-multiselect .e-down-icon .e-chips-close.e-close-hooker,
.e-bigger.e-small .e-filled.e-float-input.e-multiselect .e-down-icon .e-chips-close.e-close-hooker,
.e-bigger .e-small.e-filled.e-float-input.e-multiselect .e-down-icon .e-chips-close.e-close-hooker,
.e-small .e-bigger.e-filled.e-float-input.e-multiselect .e-down-icon .e-chips-close.e-close-hooker,
.e-bigger.e-small.e-filled.e-float-input .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger.e-small .e-filled.e-float-input .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger .e-small.e-filled.e-float-input .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-small .e-bigger.e-filled.e-float-input .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  margin-top: -32px;
  right: 40px;
}

.e-bigger.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger .e-filled.e-multiselect .e-down-icon .e-chips-close.e-close-hooker {
  margin-top: -44px;
}

.e-bigger.e-filled.e-multiselect.e-float-input .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close,
.e-bigger .e-filled.e-multiselect.e-float-input .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close,
.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close {
  height: 14px;
  width: 14px;
}

.e-bigger.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close,
.e-bigger .e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close {
  height: 16px;
  width: 16px;
}

.e-small.e-filled.e-multiselect.e-float-input .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close,
.e-small .e-filled.e-multiselect.e-float-input .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close {
  height: 10px;
  width: 10px;
}

.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-bigger.e-small.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-bigger.e-small .e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-bigger .e-small.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-small.e-bigger.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-small.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-small .e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips .e-chips-close::before {
  font-size: 12px;
  height: 12px;
  width: 12px;
}

.e-small.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-small .e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-chips .e-chips-close::before {
  font-size: 10px;
  height: 10px;
  width: 10px;
}

.e-bigger.e-filled.e-float-input .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-bigger .e-filled.e-float-input .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-bigger.e-small.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-bigger.e-small .e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-bigger .e-small.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-small .e-bigger.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips .e-chips-close::before {
  font-size: 14px;
  height: 14px;
  width: 14px;
}

.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker::before,
.e-bigger.e-small.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker::before,
.e-bigger.e-small .e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker::before,
.e-bigger .e-small.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker::before,
.e-small .e-bigger.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker::before {
  content: "\e208";
  font-size: 16px;
  height: 16px;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  width: 16px;
}

.e-small.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker::before,
.e-small .e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker::before {
  font-size: 14px;
  height: 14px;
  width: 14px;
}

.e-bigger.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker::before,
.e-bigger .e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker::before {
  font-size: 20px;
  height: 20px;
  width: 20px;
}

.e-bigger.e-small.e-rtl.e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger.e-small .e-rtl .e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl.e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl .e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  left: 10px;
  right: auto;
}

.e-small.e-rtl.e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl .e-small.e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-small .e-rtl.e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl.e-small .e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  left: 6px;
  right: auto;
}

.e-bigger.e-rtl.e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl .e-bigger.e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger .e-rtl.e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-rtl.e-bigger .e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  left: 12px;
  right: auto;
}

.e-multiselect.e-input-group.e-filled .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-multiselect.e-filled.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
  margin: 10px 0 9px;
}

.e-multiselect.e-filled.e-input-group.e-float-input.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
  margin: 0 8px 8px 0;
  padding: 0;
}

.e-small.e-multiselect.e-input-group.e-filled .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-small .e-multiselect.e-filled.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
  height: 18px;
  margin: 7px 0 2px;
  width: 18px;
}

.e-bigger.e-multiselect.e-input-group.e-filled .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-bigger .e-multiselect.e-filled.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
  height: 24px;
  margin: 12px -4px 8px -1px;
  padding: 0;
  width: 24px;
}

.e-small.e-multiselect.e-input-group.e-float-input.e-filled .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-small .e-multiselect.e-filled.e-input-group.e-float-input.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
  margin: 0 12px 4px 0;
}

.e-bigger.e-multiselect.e-input-group.e-filled.e-float-input .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-bigger .e-multiselect.e-filled.e-input-group.e-control-wrapper.e-float-input .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
  margin: 0;
}

.e-bigger.e-small.e-multiselect.e-input-group.e-float-input.e-filled .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-bigger.e-small .e-multiselect.e-input-group.e-float-input.e-filled .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-bigger .e-small.e-multiselect.e-input-group.e-float-input.e-filled .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-small .e-bigger.e-multiselect.e-input-group.e-float-input.e-filled .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
  height: 20px;
  margin: 0 0 8px;
  padding: 0;
  width: 20px;
}

.e-multiselect.e-input-group.e-filled .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon::before,
.e-multiselect.e-filled.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon::before {
  font-size: 16px;
  height: 16px;
  width: 16px;
}

.e-small.e-multiselect.e-input-group.e-filled .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon::before,
.e-small .e-multiselect.e-filled.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon::before {
  font-size: 14px;
  height: 14px;
  width: 14px;
}

.e-bigger.e-multiselect.e-input-group.e-filled .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon::before,
.e-bigger .e-multiselect.e-filled.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon::before {
  font-size: 20px;
  height: 20px;
  width: 20px;
}

.e-bigger.e-small.e-multiselect.e-filled.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon::before,
.e-bigger .e-small .e-multiselect.e-filled.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon::before,
.e-small.e-bigger.e-multiselect.e-filled.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon::before,
.e-small .e-bigger.e-multiselect.e-filled.e-input-group.e-control-wrapper .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon::before {
  font-size: 18px;
  height: 18px;
  width: 18px;
}

.e-bigger.e-small.e-multiselect.e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-bigger .e-small .e-multiselect.e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-small.e-bigger.e-multiselect.e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon,
.e-small .e-bigger.e-multiselect.e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-multi-select-wrapper .e-input-group-icon.e-ddl-icon {
  height: 24px;
  margin: 7px -4px 8px -1px;
  padding: 0;
  width: 24px;
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker.e-ddl-disable-icon::before,
.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker.e-ddl-disable-icon::before,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker.e-ddl-disable-icon::before,
.e-small.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker.e-ddl-disable-icon::before,
.e-small.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker.e-ddl-disable-icon::before,
.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker.e-ddl-disable-icon::before,
.e-bigger.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker.e-ddl-disable-icon::before,
.e-bigger.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker.e-ddl-disable-icon::before,
.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker.e-ddl-disable-icon::before,
.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker.e-ddl-disable-icon::before,
.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper .e-close-hooker.e-ddl-disable-icon::before {
  content: "";
}

.e-multi-select-wrapper {
  box-sizing: border-box;
  cursor: text;
  line-height: normal;
  min-height: 30px;
  padding: 0 32px 0 0;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
}
.e-multi-select-wrapper.e-delimiter .e-searcher, .e-multi-select-wrapper.e-delimiter .e-multi-searcher {
  height: 27px;
  vertical-align: middle;
}
.e-multi-select-wrapper.e-delimiter .e-searcher .e-dropdownbase, .e-multi-select-wrapper.e-delimiter .e-multi-searcher .e-dropdownbase {
  height: 100%;
  min-height: 100%;
}
.e-multi-select-wrapper .e-delim-view {
  white-space: nowrap;
}
.e-multi-select-wrapper .e-delim-view.e-delim-values.e-delim-overflow, .e-multi-select-wrapper .e-delim-view.e-delim-values.e-delim-total {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected .e-chips-close::before {
  color: #fff;
  font-size: 16px;
  left: 12px;
  top: 0;
}
.e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected .e-chipcontent {
  background-color: #3f51b5;
  color: #fff;
  padding: 12px 4px 11px 0;
}
.e-multi-select-wrapper .e-searcher.e-zero-size:not(.e-multiselect-box),
.e-multi-select-wrapper .e-multi-searcher.e-zero-size:not(.e-multiselect-box) {
  width: 0;
}
.e-multi-select-wrapper .e-searcher.e-zero-size:not(.e-multiselect-box) input[type=text],
.e-multi-select-wrapper .e-multi-searcher.e-zero-size:not(.e-multiselect-box) input[type=text] {
  height: 1px;
  min-height: 1px;
}
.e-multi-select-wrapper .e-chips.e-mob-chip > .e-chipcontent {
  max-width: 100%;
}

.e-multiselect.e-control-container .e-multi-select-wrapper .e-multi-searcher,
.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper .e-multi-searcher {
  display: -ms-flexbox;
  display: flex;
  float: left;
  width: auto;
}
.e-multiselect.e-control-container .e-multi-select-wrapper.e-delimiter .e-multi-searcher,
.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper.e-delimiter .e-multi-searcher {
  display: -ms-inline-flexbox;
  display: inline-flex;
  float: none;
}
.e-multiselect.e-control-container .e-multi-select-wrapper .e-multi-searcher.e-zero-size:not(.e-multiselect-box),
.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper .e-multi-searcher.e-zero-size:not(.e-multiselect-box) {
  width: 0;
}
.e-multiselect.e-control-container .e-multi-select-wrapper .e-delim-values.e-delim-hide,
.e-multiselect.e-control-container .e-multi-select-wrapper .e-delim-view.e-delim-values.e-delim-hide,
.e-multiselect.e-control-container .e-multi-select-wrapper .e-chips-collection.e-delim-hide,
.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper .e-delim-values.e-delim-hide,
.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper .e-delim-view.e-delim-values.e-delim-hide,
.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper .e-chips-collection.e-delim-hide {
  display: none;
}
.e-multiselect.e-control-container .e-multi-select-wrapper.e-down-icon,
.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper.e-down-icon {
  padding: 0 48px 0 0;
}
.e-multiselect.e-control-container .e-multi-select-wrapper .e-delim-values,
.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper .e-delim-values {
  -ms-flex-align: center;
      align-items: center;
  display: inline;
  max-width: 100%;
  word-break: break-word;
}
.e-multiselect.e-control-container .e-multi-select-wrapper .e-clear-icon,
.e-multiselect.e-control-container .e-multi-select-wrapper .e-ddl-icon,
.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper .e-clear-icon,
.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper .e-ddl-icon {
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: none;
  -ms-flex-direction: row;
      flex-direction: row;
  margin-top: -2.5em;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 5px;
  top: 100%;
}
.e-multiselect.e-control-container :not(.e-disabled).e-multi-select-wrapper:hover .e-clear-icon,
.e-multiselect.e-control-container .e-multi-select-wrapper .e-ddl-icon,
.e-multiselect.e-control-container.e-input-group :not(.e-disabled).e-multi-select-wrapper:hover .e-clear-icon,
.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper .e-ddl-icon {
  display: -ms-flexbox;
  display: flex;
}
.e-multiselect.e-control-container .e-multi-select-wrapper .e-ddl-icon,
.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper .e-ddl-icon {
  margin-right: 0;
  margin-top: -2.1em;
  right: 0;
}
.e-multiselect.e-control-container .e-multi-select-wrapper.e-delimiter,
.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper.e-delimiter {
  top: -1px;
}
.e-multiselect.e-control-container .e-multi-select-wrapper .e-clear-icon,
.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper .e-clear-icon {
  font-size: 10px;
  height: 24px;
  width: 24px;
}

.e-multiselect.e-control-container.e-rtl .e-multi-select-wrapper .e-multi-searcher,
.e-multiselect.e-control-container.e-input-group.e-rtl .e-multi-select-wrapper .e-multi-searcher {
  float: right;
}
.e-multiselect.e-control-container.e-rtl .e-multi-select-wrapper .e-clear-icon,
.e-multiselect.e-control-container.e-rtl .e-multi-select-wrapper .e-ddl-icon,
.e-multiselect.e-control-container.e-input-group.e-rtl .e-multi-select-wrapper .e-clear-icon,
.e-multiselect.e-control-container.e-input-group.e-rtl .e-multi-select-wrapper .e-ddl-icon {
  left: 0;
  right: auto;
}
.e-multiselect.e-control-container.e-rtl .e-multi-select-wrapper.e-down-icon,
.e-multiselect.e-control-container.e-input-group.e-rtl .e-multi-select-wrapper.e-down-icon {
  padding: 0 0 0 48px;
}

.e-bigger .e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper .e-ddl-icon,
.e-bigger.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper .e-ddl-icon {
  margin-top: -2.5em;
}

.e-popup.e-multi-select-list-wrapper .e-list-item.e-disabled,
.e-popup.e-multi-select-list-wrapper.e-multiselect-group.e-checkbox .e-list-group-item.e-disabled {
  opacity: 0.7;
  pointer-events: none;
}

.e-multiselect.e-input-group,
.e-multiselect.e-float-input {
  word-wrap: initial;
}

.e-multiselect.e-input-group .e-searcher .e-label-top,
.e-multiselect.e-input-group .e-multi-searcher .e-label-top {
  top: 15px;
}

.e-bigger .e-multi-select-wrapper {
  min-height: 37px;
}

.e-multi-select-wrapper.e-close-icon-hide {
  padding-right: 0;
}

.e-multi-select-wrapper .e-chips-collection {
  cursor: default;
  display: block;
}

.e-multi-select-wrapper .e-multi-hidden {
  border: 0;
  height: 0;
  position: absolute;
  visibility: hidden;
  width: 0;
}

.e-multi-select-wrapper .e-chips {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  float: left;
  margin: 0 8px 6px 0;
  max-width: 100%;
  overflow: hidden;
  padding: 0 4px 0 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-bigger .e-multi-select-wrapper .e-chips {
  margin: 0 8px 6px 0;
}

.e-multi-select-wrapper .e-chips > .e-chipcontent {
  max-width: 100%;
  overflow: hidden;
  padding: 0 4px 0 0;
  text-indent: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-multi-select-wrapper.e-delimiter .e-searcher {
  display: inline-block;
  float: none;
}

.e-multi-select-wrapper .e-mob-chip.e-chips > .e-chipcontent,
.e-bigger .e-multi-select-wrapper .e-chips > .e-chipcontent {
  padding: 8px 4px 8px 0;
}

.e-multiselect.e-rtl .e-multi-select-wrapper .e-mob-chip.e-chips > .e-chipcontent,
.e-bigger .e-multiselect.e-rtl .e-multi-select-wrapper .e-chips > .e-chipcontent {
  padding: 8px 0 8px 4px;
}

.e-multi-select-wrapper .e-chips-close {
  -ms-flex-item-align: center;
      align-self: center;
  display: -ms-flexbox;
  display: flex;
  float: right;
  font-family: "e-icons";
  height: 16px;
  margin: 0 0 0;
  width: 16px;
}

.e-rtl .e-multi-select-wrapper .e-chips-close {
  margin: 0 0 0;
}

.e-multi-select-wrapper .e-mob-chip.e-chips .e-chips-close {
  margin: 3px 0 0;
}

.e-multi-select-wrapper .e-chips-close.e-close-hooker {
  cursor: default;
  font-size: 10px;
  height: 24px;
  margin-top: -2.5em;
  position: absolute;
  right: 5px;
  width: 24px;
  top: 100%;
}

.e-multiselect .e-down-icon .e-chips-close.e-close-hooker,
.e-small.e-multiselect .e-down-icon .e-chips-close.e-close-hooker,
.e-multiselect.e-input-group.e-control-container .e-multi-select-wrapper.e-down-icon .e-clear-icon {
  right: 22px;
}

.e-multiselect.e-input-group.e-control-container.e-rtl .e-multi-select-wrapper.e-down-icon .e-clear-icon {
  left: 22px;
  right: auto;
}

.e-bigger .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  margin-top: -3em;
}

.e-bigger .e-multiselect.e-control-container .e-multi-select-wrapper .e-clear-icon,
.e-bigger.e-multiselect.e-control-container .e-multi-select-wrapper .e-clear-icon {
  margin-top: -3em;
}

.e-multi-select-wrapper input[type=text] {
  background: none;
  border: 0;
  font-family: inherit;
  font-size: 13px;
  font-weight: normal;
  height: 28px;
  min-height: 28px;
  outline: none;
  padding: 4px 0 4px 0;
  text-indent: 0;
}

.e-multiselect.e-filled .e-multi-select-wrapper input[type=text] {
  background: none;
  border: 0;
  color: inherit;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 28px;
  min-height: 28px;
  outline: none;
  padding: 0;
  text-indent: 0;
}

.e-rtl.e-multiselect.e-filled .e-multi-select-wrapper input[type=text] {
  padding: 5px;
}

.e-bigger .e-multi-select-wrapper input[type=text],
.e-multi-select-wrapper.e-mob-wrapper input[type=text] {
  height: 28px;
  min-height: 28px;
}

.e-bigger .e-multi-select-wrapper input[type=text] {
  height: 34px;
  min-height: 34px;
}

/* stylelint-disable property-no-vendor-prefix */
.e-multi-select-wrapper input[type=text]::-ms-clear {
  display: none;
}

.e-multi-select-wrapper .e-searcher {
  display: block;
  float: left;
  width: auto;
}

.e-multiselect .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width {
  width: calc(100% - 32px);
}

.e-bigger .e-multiselect .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width,
.e-bigger.e-multiselect .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width {
  width: calc(100% - 38px);
}

.e-small .e-multiselect .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width,
.e-small.e-multiselect .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width {
  width: calc(100% - 30px);
}

.e-bigger.e-small .e-multiselect .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width,
.e-bigger.e-small.e-multiselect .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width,
.e-bigger .e-small.e-multiselect .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width,
.e-small .e-bigger.e-multiselect .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width {
  width: calc(100% - 32px);
}

.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width {
  width: calc(100% - 32px);
}

.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width,
.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width {
  width: calc(100% - 38px);
}

.e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width {
  width: calc(100% - 30px);
}

.e-bigger.e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width,
.e-bigger.e-small .e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width,
.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width,
.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon .e-searcher.e-search-custom-width {
  width: calc(100% - 32px);
}

.e-multi-select-wrapper .e-delim-values {
  font-family: inherit;
  font-size: 14px;
  line-height: 28px;
  max-width: 100%;
  padding-left: 0;
  padding-right: 6px;
  vertical-align: middle;
}

.e-bigger .e-multi-select-wrapper .e-delim-values {
  line-height: 34px;
  padding-left: 0;
}

.e-multi-select-list-wrapper .e-hide-listitem,
.e-multi-select-list-wrapper .e-hide-group-header {
  display: none;
}

.e-multi-select-wrapper .e-delim-values .e-remain {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  padding-left: 16px;
}

.e-multiselect.e-disabled .e-multi-select-wrapper,
.e-multiselect.e-disabled .e-multi-select-wrapper .e-chips .e-chips-close::before {
  cursor: not-allowed;
}

.e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected .e-chips-close {
  height: 40px;
  left: 0;
  margin: 0 0 0 0;
  margin-left: auto;
  position: relative;
  top: 0;
  width: 40px;
}

.e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected {
  -ms-flex-item-align: center;
      align-self: center;
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.21);
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0 0 0 16px;
  width: 92%;
}

.e-multi-select-wrapper .e-ddl-disable-icon::before {
  content: "";
}

.e-multiselect.e-rtl .e-multi-select-wrapper.e-delimiter .e-searcher {
  float: none;
}

.e-multiselect.e-rtl .e-multi-select-wrapper.e-close-icon-hide {
  padding-left: 0;
}
.e-multiselect.e-rtl .e-multi-select-wrapper {
  padding: 0 2px 0 32px;
}
.e-multiselect.e-rtl .e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected .e-chips-close::before {
  left: -12px;
}
.e-multiselect.e-rtl .e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected .e-chips-close {
  margin: 0;
  margin-right: auto;
}
.e-multiselect.e-rtl .e-multi-select-wrapper .e-searcher {
  float: right;
}
.e-multiselect.e-rtl .e-multi-select-wrapper .e-chips {
  float: right;
  margin: 0 0 8px 8px;
  padding: 0 8px 0 4px;
}
.e-multiselect.e-rtl .e-multi-select-wrapper .e-chips .e-chipcontent {
  padding: 5px 4px;
}
.e-multiselect.e-rtl .e-multi-select-wrapper .e-chips .e-chips-close {
  float: left;
}
.e-multiselect.e-rtl .e-multi-select-wrapper .e-chips .e-chips-close::before {
  left: 0;
}
.e-multiselect.e-rtl .e-multi-select-wrapper .e-chips.e-mob-chip {
  padding: 0 4px 0 8px;
}
.e-multiselect.e-rtl .e-multi-select-wrapper .e-chips.e-mob-chip .e-chipcontent {
  padding: 8px 4px;
}
.e-multiselect.e-rtl .e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected {
  padding: 0 8px 0 4px;
}
.e-multiselect.e-rtl .e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected .e-chipcontent {
  padding: 12px 4px;
}
.e-multiselect.e-rtl .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  left: 22px;
  position: absolute;
  right: auto;
}
.e-multiselect.e-rtl .e-multi-select-wrapper .e-close-hooker::before {
  left: -4px;
}
.e-multiselect.e-rtl .e-multi-select-wrapper .e-delim-values .e-remain {
  padding-right: 16px;
}

.e-multiselect.e-rtl .e-down-icon .e-chips-close.e-close-hooker {
  left: 19px;
}

.e-multiselect.e-rtl .e-multi-select-wrapper.e-down-icon .e-close-hooker::before {
  left: -7px;
}

.e-popup.e-multi-select-list-wrapper .e-list-item .e-checkbox-wrapper {
  bottom: 1px;
  margin-right: 10px;
  position: relative;
  text-indent: 0;
  vertical-align: middle;
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group:not(.e-rtl) .e-list-item .e-checkbox-wrapper {
  padding-left: 14px;
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group.e-rtl .e-list-item .e-checkbox-wrapper {
  padding-right: 25px;
}

.e-bigger .e-popup.e-multi-select-list-wrapper.e-multiselect-group.e-rtl .e-list-item .e-checkbox-wrapper {
  padding-right: 25px;
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group .e-list-group-item .e-checkbox-wrapper {
  bottom: 1px;
  margin-right: 10px;
  position: relative;
  text-indent: 0;
  vertical-align: middle;
}

.e-bigger .e-popup.e-multi-select-list-wrapper .e-list-item .e-checkbox-wrapper,
.e-bigger.e-popup.e-multi-select-list-wrapper .e-list-item .e-checkbox-wrapper {
  bottom: 1px;
  margin-right: 12px;
}

.e-bigger .e-popup.e-multi-select-list-wrapper.e-multiselect-group .e-list-group-item .e-checkbox-wrapper,
.e-bigger.e-popup.e-multi-select-list-wrapper.e-multiselect-group .e-list-group-item .e-checkbox-wrapper {
  bottom: 1px;
  margin-right: 12px;
}

.e-popup.e-multi-select-list-wrapper.e-rtl .e-list-item .e-checkbox-wrapper {
  margin-left: 12px;
  margin-right: 0;
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group.e-rtl .e-list-group-item .e-checkbox-wrapper {
  margin-left: 12px;
  margin-right: 0;
}

.e-popup.e-multi-select-list-wrapper.e-rtl .e-list-item {
  padding-right: 0;
}

.e-popup.e-multi-select-list-wrapper.e-rtl .e-dropdownbase.e-rtl.e-dd-group .e-list-item {
  padding-right: 0;
}

.e-bigger .e-popup.e-multi-select-list-wrapper.e-multiselect-group .e-dropdownbase.e-dd-group .e-list-group-item {
  padding-left: 16px;
}

.e-bigger .e-popup.e-multi-select-list-wrapper.e-multiselect-group:not(.e-rtl) .e-dropdownbase.e-dd-group .e-list-item .e-checkbox-wrapper {
  padding-left: 14px;
}

.e-multi-select-list-wrapper .e-selectall-parent {
  cursor: pointer;
  display: block;
  line-height: 36px;
  overflow: hidden;
  padding-right: 16px;
  position: relative;
  text-indent: 17px;
  white-space: nowrap;
  width: 100%;
}

.e-rtl .e-multi-select-list-wrapper .e-selectall-parent,
.e-multi-select-list-wrapper.e-rtl .e-selectall-parent {
  padding-left: 16px;
  padding-right: 0;
}

.e-multi-select-list-wrapper .e-selectall-parent .e-all-text {
  color: rgba(0, 0, 0, 0.87);
  font-family: inherit;
  font-size: 14px;
}

.e-bigger.e-multi-select-list-wrapper .e-selectall-parent,
.e-bigger .e-multi-select-list-wrapper .e-selectall-parent {
  font-size: 13px;
  line-height: 48px;
  text-indent: 17px;
}

.e-multi-select-list-wrapper .e-selectall-parent .e-checkbox-wrapper {
  bottom: 1px;
  margin-right: 10px;
  position: relative;
  text-indent: 0;
  vertical-align: middle;
}

.e-bigger.e-multi-select-list-wrapper .e-selectall-parent .e-checkbox-wrapper,
.e-bigger .e-multi-select-list-wrapper .e-selectall-parent .e-checkbox-wrapper {
  bottom: 1px;
  margin-right: 12px;
}

.e-multi-select-list-wrapper.e-rtl .e-selectall-parent .e-checkbox-wrapper {
  margin-left: 12px;
  margin-right: 0;
}

.e-multiselect .e-input-group-icon.e-ddl-icon {
  float: right;
  margin-top: 6px;
}

.e-multiselect.e-rtl .e-input-group-icon.e-ddl-icon {
  float: left;
}

.e-multiselect .e-ddl-icon::before {
  transform: rotate(0deg);
  transition: transform 300ms ease;
}

.e-multiselect.e-icon-anim .e-ddl-icon::before {
  transform: rotate(180deg);
  transition: transform 300ms ease;
}

.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-multiselect .e-multi-select-wrapper.e-down-icon {
  padding: 0;
}

.e-ddl.e-popup.e-multi-select-list-wrapper .e-filter-parent .e-input-filter,
.e-ddl.e-popup.e-multi-select-list-wrapper .e-filter-parent .e-input-group.e-input-focus .e-input-filter {
  padding: 8px;
}

.e-bigger .e-ddl.e-popup.e-multi-select-list-wrapper .e-filter-parent .e-input-filter,
.e-bigger .e-ddl.e-popup.e-multi-select-list-wrapper .e-filter-parent .e-input-group.e-input-focus {
  padding: 4px 0;
}

.e-ddl.e-popup.e-multi-select-list-wrapper .e-filter-parent .e-input-group:not(.e-control-container) .e-clear-icon,
.e-bigger .e-ddl.e-popup.e-multi-select-list-wrapper .e-filter-parent .e-input-group:not(.e-control-container) .e-clear-icon {
  padding-left: 8px;
  padding-right: 8px;
}

.e-ddl.e-popup.e-multi-select-list-wrapper .e-filter-parent .e-back-icon {
  padding: 0 8px;
}

.e-bigger .e-checkbox .e-multi-select-wrapper .e-delim-values {
  line-height: 34px;
}

.e-checkbox .e-multi-select-wrapper .e-delim-values .e-remain {
  line-height: 20px;
  padding-left: 10px;
}

.e-popup.e-multi-select-list-wrapper .e-list-item.e-disable .e-checkbox-wrapper .e-frame,
.e-popup.e-multi-select-list-wrapper .e-list-group-item.e-disable .e-checkbox-wrapper .e-frame {
  opacity: 0.3;
}

.e-popup.e-multi-select-list-wrapper .e-list-item.e-disable,
.e-popup.e-multi-select-list-wrapper.e-multiselect-group.e-checkbox .e-list-group-item.e-disable {
  opacity: 0.7;
}

.e-multi-select-wrapper input[readonly=true] {
  pointer-events: none;
}

.e-multiselect.e-checkbox .e-multi-select-wrapper .e-searcher {
  pointer-events: none;
}

ejs-multiselect {
  display: block;
}

.e-small.e-multi-select-list-wrapper .e-selectall-parent,
.e-small .e-multi-select-list-wrapper .e-selectall-parent {
  line-height: 26px;
}

.e-small .e-multi-select-wrapper .e-chips-close {
  height: 14px;
  width: 14px;
}

.e-small .e-multi-select-wrapper {
  min-height: 24px;
}

.e-small .e-multi-select-wrapper input[type=text] {
  height: 26px;
  min-height: 26px;
}

.e-small .e-multi-select-wrapper .e-delim-values {
  font-size: 12px;
  line-height: 26px;
}

.e-small .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-small .e-multiselect.e-control-container .e-multi-select-wrapper .e-clear-icon {
  margin-top: -2.5em;
}

.e-small.e-multiselect.e-control-container .e-multi-select-wrapper .e-ddl-icon,
.e-small.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper .e-ddl-icon {
  margin-top: -1.8em;
}

.e-bigger.e-small.e-multi-select-list-wrapper .e-selectall-parent,
.e-bigger.e-small .e-multi-select-list-wrapper .e-selectall-parent {
  line-height: 40px;
}

.e-bigger.e-small.e-multiselect.e-control-container .e-multi-select-wrapper .e-ddl-icon,
.e-bigger.e-small.e-multiselect.e-control-container.e-input-group .e-multi-select-wrapper .e-ddl-icon {
  margin-top: -2em;
}

.e-bigger.e-small .e-multi-select-wrapper .e-chips-close {
  height: 16px;
  width: 16px;
}

.e-bigger.e-small .e-multi-select-wrapper {
  min-height: 28px;
}

.e-bigger.e-small .e-multi-select-wrapper input[type=text] {
  height: 28px;
  min-height: 28px;
}

.e-small.e-bigger .e-multi-select-wrapper .e-delim-values {
  font-size: 13px;
  line-height: 28px;
}

.e-bigger.e-small .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-bigger.e-small .e-multiselect.e-control-container .e-multi-select-wrapper .e-clear-icon {
  margin-top: -2.5em;
  right: 5px;
}

.e-content-placeholder.e-multiselect.e-placeholder-multiselect {
  background-size: 300px 33px;
  min-height: 33px;
}

.e-bigger .e-content-placeholder.e-multiselect.e-placeholder-multiselect,
.e-bigger.e-content-placeholder.e-multiselect.e-placeholder-multiselect {
  background-size: 300px 40px;
  min-height: 40px;
}

.e-multiselect.e-outline .e-multi-select-wrapper input[type=text] {
  color: inherit;
  font-size: inherit;
  min-height: 34px;
  padding: 8px 12px 9px;
}

.e-multiselect.e-outline.e-input-focus .e-multi-select-wrapper input[type=text] {
  padding: 8px 12px 9px;
}

.e-small.e-multiselect.e-outline .e-multi-select-wrapper input[type=text],
.e-small .e-multiselect.e-outline .e-multi-select-wrapper input[type=text] {
  font-size: inherit;
  min-height: 30px;
  padding: 7px 10px;
}

.e-small.e-multiselect.e-outline.e-input-focus .e-multi-select-wrapper input[type=text],
.e-small .e-multiselect.e-outline.e-input-focus .e-multi-select-wrapper input[type=text] {
  padding: 7px 10px;
}

.e-bigger.e-small.e-multiselect.e-outline .e-multi-select-wrapper input[type=text],
.e-bigger.e-small .e-multiselect.e-outline .e-multi-select-wrapper input[type=text],
.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper input[type=text],
.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper input[type=text] {
  font-size: inherit;
  min-height: 34px;
  padding: 8px 12px 9px;
}

.e-bigger.e-small.e-multiselect.e-outline.e-input-focus .e-multi-select-wrapper input[type=text],
.e-bigger.e-small .e-multiselect.e-outline.e-input-focus .e-multi-select-wrapper input[type=text],
.e-bigger .e-small.e-multiselect.e-outline.e-input-focus .e-multi-select-wrapper input[type=text],
.e-small .e-bigger.e-multiselect.e-outline.e-input-focus .e-multi-select-wrapper input[type=text] {
  padding: 8px 12px 9px;
}

.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper input[type=text],
.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper input[type=text] {
  font-size: inherit;
  min-height: 40px;
}

.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper, .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper {
  min-height: 40px;
  padding: 7px 42px 7px 0;
}
.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper {
  min-height: 34px;
  padding: 3px 38px 1px 0;
}
.e-bigger.e-small .e-multiselect.e-outline .e-multi-select-wrapper, .e-bigger.e-small.e-multiselect.e-outline .e-multi-select-wrapper {
  min-height: 34px;
  padding: 3px 38px 1px 0;
}

.e-small .e-multiselect.e-outline .e-multi-select-wrapper, .e-small.e-multiselect.e-outline .e-multi-select-wrapper {
  min-height: 30px;
  padding: 3px 38px 1px 0;
}
.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper {
  min-height: 34px;
  padding: 3px 38px 1px 0;
}
.e-small.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper {
  min-height: 34px;
  padding: 3px 38px 1px 0;
}

.e-multiselect.e-outline .e-multi-select-wrapper {
  min-height: 34px;
  padding: 3px 38px 1px 0;
}

.e-outline.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-bigger.e-outline.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-bigger .e-outline.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon {
  padding-right: 0;
}

.e-rtl.e-outline.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-rtl.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-rtl .e-outline.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-rtl .e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-rtl.e-bigger.e-outline.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-rtl.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-bigger .e-rtl.e-outline.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-bigger .e-rtl.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-rtl .e-bigger.e-outline.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-rtl .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-rtl.e-small.e-outline.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-rtl.e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-small .e-rtl.e-outline.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-small .e-rtl.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-rtl .e-small.e-outline.e-multiselect.e-checkbox .e-multi-select-wrapper,
.e-rtl .e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-rtl.e-bigger .e-small.e-multiselect.e-outline.e-checkbox .e-multi-select-wrapper,
.e-rtl.e-small .e-bigger.e-multiselect.e-outline.e-checkbox .e-multi-select-wrapper,
.e-rtl .e-bigger .e-small.e-multiselect.e-outline.e-checkbox .e-multi-select-wrapper,
.e-rtl .e-small .e-bigger.e-multiselect.e-outline.e-checkbox .e-multi-select-wrapper,
.e-rtl.e-small.e-bigger .e-multiselect.e-outline.e-checkbox .e-multi-select-wrapper,
.e-rtl.e-small.e-bigger.e-multiselect.e-outline.e-checkbox .e-multi-select-wrapper,
.e-rtl.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-rtl.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-rtl .e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-rtl .e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-rtl.e-small.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon,
.e-rtl.e-small.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-down-icon {
  padding-left: 0;
}

.e-rtl .e-multiselect.e-outline .e-multi-select-wrapper,
.e-rtl.e-multiselect.e-outline .e-multi-select-wrapper,
.e-rtl .e-small.e-multiselect.e-outline .e-multi-select-wrapper,
.e-rtl.e-small .e-multiselect.e-outline .e-multi-select-wrapper,
.e-rtl.e-small.e-multiselect.e-outline .e-multi-select-wrapper,
.e-rtl.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper,
.e-rtl.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper,
.e-rtl .e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper,
.e-rtl .e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper,
.e-rtl.e-small.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper,
.e-rtl.e-small.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper {
  padding: 3px 0 1px 38px;
}

.e-rtl .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper,
.e-rtl.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper,
.e-rtl.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper {
  padding: 7px 0 7px 42px;
}

.e-rtl .e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-rtl.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-rtl .e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-rtl.e-small .e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-rtl.e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-rtl.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-rtl.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-rtl .e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-rtl .e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-rtl.e-small.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-rtl.e-small.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-rtl .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-rtl.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-rtl.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide {
  padding-left: 0;
}

.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-small.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-small.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide,
.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-close-icon-hide {
  padding-right: 0;
}

.e-multiselect.e-outline .e-multi-select-wrapper.e-delimiter .e-searcher {
  height: 34px;
}

.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-delimiter .e-searcher,
.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper.e-delimiter .e-searcher {
  height: 40px;
}

.e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-delimiter .e-searcher,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper.e-delimiter .e-searcher {
  height: 30px;
}

.e-bigger.e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-delimiter .e-searcher,
.e-bigger.e-small .e-multiselect.e-outline .e-multi-select-wrapper.e-delimiter .e-searcher,
.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper.e-delimiter .e-searcher,
.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper.e-delimiter .e-searcher {
  height: 34px;
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values {
  font-size: 14px;
  line-height: 34px;
  padding: 0 0 0 12px;
}

.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values {
  font-size: 16px;
  line-height: 40px;
  padding: 0 0 0 16px;
}

.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values {
  font-size: 13px;
  line-height: 30px;
  padding: 0 0 0 8px;
}

.e-bigger.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-bigger.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values {
  font-size: 14px;
  line-height: 34px;
  padding: 0 0 0 12px;
}

.e-rtl.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values {
  padding: 0 12px 0 0;
}

.e-rtl.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-rtl.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-rtl .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-bigger .e-rtl.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-bigger .e-rtl .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-rtl .e-bigger .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values {
  padding: 0 16px 0 0;
}

.e-rtl.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-rtl.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-rtl .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-small .e-rtl.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-small .e-rtl .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-rtl .e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values {
  padding: 0 8px 0 0;
}

.e-rtl.e-bigger.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-rtl.e-bigger.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-rtl .e-bigger.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-bigger.e-small .e-rtl.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-rtl.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-rtl .e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-bigger .e-rtl.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-bigger .e-rtl .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-rtl.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-rtl .e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-small .e-rtl .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values,
.e-small .e-rtl.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values {
  padding: 0 12px 0 0;
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips {
  height: 26px;
  margin: 4px 0 4px 8px;
  padding: 0 8px;
}

.e-rtl .e-multiselect.e-outline .e-multi-select-wrapper .e-chips,
.e-rtl.e-multiselect.e-outline .e-multi-select-wrapper .e-chips {
  margin: 4px 8px 4px 0;
}

.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-chips,
.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper .e-chips {
  height: 32px;
  padding: 0 12px;
}

.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-chips {
  height: 24px;
  padding: 0 8px;
}

.e-bigger.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips,
.e-bigger.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-chips,
.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips,
.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-chips {
  height: 26px;
  padding: 0 8px;
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent {
  padding: 0 8px 0 0;
}

.e-rtl.e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-rtl .e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent {
  padding: 0 0 0 8px;
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-bigger.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-bigger.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent {
  font-size: 13px;
}

.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent {
  font-size: 14px;
}

.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-chips > .e-chipcontent {
  font-size: 12px;
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-multiselect.e-control-container .e-multi-select-wrapper .e-clear-icon {
  -ms-flex-align: normal;
      align-items: normal;
}

.e-multiselect.e-filled .e-multi-select-wrapper .e-delim-values .e-remain,
.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values .e-remain {
  font-size: 14px;
}

.e-bigger.e-multiselect.e-filled .e-multi-select-wrapper .e-delim-values .e-remain,
.e-bigger .e-multiselect.e-filled .e-multi-select-wrapper .e-delim-values .e-remain,
.e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values .e-remain,
.e-bigger .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values .e-remain {
  font-size: 16px;
}

.e-small.e-multiselect.e-filled .e-multi-select-wrapper .e-delim-values .e-remain,
.e-small .e-multiselect.e-filled .e-multi-select-wrapper .e-delim-values .e-remain,
.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values .e-remain,
.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values .e-remain {
  font-size: 13px;
}

.e-bigger.e-small.e-multiselect.e-filled .e-multi-select-wrapper .e-delim-values .e-remain,
.e-bigger.e-small .e-multiselect.e-filled .e-multi-select-wrapper .e-delim-values .e-remain,
.e-bigger .e-small.e-multiselect.e-filled .e-multi-select-wrapper .e-delim-values .e-remain,
.e-small .e-bigger.e-multiselect.e-filled .e-multi-select-wrapper .e-delim-values .e-remain,
.e-bigger.e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values .e-remain,
.e-bigger.e-small .e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values .e-remain,
.e-bigger .e-small.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values .e-remain,
.e-small .e-bigger.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values .e-remain {
  font-size: 14px;
}

.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper {
  padding: 0 12px 0 8px;
}

.e-small.e-filled:not(.e-bigger).e-multiselect:not(.e-float-input) .e-multi-select-wrapper,
.e-small .e-filled:not(.e-bigger).e-multiselect:not(.e-float-input) .e-multi-select-wrapper {
  padding: 3px 12px 0 4px;
}

.e-multiselect.e-filled.e-input-group.e-control-wrapper,
.e-bigger.e-small.e-multiselect.e-filled.e-input-group.e-control-wrapper,
.e-bigger.e-small .e-multiselect.e-filled.e-input-group.e-control-wrapper,
.e-bigger .e-small.e-multiselect.e-filled.e-input-group.e-control-wrapper,
.e-small .e-bigger.e-multiselect.e-filled.e-input-group.e-control-wrapper {
  padding: 0;
}

.e-filled.e-float-input .e-multi-select-wrapper,
.e-bigger.e-small.e-filled.e-float-input .e-multi-select-wrapper,
.e-bigger.e-small .e-filled.e-float-input .e-multi-select-wrapper,
.e-bigger .e-small.e-filled.e-float-input .e-multi-select-wrapper,
.e-small .e-bigger.e-filled.e-float-input .e-multi-select-wrapper {
  padding: 11px 12px 0;
}

.e-small.e-filled.e-float-input .e-multi-select-wrapper,
.e-small .e-filled.e-float-input .e-multi-select-wrapper {
  padding: 12px 12px 0 8px;
}

.e-bigger.e-filled.e-float-input .e-multi-select-wrapper,
.e-bigger .e-filled.e-float-input .e-multi-select-wrapper {
  padding: 19px 12px 0 16px;
}

.e-bigger:not(.e-small).e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper {
  padding-top: 3px;
}

.e-rtl.e-multiselect.e-filled.e-input-group.e-control-wrapper,
.e-rtl.e-multiselect.e-filled.e-float-input.e-control-wrapper {
  padding: 0;
}

.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper input[type=text],
.e-bigger.e-small.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper input[type=text],
.e-bigger.e-small .e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper input[type=text],
.e-bigger .e-small.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper input[type=text],
.e-small .e-bigger.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper input[type=text] {
  min-height: 39px;
  padding-right: 10px;
}

.e-small.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper input[type=text],
.e-small .e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper input[type=text] {
  min-height: 32px;
}

.e-small.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper input[type=text],
.e-small .e-multiselect.e-filled.e-float-input .e-multi-select-wrapper input[type=text] {
  height: 22px;
  min-height: 22px;
  padding: 2px;
}

.e-bigger.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper input[type=text],
.e-bigger .e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper input[type=text] {
  min-height: 52px;
}

.e-bigger.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper input[type=text],
.e-bigger .e-multiselect.e-filled.e-float-input .e-multi-select-wrapper input[type=text] {
  min-height: 36px;
}

.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper.e-delimiter .e-searcher {
  height: 39px;
}

.e-small.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper.e-delimiter .e-searcher,
.e-small .e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper.e-delimiter .e-searcher {
  height: 32px;
}

.e-small.e-filled.e-multiselect.e-float-input .e-multi-select-wrapper.e-delimiter .e-searcher,
.e-small .e-filled.e-multiselect.e-float-input .e-multi-select-wrapper.e-delimiter .e-searcher {
  height: 22px;
}

.e-bigger.e-filled:not(.e-small).e-multiselect:not(.e-float-input) .e-multi-select-wrapper.e-delimiter .e-searcher,
.e-bigger .e-filled:not(.e-small).e-multiselect:not(.e-float-input) .e-multi-select-wrapper.e-delimiter .e-searcher {
  height: 52px;
}

.e-bigger.e-filled.e-multiselect.e-float-input .e-multi-select-wrapper.e-delimiter .e-searcher,
.e-bigger .e-filled.e-multiselect.e-float-input .e-multi-select-wrapper.e-delimiter .e-searcher {
  height: 36px;
}

.e-bigger.e-small.e-filled.e-multiselect.e-float-input .e-multi-select-wrapper.e-delimiter .e-searcher,
.e-bigger.e-small .e-filled.e-multiselect.e-float-input .e-multi-select-wrapper.e-delimiter .e-searcher .e-bigger .e-small.e-filled.e-multiselect.e-float-input .e-multi-select-wrapper.e-delimiter .e-searcher,
.e-small .e-bigger.e-filled.e-multiselect.e-float-input .e-multi-select-wrapper.e-delimiter .e-searcher {
  height: 28px;
}

.e-bigger.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper.e-delimiter input[type=text],
.e-bigger .e-multiselect.e-filled.e-float-input .e-multi-select-wrapper.e-delimiter input[type=text] {
  padding: 5px 0;
}

.e-multiselect.e-filled.e-float-input .e-searcher .e-dropdownbase,
.e-bigger.e-small.e-multiselect.e-filled.e-float-input .e-searcher .e-dropdownbase,
.e-bigger.e-small .e-multiselect.e-filled.e-float-input .e-searcher .e-dropdownbase,
.e-bigger .e-small.e-multiselect.e-filled.e-float-input .e-searcher .e-dropdownbase,
.e-small .e-bigger.e-multiselect.e-filled.e-float-input .e-searcher .e-dropdownbase {
  min-height: 28px;
}

.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-delim-values,
.e-small.e-bigger.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-delim-values,
.e-small.e-bigger .e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-delim-values,
.e-small .e-bigger.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-delim-values,
.e-bigger .e-small.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-delim-values {
  font-size: 13px;
  line-height: 39px;
}

.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values,
.e-small.e-bigger.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values,
.e-small.e-bigger .e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values,
.e-small .e-bigger.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values,
.e-bigger .e-small.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values {
  font-size: 14px;
  line-height: 28px;
}

.e-bigger.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-delim-values,
.e-bigger .e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-delim-values,
.e-bigger.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values,
.e-bigger .e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values {
  font-size: 16px;
  line-height: 52px;
}

.e-bigger.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values,
.e-bigger .e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values {
  line-height: 36px;
}

.e-small.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-delim-values,
.e-small .e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-delim-values,
.e-small.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values,
.e-small .e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values {
  font-size: 13px;
  line-height: 32px;
}

.e-small.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values,
.e-small .e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-delim-values {
  line-height: 22px;
}

.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-bigger.e-small.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-bigger.e-small .e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-bigger .e-small.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-small .e-bigger.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips {
  height: 28px;
}

.e-bigger.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-bigger .e-multiselect.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-small.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-small .e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips {
  height: 24px;
}

.e-bigger.e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-bigger .e-multiselect.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips {
  height: 32px;
}

.e-multiselect.e-filled.e-float-input .e-multi-select-wrapper.e-mob-wrapper .e-chips.e-mob-chip {
  height: 24px;
}

.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-bigger.e-small.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-bigger.e-small .e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-bigger .e-small.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-small .e-bigger.e-filled.e-float-input .e-multi-select-wrapper .e-chips {
  height: 18px;
}

.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-bigger.e-small.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-bigger.e-small .e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-bigger .e-small.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-small .e-bigger.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-bigger.e-small.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-bigger.e-small .e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-bigger .e-small.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-small .e-bigger.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips {
  margin: 8px 8px 0 0;
  padding: 0 8px;
}

.e-rtl.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-rtl.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-rtl.e-bigger.e-small.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-rtl.e-bigger.e-small .e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-rtl.e-bigger .e-small.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-rtl.e-small .e-bigger.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-rtl.e-bigger.e-small.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-rtl.e-bigger.e-small .e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-rtl.e-bigger .e-small.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-rtl.e-small .e-bigger.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips {
  margin: 8px 0 0 8px;
  padding: 0 8px;
}

.e-bigger.e-small.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-bigger.e-small .e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-bigger .e-small.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-small .e-bigger.e-filled.e-float-input .e-multi-select-wrapper .e-chips {
  padding-left: 8px;
}

.e-small .e-filled .e-multi-select-wrapper .e-chips,
.e-small.e-filled .e-multi-select-wrapper .e-chips {
  height: 16px;
  margin: 4px 4px 0 0;
  padding-right: 8px;
}

.e.rtl.e-small .e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-rtl.e-small.e-filled.e-float-input .e-multi-select-wrapper .e-chips {
  margin: 4px 0 0 4px;
}

.e-small .e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips,
.e-small.e-filled:not(.e-float-input) .e-multi-select-wrapper .e-chips {
  height: 24px;
}

.e-filled.e-multiselect.e-float-input .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close,
.e-small.e-filled:not(.e-bigger).e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close,
.e-small .e-filled:not(.e-bigger).e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close,
.e-bigger.e-small.e-filled.e-multiselect.e-float-input .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close,
.e-bigger.e-small .e-filled.e-multiselect.e-float-input .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close,
.e-bigger .e-small.e-filled.e-multiselect.e-float-input .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close,
.e-small .e-bigger.e-filled.e-multiselect.e-float-input .e-multi-select-wrapper .e-chips:not(.e-mob-chip) .e-chips-close {
  height: 12px;
  width: 12px;
}

.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-bigger.e-small.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-bigger.e-small .e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-bigger .e-small.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-small .e-bigger.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips > .e-chipcontent {
  font-size: 13px;
  padding: 0 8px 0 0;
}

.e-small.e-filled.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-small .e-filled.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent {
  font-size: 12px;
}

.e-filled.e-float-input.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-bigger.e-small.e-filled.e-float-input.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-bigger.e-small .e-filled.e-float-input.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-bigger .e-small.e-filled.e-float-input.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-small .e-bigger.e-filled.e-float-input.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent {
  font-size: 12px;
  padding: 0 4px 0 0;
}

.e-rtl.e-filled.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-rtl.e-bigger.e-small.e-filled.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-rtl.e-bigger.e-small .e-filled.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-rtl.e-bigger .e-small.e-filled.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-rtl.e-small .e-bigger.e-filled.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent {
  padding: 0 0 0 4px;
}

.e-small.e-filled.e-float-input.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-small .e-filled.e-float-input.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent {
  font-size: 10px;
}

.e-bigger.e-filled.e-float-input.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-bigger .e-filled.e-float-input.e-multiselect .e-multi-select-wrapper .e-chips > .e-chipcontent {
  font-size: 13px;
}

.e-filled.e-multiselect.e-float-input .e-multi-select-wrapper.e-mob-wrapper .e-chips > .e-chipcontent {
  font-size: 13px;
  padding-right: 4px;
}

.e-bigger.e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-bigger .e-filled.e-multiselect:not(.e-float-input) .e-multi-select-wrapper .e-chips > .e-chipcontent {
  font-size: 14px;
}

.e-rtl.e-bigger.e-filled .e-multi-select-wrapper .e-chips,
.e-rtl.e-bigger .e-filled .e-multi-select-wrapper .e-chips,
.e-rtl.e-bigger.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-rtl.e-bigger .e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-bigger.e-filled .e-multi-select-wrapper .e-chips,
.e-bigger .e-filled .e-multi-select-wrapper .e-chips {
  padding: 0 12px;
}

.e-bigger.e-filled.e-float-input .e-multi-select-wrapper .e-chips,
.e-bigger .e-filled.e-float-input .e-multi-select-wrapper .e-chips {
  padding-right: 8px;
}

.e-filled.e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-multiselect.e-control-container .e-filled.e-multi-select-wrapper .e-clear-icon {
  height: 38px;
  margin-top: -38px;
  right: 12px;
  top: 100%;
  width: 16px;
}

.e-multiselect.e-control-wrapper.e-input-group.e-readonly .e-clear-icon,
.e-outline.e-multiselect.e-control-wrapper.e-input-group.e-readonly .e-clear-icon,
.e-filled.e-multiselect.e-control-wrapper.e-input-group.e-readonly .e-clear-icon,
.e-multiselect.e-readonly.e-control-wrapper.e-input-group.e-control-container :not(.e-disabled).e-multi-select-wrapper:hover .e-clear-icon,
.e-multiselect.e-readonly.e-control-wrapper.e-input-group.e-control-container.e-input-group :not(.e-disabled).e-multi-select-wrapper:hover .e-clear-icon {
  display: none;
}

.e-multiselect.e-input-group.e-control-wrapper .e-input-group-icon {
  margin-right: 0;
}

.e-multiselect.e-control-container.e-input-group.e-disabled .e-input-group-icon.e-ddl-icon.e-icons,
.e-multiselect.e-control-container.e-input-group.e-disabled .e-clear-icon {
  cursor: not-allowed;
}

.e-multiselect.e-control-wrapper.e-control-container.e-input-group.e-checkbox .e-multi-select-wrapper.e-down-icon,
.e-multiselect.e-control-wrapper.e-control-container.e-input-group.e-checkbox .e-multi-select-wrapper.e-down-icon .e-multi-searcher .e-dropdownbase.e-control.e-multiselect.e-lib {
  cursor: pointer;
}

.e-multi-select-wrapper .e-chips.e-chip-selected .e-chips-close::before {
  color: #fff;
}

.e-multi-select-wrapper .e-chips.e-chip-selected {
  background-color: #757575;
}

.e-multiselect:not(.e-disabled) .e-multi-select-wrapper .e-chips.e-chip-selected:hover {
  background-color: #757575;
}

.e-multiselect {
  box-sizing: border-box;
}

/* stylelint-disable property-no-vendor-prefix */
.e-multi-select-wrapper .e-chips > .e-chipcontent {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.87);
  font-family: inherit;
  font-size: 13px;
}

.e-multi-select-wrapper .e-chips.e-chip-selected > .e-chipcontent {
  color: #fff;
}

.e-multi-select-wrapper .e-chips.e-chip-selected > .e-chipcontent:hover {
  color: #fff;
}

.e-multi-select-wrapper .e-chips {
  background-color: #eee;
  border-radius: 16px;
  height: 24px;
}

.e-multiselect:not(.e-disabled) .e-multi-select-wrapper .e-chips:hover {
  background-color: #eee;
}

.e-multi-select-wrapper .e-chips > .e-chipcontent:hover {
  color: rgba(0, 0, 0, 0.87);
}

.e-multi-select-wrapper .e-chips .e-chips-close::before {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
}

.e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected {
  background-color: #3f51b5;
  border-radius: 2px;
  color: #fff;
  height: 40px;
  line-height: 40px;
}

.e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected .e-chipcontent {
  color: #fff;
}

.e-multi-select-wrapper .e-chips.e-mob-chip,
.e-bigger .e-multi-select-wrapper .e-chips {
  height: 28px;
}

.e-popup.e-multi-select-list-wrapper {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-sizing: content-box;
  overflow: initial;
}

.e-popup.e-multi-select-list-wrapper .e-list-item.e-active {
  border-bottom: 1px solid transparent;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #fff;
}

.e-popup.e-multi-select-list-wrapper.e-checkbox .e-list-item.e-active {
  background-color: transparent;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group.e-checkbox .e-list-group-item.e-active {
  background-color: transparent;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
}

.e-popup.e-multi-select-list-wrapper.e-checkbox .e-list-item.e-active.e-item-focus {
  color: rgba(0, 0, 0, 0.87);
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group.e-checkbox .e-list-group-item.e-active.e-item-focus {
  color: rgba(0, 0, 0, 0.87);
}

.e-popup.e-multi-select-list-wrapper.e-checkbox .e-list-item.e-active.e-hover {
  background-color: #eee;
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group.e-checkbox .e-list-group-item.e-active.e-hover {
  background-color: #eee;
}

.e-popup.e-multi-select-list-wrapper .e-list-item.e-active:first-child {
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
}

.e-popup.e-multi-select-list-wrapper .e-list-item.e-active:last-child {
  border-bottom: 1px solid transparent;
}

.e-popup.e-multi-select-list-wrapper .e-list-item.e-active.e-item-focus + li.e-active {
  border-top: 1px solid transparent;
}

.e-popup.e-multi-select-list-wrapper .e-list-item.e-active.e-item-focus {
  background-color: #e0e0e0;
  box-shadow: none;
  color: #e3165b;
  border: 1px solid transparent;
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group .e-list-group-item.e-active.e-item-focus {
  background-color: #e0e0e0;
  box-shadow: none;
  color: #e3165b;
  border: 1px solid transparent;
}

.e-popup.e-multi-select-list-wrapper .e-list-item {
  border: 1px solid transparent;
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group .e-list-group-item {
  border: 1px solid transparent;
}

.e-multi-select-wrapper input.e-dropdownbase:-moz-placeholder { /* stylelint-disable-line selector-no-vendor-prefix */
  color: rgba(0, 0, 0, 0.42);
}

.e-multi-select-wrapper input.e-dropdownbase::-moz-placeholder { /* stylelint-disable-line selector-no-vendor-prefix */
  color: rgba(0, 0, 0, 0.42);
}

.e-multi-select-wrapper input.e-dropdownbase:-ms-input-placeholder { /* stylelint-disable-line selector-no-vendor-prefix */
  color: rgba(0, 0, 0, 0.42);
}

.e-multi-select-wrapper input.e-dropdownbase::-webkit-input-placeholder { /* stylelint-disable-line selector-no-vendor-prefix */
  color: rgba(0, 0, 0, 0.42);
}

.e-ul.e-reorder {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.e-multi-select-list-wrapper .e-selectall-parent {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.e-multi-select-wrapper .e-delim-values {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.87);
}

.e-multi-select-wrapper .e-chips-close.e-close-hooker {
  color: #000;
}

.e-small .e-multi-select-wrapper .e-chips {
  height: 20px;
}

.e-small .e-multi-select-wrapper .e-chips > .e-chipcontent {
  font-size: 12px;
}

.e-small .e-multi-select-wrapper .e-chips .e-chips-close::before {
  font-size: 14px;
}

.e-small .e-multi-select-wrapper .e-close-hooker::before {
  left: 6px;
}

.e-small.e-bigger .e-multi-select-wrapper .e-close-hooker::before {
  left: 6px;
}

.e-bigger.e-small .e-multi-select-wrapper .e-chips {
  height: 22px;
}

.e-bigger.e-small .e-multi-select-wrapper .e-chips > .e-chipcontent {
  font-size: 13px;
}

.e-bigger.e-small .e-multi-select-wrapper .e-chips .e-chips-close::before {
  font-size: 16px;
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips {
  background-color: #e0e0e0;
}

.e-multiselect.e-filled .e-multi-select-wrapper .e-chips {
  background-color: #cecece;
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips:hover {
  background-color: #d6d6d6;
}

.e-multiselect.e-filled .e-multi-select-wrapper .e-chips:hover {
  background-color: #cecece;
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips.e-chip-selected,
.e-multiselect.e-filled .e-multi-select-wrapper .e-chips.e-chip-selected {
  background-color: #c7c7c7;
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips.e-chip-selected:hover,
.e-multiselect.e-filled .e-multi-select-wrapper .e-chips.e-chip-selected:hover {
  background-color: #c7c7c7;
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips.e-chip-selected > .e-chipcontent,
.e-multiselect.e-filled .e-multi-select-wrapper .e-chips.e-chip-selected > .e-chipcontent {
  color: rgba(0, 0, 0, 0.87);
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips.e-chip-selected > .e-chipcontent:hover,
.e-multiselect.e-filled .e-multi-select-wrapper .e-chips.e-chip-selected > .e-chipcontent:hover {
  color: rgba(0, 0, 0, 0.87);
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker,
.e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  color: rgba(0, 0, 0, 0.54);
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips-close.e-close-hooker:hover,
.e-multiselect.e-filled .e-multi-select-wrapper .e-chips-close.e-close-hooker:hover {
  color: rgba(0, 0, 0, 0.87);
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-chips.e-chip-selected .e-chips-close::before,
.e-multiselect.e-filled .e-multi-select-wrapper .e-chips.e-chip-selected .e-chips-close::before {
  color: rgba(0, 0, 0, 0.54);
}

.e-multiselect.e-filled .e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected .e-chips-close::before,
.e-multiselect.e-outline .e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected .e-chips-close::before {
  color: #fff;
}

.e-multiselect.e-outline:not(.e-disabled) .e-multi-select-wrapper .e-chips:not(.e-chip-selected) .e-chips-close:hover::before,
.e-multiselect.e-filled:not(.e-disabled) .e-multi-select-wrapper .e-chips:not(.e-chip-selected) .e-chips-close:hover::before {
  color: rgba(0, 0, 0, 0.87);
}

.e-multiselect.e-outline .e-multi-select-wrapper .e-delim-values .e-remain,
.e-multiselect.e-filled .e-multi-select-wrapper .e-delim-values .e-remain {
  color: rgba(0, 0, 0, 0.6);
}

.e-multiselect.e-outline.e-disabled .e-multi-select-wrapper .e-delim-values,
.e-multiselect.e-outline.e-disabled .e-multi-select-wrapper .e-delim-values .e-remain,
.e-multiselect.e-outline.e-disabled .e-multi-select-wrapper .e-chips > .e-chipcontent,
.e-multiselect.e-outline.e-disabled .e-multi-select-wrapper .e-chips .e-chips-close::before,
.e-multiselect.e-outline.e-disabled .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  color: rgba(0, 0, 0, 0.38);
}

.e-multiselect.e-disabled .e-multi-select-wrapper .e-delim-values {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.42);
  color: rgba(0, 0, 0, 0.42);
}

.e-multiselect.e-outline.e-disabled .e-multi-select-wrapper .e-chips {
  background-color: #f5f5f5;
}

.e-multiselect.e-filled .e-multi-select-wrapper.e-mob-wrapper .e-chips.e-mob-chip.e-chip-selected,
.e-multiselect.e-outline .e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected {
  background-color: #3f51b5;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  height: 40px;
  line-height: 40px;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.e-multiselect.e-filled .e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected .e-chips-close,
.e-multiselect.e-outline .e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected .e-chips-close {
  width: 33px;
}

.e-multiselect.e-filled .e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected .e-chipcontent,
.e-multiselect.e-outline .e-multi-select-wrapper .e-chips.e-mob-chip.e-chip-selected .e-chipcontent {
  color: #fff;
}

.e-listbox-tool .e-moveup::before {
  content: "\e651";
}

.e-listbox-tool .e-movedown::before {
  content: "\e652";
}

.e-listbox-tool .e-moveto::before {
  content: "\e653";
}

.e-listbox-tool .e-movefrom::before {
  content: "\e654";
}

.e-listbox-tool .e-moveallto::before {
  content: "\e655";
}

.e-listbox-tool .e-moveallfrom::before {
  content: "\e656";
}

/* stylelint-disable property-no-vendor-prefix */
.e-listbox-wrapper,
.e-listbox-container,
.e-listboxtool-wrapper {
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
}
.e-listbox-wrapper *,
.e-listbox-container *,
.e-listboxtool-wrapper * {
  box-sizing: border-box;
}
.e-listbox-wrapper.e-listboxtool-container .e-list-wrap,
.e-listbox-container.e-listboxtool-container .e-list-wrap,
.e-listboxtool-wrapper.e-listboxtool-container .e-list-wrap {
  width: 86% !important; /* stylelint-disable-line declaration-no-important */
}
.e-listbox-wrapper:focus,
.e-listbox-container:focus,
.e-listboxtool-wrapper:focus {
  outline: none;
}
.e-listbox-wrapper.e-disabled,
.e-listbox-container.e-disabled,
.e-listboxtool-wrapper.e-disabled {
  cursor: default;
  pointer-events: none;
}
.e-listbox-wrapper:not(.e-list-template) .e-list-nrt,
.e-listbox-container:not(.e-list-template) .e-list-nrt,
.e-listboxtool-wrapper:not(.e-list-template) .e-list-nrt {
  text-align: center;
}
.e-listbox-wrapper:not(.e-list-template) .e-list-item,
.e-listbox-wrapper .e-list-nrt,
.e-listbox-wrapper .e-selectall-parent,
.e-listbox-container:not(.e-list-template) .e-list-item,
.e-listbox-container .e-list-nrt,
.e-listbox-container .e-selectall-parent,
.e-listboxtool-wrapper:not(.e-list-template) .e-list-item,
.e-listboxtool-wrapper .e-list-nrt,
.e-listboxtool-wrapper .e-selectall-parent {
  height: 36px;
  line-height: 1;
  padding: 11px 16px;
  position: relative;
}
.e-listbox-wrapper .e-list-parent,
.e-listbox-container .e-list-parent,
.e-listboxtool-wrapper .e-list-parent {
  height: 100%;
  min-height: 36px;
}
.e-listbox-wrapper .e-list-item,
.e-listbox-container .e-list-item,
.e-listboxtool-wrapper .e-list-item {
  border-bottom: 0 solid;
  outline: none;
}
.e-listbox-wrapper .e-list-item.e-disabled,
.e-listbox-container .e-list-item.e-disabled,
.e-listboxtool-wrapper .e-list-item.e-disabled {
  pointer-events: none;
}
.e-listbox-wrapper .e-disable,
.e-listbox-container .e-disable,
.e-listboxtool-wrapper .e-disable {
  opacity: 0.7;
}
.e-listbox-wrapper .e-list-parent,
.e-listbox-container .e-list-parent,
.e-listboxtool-wrapper .e-list-parent {
  margin: 0;
  padding: 0;
}
.e-listbox-wrapper .e-list-header .e-text.header,
.e-listbox-container .e-list-header .e-text.header,
.e-listboxtool-wrapper .e-list-header .e-text.header {
  display: none;
}
.e-listbox-wrapper .e-icon-back,
.e-listbox-container .e-icon-back,
.e-listboxtool-wrapper .e-icon-back {
  margin-top: 2px;
}
.e-listbox-wrapper .e-list-header .e-headertemplate-text.nested-header,
.e-listbox-container .e-list-header .e-headertemplate-text.nested-header,
.e-listboxtool-wrapper .e-list-header .e-headertemplate-text.nested-header {
  display: none;
}
.e-listbox-wrapper .e-list-header,
.e-listbox-container .e-list-header,
.e-listboxtool-wrapper .e-list-header {
  -ms-flex-align: center;
      align-items: center;
  border-bottom: 1px solid;
  display: -ms-flexbox;
  display: flex;
  font-weight: bold;
  height: 48px;
  padding: 0 16px;
}
.e-listbox-wrapper .e-has-header > .e-view,
.e-listbox-container .e-has-header > .e-view,
.e-listboxtool-wrapper .e-has-header > .e-view {
  top: 45px;
}
.e-listbox-wrapper .e-but-back,
.e-listbox-container .e-but-back,
.e-listboxtool-wrapper .e-but-back {
  cursor: pointer;
  padding-right: 30px;
}
.e-listbox-wrapper .e-list-group-item:first-child,
.e-listbox-container .e-list-group-item:first-child,
.e-listboxtool-wrapper .e-list-group-item:first-child {
  border: 0;
  border-bottom: 0 solid transparent;
}
.e-listbox-wrapper .e-list-group-item,
.e-listbox-container .e-list-group-item,
.e-listboxtool-wrapper .e-list-group-item {
  border-bottom: 0 solid transparent;
  border-top: 1px solid;
  font-weight: 600;
  height: 36px;
  line-height: 16px;
  padding: 11px 16px;
}
.e-listbox-wrapper .e-icon-collapsible,
.e-listbox-container .e-icon-collapsible,
.e-listboxtool-wrapper .e-icon-collapsible {
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  right: 0%;
  top: 50%;
  transform: translateY(-50%);
}
.e-listbox-wrapper .e-text-content,
.e-listbox-container .e-text-content,
.e-listboxtool-wrapper .e-text-content {
  height: 100%;
  position: relative;
  vertical-align: middle;
}
.e-listbox-wrapper .e-text-content *,
.e-listbox-container .e-text-content *,
.e-listboxtool-wrapper .e-text-content * {
  display: inline-block;
  vertical-align: middle;
}
.e-listbox-wrapper .e-text-content.e-checkbox .e-list-text,
.e-listbox-container .e-text-content.e-checkbox .e-list-text,
.e-listboxtool-wrapper .e-text-content.e-checkbox .e-list-text {
  width: calc(100% - 40px);
}
.e-listbox-wrapper .e-text-content.e-checkbox.e-checkbox-left .e-list-icon + .e-list-text,
.e-listbox-container .e-text-content.e-checkbox.e-checkbox-left .e-list-icon + .e-list-text,
.e-listboxtool-wrapper .e-text-content.e-checkbox.e-checkbox-left .e-list-icon + .e-list-text {
  width: calc(100% - 90px);
}
.e-listbox-wrapper .e-text-content.e-checkbox.e-checkbox-right .e-list-icon + .e-list-text,
.e-listbox-container .e-text-content.e-checkbox.e-checkbox-right .e-list-icon + .e-list-text,
.e-listboxtool-wrapper .e-text-content.e-checkbox.e-checkbox-right .e-list-icon + .e-list-text {
  width: calc(100% - 80px);
}
.e-listbox-wrapper .e-list-item.e-checklist.e-has-child .e-text-content.e-checkbox.e-checkbox-right .e-list-icon + .e-list-text,
.e-listbox-container .e-list-item.e-checklist.e-has-child .e-text-content.e-checkbox.e-checkbox-right .e-list-icon + .e-list-text,
.e-listboxtool-wrapper .e-list-item.e-checklist.e-has-child .e-text-content.e-checkbox.e-checkbox-right .e-list-icon + .e-list-text {
  width: calc(100% - 92px);
}
.e-listbox-wrapper .e-checkbox .e-checkbox-left,
.e-listbox-container .e-checkbox .e-checkbox-left,
.e-listboxtool-wrapper .e-checkbox .e-checkbox-left {
  margin: -2px 10px 0 0;
}
.e-listbox-wrapper .e-checkbox .e-checkbox-right,
.e-listbox-container .e-checkbox .e-checkbox-right,
.e-listboxtool-wrapper .e-checkbox .e-checkbox-right {
  margin: -2px 0 0 10px;
}
.e-listbox-wrapper .e-list-text,
.e-listbox-container .e-list-text,
.e-listboxtool-wrapper .e-list-text {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
}
.e-listbox-wrapper .e-list-icon + .e-list-text,
.e-listbox-container .e-list-icon + .e-list-text,
.e-listboxtool-wrapper .e-list-icon + .e-list-text {
  width: calc(100% - 60px);
}
.e-listbox-wrapper .e-icon-wrapper .e-list-text,
.e-listbox-container .e-icon-wrapper .e-list-text,
.e-listboxtool-wrapper .e-icon-wrapper .e-list-text {
  width: calc(100% - 60px);
}
.e-listbox-wrapper .e-icon-wrapper.e-text-content.e-checkbox .e-list-text,
.e-listbox-container .e-icon-wrapper.e-text-content.e-checkbox .e-list-text,
.e-listboxtool-wrapper .e-icon-wrapper.e-text-content.e-checkbox .e-list-text {
  width: calc(100% - 60px);
}
.e-listbox-wrapper .e-list-icon,
.e-listbox-container .e-list-icon,
.e-listboxtool-wrapper .e-list-icon {
  height: 30px;
  margin-right: 16px;
  width: 30px;
}
.e-listbox-wrapper .e-content,
.e-listbox-container .e-content,
.e-listboxtool-wrapper .e-content {
  overflow: hidden;
  position: relative;
}
.e-listbox-wrapper .e-list-header .e-text,
.e-listbox-container .e-list-header .e-text,
.e-listboxtool-wrapper .e-list-header .e-text {
  cursor: pointer;
  text-indent: 0;
}
.e-listbox-wrapper .e-text .e-headertext,
.e-listbox-container .e-text .e-headertext,
.e-listboxtool-wrapper .e-text .e-headertext {
  display: inline-block;
  line-height: inherit;
}
.e-listbox-wrapper.e-rtl,
.e-listbox-container.e-rtl,
.e-listboxtool-wrapper.e-rtl {
  direction: rtl;
}
.e-listbox-wrapper.e-rtl .e-list-icon,
.e-listbox-container.e-rtl .e-list-icon,
.e-listboxtool-wrapper.e-rtl .e-list-icon {
  margin-left: 16px;
  margin-right: 0;
}
.e-listbox-wrapper.e-rtl .e-icon-collapsible,
.e-listbox-container.e-rtl .e-icon-collapsible,
.e-listboxtool-wrapper.e-rtl .e-icon-collapsible {
  left: 0%;
  right: initial;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
}
.e-listbox-wrapper.e-rtl .e-list-header .e-text,
.e-listbox-container.e-rtl .e-list-header .e-text,
.e-listboxtool-wrapper.e-rtl .e-list-header .e-text {
  cursor: pointer;
}
.e-listbox-wrapper.e-rtl .e-but-back,
.e-listbox-container.e-rtl .e-but-back,
.e-listboxtool-wrapper.e-rtl .e-but-back {
  transform: rotate(180deg);
}
.e-listbox-wrapper.e-rtl .e-icon-back,
.e-listbox-container.e-rtl .e-icon-back,
.e-listboxtool-wrapper.e-rtl .e-icon-back {
  margin-top: -2px;
}
.e-listbox-wrapper.e-rtl .e-checkbox .e-checkbox-left,
.e-listbox-container.e-rtl .e-checkbox .e-checkbox-left,
.e-listboxtool-wrapper.e-rtl .e-checkbox .e-checkbox-left {
  margin: -2px 0 0 10px;
}
.e-listbox-wrapper.e-rtl .e-checkbox .e-checkbox-right,
.e-listbox-container.e-rtl .e-checkbox .e-checkbox-right,
.e-listboxtool-wrapper.e-rtl .e-checkbox .e-checkbox-right {
  margin: -2px 10px 0 0;
}
.e-listbox-wrapper.e-rtl .e-checkbox-wrapper,
.e-listbox-container.e-rtl .e-checkbox-wrapper,
.e-listboxtool-wrapper.e-rtl .e-checkbox-wrapper {
  margin: -2px 0 0 10px;
}
.e-listbox-wrapper .e-checkbox-wrapper,
.e-listbox-container .e-checkbox-wrapper,
.e-listboxtool-wrapper .e-checkbox-wrapper {
  margin: -2px 10px 0 0;
  text-indent: 0;
  vertical-align: middle;
}
.e-listbox-wrapper.e-checkbox-right .e-checkbox-wrapper,
.e-listbox-container.e-checkbox-right .e-checkbox-wrapper,
.e-listboxtool-wrapper.e-checkbox-right .e-checkbox-wrapper {
  position: absolute;
  right: 0;
  top: 30%;
}
.e-listbox-wrapper .e-input-group,
.e-listbox-container .e-input-group,
.e-listboxtool-wrapper .e-input-group {
  padding: 4px 8px;
}
.e-listbox-wrapper .e-input-focus,
.e-listbox-container .e-input-focus,
.e-listboxtool-wrapper .e-input-focus {
  padding: 4px 4px 4px 8px;
}
.e-listbox-wrapper .e-hidden-select,
.e-listbox-container .e-hidden-select,
.e-listboxtool-wrapper .e-hidden-select {
  height: 1px;
  opacity: 0;
  position: absolute;
  width: 100%;
}
.e-listbox-wrapper .e-placeholder,
.e-listbox-container .e-placeholder,
.e-listboxtool-wrapper .e-placeholder {
  background-color: #e3165b;
  display: block;
  height: 1px;
}

ejs-listbox {
  display: block;
}

.e-listbox-wrapper:not(.e-listbox-container) {
  overflow: auto;
}

.e-listbox-wrapper.e-filter-list .e-list-parent {
  overflow: auto;
}

.e-listbox-wrapper.e-sortableclone,
.e-listbox-container.e-sortableclone,
.e-listboxtool-wrapper.e-sortableclone {
  border-width: 0;
  overflow: visible;
}
.e-listbox-wrapper.e-sortableclone .e-list-item,
.e-listbox-container.e-sortableclone .e-list-item,
.e-listboxtool-wrapper.e-sortableclone .e-list-item {
  list-style-type: none;
}
.e-listbox-wrapper.e-sortableclone .e-ripple,
.e-listbox-container.e-sortableclone .e-ripple,
.e-listboxtool-wrapper.e-sortableclone .e-ripple {
  overflow: visible;
}
.e-listbox-wrapper.e-sortableclone .e-ripple .e-ripple-element,
.e-listbox-container.e-sortableclone .e-ripple .e-ripple-element,
.e-listboxtool-wrapper.e-sortableclone .e-ripple .e-ripple-element {
  display: none;
}
.e-listbox-wrapper.e-sortableclone .e-list-badge,
.e-listbox-container.e-sortableclone .e-list-badge,
.e-listboxtool-wrapper.e-sortableclone .e-list-badge {
  -ms-flex-align: center;
      align-items: center;
  background-color: #e3165b;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  height: 22px;
  -ms-flex-pack: center;
      justify-content: center;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 22px;
}

.e-listboxtool-wrapper.e-sortableclone {
  display: block;
}

.e-listboxtool-wrapper,
.e-listboxtool-container {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
}
.e-listboxtool-wrapper *,
.e-listboxtool-container * {
  box-sizing: border-box;
}
.e-listboxtool-wrapper.e-disabled,
.e-listboxtool-container.e-disabled {
  cursor: default;
  pointer-events: none;
}
.e-listboxtool-wrapper .e-listbox-wrapper,
.e-listboxtool-container .e-listbox-wrapper {
  -ms-flex: 1;
      flex: 1;
}
.e-listboxtool-wrapper.e-right .e-listbox-tool,
.e-listboxtool-container.e-right .e-listbox-tool {
  margin-left: 15px;
}
.e-listboxtool-wrapper.e-left .e-listbox-tool,
.e-listboxtool-container.e-left .e-listbox-tool {
  margin-right: 15px;
}
.e-listboxtool-wrapper .e-listbox-tool,
.e-listboxtool-container .e-listbox-tool {
  border: 1px solid #e0e0e0;
  overflow: auto;
  padding: 8px;
}
.e-listboxtool-wrapper .e-listbox-tool .e-btn,
.e-listboxtool-container .e-listbox-tool .e-btn {
  display: list-item;
  list-style-type: none;
  margin-bottom: 10px;
}
.e-listboxtool-wrapper.e-checkbox-right .e-checkbox-wrapper,
.e-listboxtool-container.e-checkbox-right .e-checkbox-wrapper {
  position: absolute;
  right: 0;
  top: 30%;
}

.e-rtl.e-listboxtool-wrapper.e-right .e-listbox-tool,
.e-rtl.e-listboxtool-container.e-right .e-listbox-tool {
  margin-right: 15px;
}
.e-rtl.e-listboxtool-wrapper.e-left .e-listbox-tool,
.e-rtl.e-listboxtool-container.e-left .e-listbox-tool {
  margin-left: 15px;
}

.e-bigger .e-listbox-wrapper.e-listboxtool-container .e-list-wrap,
.e-listbox-wrapper.e-bigger.e-listboxtool-container .e-list-wrap,
.e-bigger .e-listbox-container.e-listboxtool-container .e-list-wrap,
.e-listbox-container.e-bigger.e-listboxtool-container .e-list-wrap {
  width: 85% !important; /* stylelint-disable-line declaration-no-important */
}
.e-bigger .e-listbox-wrapper .e-list-item,
.e-listbox-wrapper.e-bigger .e-list-item,
.e-bigger .e-listbox-container .e-list-item,
.e-listbox-container.e-bigger .e-list-item {
  border-bottom: 0 solid transparent;
  border-left: 0 solid transparent;
  border-right: 0 solid transparent;
  border-top: 0 solid transparent;
}
.e-bigger .e-listbox-wrapper:not(.e-list-template) .e-list-item,
.e-bigger .e-listbox-wrapper .e-selectall-parent,
.e-bigger .e-listbox-wrapper .e-list-group-item,
.e-bigger .e-listbox-wrapper .e-list-header,
.e-listbox-wrapper.e-bigger:not(.e-list-template) .e-list-item,
.e-listbox-wrapper.e-bigger .e-selectall-parent,
.e-listbox-wrapper.e-bigger .e-list-group-item,
.e-listbox-wrapper.e-bigger .e-list-header,
.e-bigger .e-listbox-container:not(.e-list-template) .e-list-item,
.e-bigger .e-listbox-container .e-selectall-parent,
.e-bigger .e-listbox-container .e-list-group-item,
.e-bigger .e-listbox-container .e-list-header,
.e-listbox-container.e-bigger:not(.e-list-template) .e-list-item,
.e-listbox-container.e-bigger .e-selectall-parent,
.e-listbox-container.e-bigger .e-list-group-item,
.e-listbox-container.e-bigger .e-list-header {
  padding: 12px 16px;
}
.e-bigger .e-listbox-wrapper:not(.e-list-template) .e-list-item,
.e-bigger .e-listbox-wrapper .e-selectall-parent,
.e-listbox-wrapper.e-bigger:not(.e-list-template) .e-list-item,
.e-listbox-wrapper.e-bigger .e-selectall-parent,
.e-bigger .e-listbox-container:not(.e-list-template) .e-list-item,
.e-bigger .e-listbox-container .e-selectall-parent,
.e-listbox-container.e-bigger:not(.e-list-template) .e-list-item,
.e-listbox-container.e-bigger .e-selectall-parent {
  height: 48px;
  line-height: 1.6;
  position: relative;
}
.e-bigger .e-listbox-wrapper .e-list-parent,
.e-listbox-wrapper.e-bigger .e-list-parent,
.e-bigger .e-listbox-container .e-list-parent,
.e-listbox-container.e-bigger .e-list-parent {
  min-height: 48px;
}
.e-bigger .e-listbox-wrapper .e-text-content,
.e-listbox-wrapper.e-bigger .e-text-content,
.e-bigger .e-listbox-container .e-text-content,
.e-listbox-container.e-bigger .e-text-content {
  font-size: 15px;
}
.e-bigger .e-listbox-wrapper .e-list-group-item,
.e-listbox-wrapper.e-bigger .e-list-group-item,
.e-bigger .e-listbox-container .e-list-group-item,
.e-listbox-container.e-bigger .e-list-group-item {
  height: 48px;
  line-height: 25px;
}
.e-bigger .e-listbox-wrapper .e-list-header,
.e-listbox-wrapper.e-bigger .e-list-header,
.e-bigger .e-listbox-container .e-list-header,
.e-listbox-container.e-bigger .e-list-header {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-weight: bold;
  height: 64px;
}
.e-bigger .e-listbox-wrapper .e-list-header .e-text.header,
.e-listbox-wrapper.e-bigger .e-list-header .e-text.header,
.e-bigger .e-listbox-container .e-list-header .e-text.header,
.e-listbox-container.e-bigger .e-list-header .e-text.header {
  display: none;
}
.e-bigger .e-listbox-wrapper .e-list-header .e-headertemplate-text.nested-header,
.e-listbox-wrapper.e-bigger .e-list-header .e-headertemplate-text.nested-header,
.e-bigger .e-listbox-container .e-list-header .e-headertemplate-text.nested-header,
.e-listbox-container.e-bigger .e-list-header .e-headertemplate-text.nested-header {
  display: none;
}
.e-bigger .e-listbox-wrapper .e-list-header .e-text,
.e-listbox-wrapper.e-bigger .e-list-header .e-text,
.e-bigger .e-listbox-container .e-list-header .e-text,
.e-listbox-container.e-bigger .e-list-header .e-text {
  font-size: 20px;
}
.e-small .e-listbox-wrapper.e-filter-list .e-list-parent,
.e-small .e-listbox-container.e-filter-list .e-list-parent {
  height: calc(100% - 45px) !important; /* stylelint-disable-line declaration-no-important */
}

.e-bigger .e-listbox-wrapper.e-filter-list:not(.e-listbox-container) .e-list-parent {
  height: calc(100% - 55px) !important; /* stylelint-disable-line declaration-no-important */
}

.e-bigger .e-listbox-container.e-filter-list .e-list-wrap {
  height: calc(100% - 50px) !important; /* stylelint-disable-line declaration-no-important */
}

.e-bigger .e-listbox-container.e-filter-list .e-list-parent {
  height: 100%;
}

.e-bigger .e-listbox-wrapper.e-filter-list .e-selectall-parent + .e-list-parent,
.e-bigger .e-listbox-container.e-filter-list .e-selectall-parent + .e-list-parent {
  height: calc(100% - 48px) !important; /* stylelint-disable-line declaration-no-important */
}

.e-listbox-container.e-filter-list .e-selectall-parent + .e-list-parent {
  height: calc(100% - 48px) !important; /* stylelint-disable-line declaration-no-important */
}

.e-listbox-wrapper.e-filter-list:not(.e-listbox-container) .e-list-parent {
  height: calc(100% - 48px) !important; /* stylelint-disable-line declaration-no-important */
}

.e-bigger .e-listbox-wrapper.e-filter-list:not(.e-listbox-container) .e-list-parent {
  height: calc(100% - 55px) !important; /* stylelint-disable-line declaration-no-important */
}

.e-bigger .e-listbox-container.e-filter-list .e-selectall-parent + .e-list-parent {
  height: 100%;
}

.e-bigger .e-listbox-wrapper .e-selectall-parent + .e-list-parent,
.e-bigger .e-listbox-container .e-selectall-parent + .e-list-wrap {
  height: calc(100% - 48px) !important; /* stylelint-disable-line declaration-no-important */
}

.e-bigger .e-listbox-container .e-selectall-parent + .e-list-parent {
  height: 100%;
}

.e-small.e-bigger .e-listbox-wrapper.e-filter-list .e-list-parent,
.e-small.e-bigger .e-listbox-container.e-filter-list .e-list-wrap {
  height: calc(100% - 45px) !important; /* stylelint-disable-line declaration-no-important */
}

.e-listbox-wrapper.e-filter-list:not(.e-listbox-container) .e-selectall-parent + .e-hidden-select + .e-listbox + .e-list-parent {
  height: calc(100% - 83px) !important; /* stylelint-disable-line declaration-no-important */
}

.e-bigger .e-listbox-wrapper.e-filter-list:not(.e-listbox-container) .e-selectall-parent + .e-hidden-select + .e-listbox + .e-list-parent {
  height: calc(100% - 103px) !important; /* stylelint-disable-line declaration-no-important */
}

.e-small.e-bigger .e-listbox-container.e-filter-list .e-list-parent {
  height: 100%;
}

.e-listbox-wrapper,
.e-listbox-container:not(.e-listboxtool-container):not(.e-sortableclone),
.e-listboxtool-container.e-listbox-container .e-ul {
  border: 1px solid #e0e0e0;
}

.e-listbox-wrapper,
.e-listbox-container,
.e-listboxtool-wrapper {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 15px;
}
.e-listbox-wrapper .e-list-header,
.e-listbox-container .e-list-header,
.e-listboxtool-wrapper .e-list-header {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
}

.e-listbox-wrapper.e-filter-list .e-list-parent,
.e-listbox-container.e-filter-list .e-list-parent,
.e-listboxtool-wrapper.e-filter-list .e-list-parent {
  height: calc(100% - 48px);
}

.e-listbox-wrapper.e-filter-list .e-selectall-parent + .e-list-parent,
.e-listbox-container.e-filter-list .e-selectall-parent + .e-list-parent,
.e-listboxtool-wrapper.e-filter-list .e-selectall-parent + .e-list-parent {
  height: calc(100% - 36px) !important; /* stylelint-disable-line declaration-no-important */
}

.e-listbox-wrapper .e-selectall-parent + .e-list-parent,
.e-listbox-container .e-selectall-parent + .e-list-parent,
.e-listboxtool-wrapper .e-selectall-parent + .e-list-parent {
  height: calc(100% - 36px) !important; /* stylelint-disable-line declaration-no-important */
}

.e-listbox-wrapper .e-icons,
.e-listbox-container .e-icons,
.e-listboxtool-wrapper .e-icons {
  color: rgba(0, 0, 0, 0.54);
}

.e-listbox-wrapper .e-list-item,
.e-listbox-container .e-list-item,
.e-listboxtool-wrapper .e-list-item {
  background-color: #fff;
  border-bottom: 0 solid transparent;
  border-left: 0 solid transparent;
  border-right: 0 solid transparent;
  border-top: 0 solid transparent;
  color: rgba(0, 0, 0, 0.87);
}

.e-listbox-wrapper .e-list-item:hover:not(.e-selected):not(.e-disabled), .e-listbox-wrapper .e-list-item:hover.e-selected.e-checklist:not(.e-disabled),
.e-listbox-container .e-list-item:hover:not(.e-selected):not(.e-disabled),
.e-listbox-container .e-list-item:hover.e-selected.e-checklist:not(.e-disabled),
.e-listboxtool-wrapper .e-list-item:hover:not(.e-selected):not(.e-disabled),
.e-listboxtool-wrapper .e-list-item:hover.e-selected.e-checklist:not(.e-disabled) {
  background-color: #eee;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
}

.e-listbox-wrapper .e-list-item.e-selected,
.e-listbox-container .e-list-item.e-selected,
.e-listboxtool-wrapper .e-list-item.e-selected {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}

.e-listbox-wrapper .e-list-item.e-selected.e-checklist,
.e-listbox-container .e-list-item.e-selected.e-checklist,
.e-listboxtool-wrapper .e-list-item.e-selected.e-checklist {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.e-listbox-wrapper .e-list-item.e-focused, .e-listbox-wrapper .e-list-item.e-focused.e-selected.e-checklist,
.e-listbox-container .e-list-item.e-focused,
.e-listbox-container .e-list-item.e-focused.e-selected.e-checklist,
.e-listboxtool-wrapper .e-list-item.e-focused,
.e-listboxtool-wrapper .e-list-item.e-focused.e-selected.e-checklist {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}

.e-listbox-wrapper .e-list-item.e-focused .e-checkbox-wrapper .e-frame.e-check, .e-listbox-wrapper .e-list-item.e-focused .e-css.e-checkbox-wrapper .e-frame.e-check,
.e-listbox-container .e-list-item.e-focused .e-checkbox-wrapper .e-frame.e-check,
.e-listbox-container .e-list-item.e-focused .e-css.e-checkbox-wrapper .e-frame.e-check,
.e-listboxtool-wrapper .e-list-item.e-focused .e-checkbox-wrapper .e-frame.e-check,
.e-listboxtool-wrapper .e-list-item.e-focused .e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: #e3165b;
  border-color: transparent;
  color: #fff;
}

.e-listbox-wrapper .e-list-group-item,
.e-listbox-container .e-list-group-item,
.e-listboxtool-wrapper .e-list-group-item {
  background-color: #fafafa;
  border-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
  font-size: 15px;
}

.e-listbox-wrapper .e-selectall-parent,
.e-listbox-container .e-selectall-parent,
.e-listboxtool-wrapper .e-selectall-parent {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}

.e-listbox-wrapper .e-sortableclone.e-ripple .e-ripple-element,
.e-listbox-container .e-sortableclone.e-ripple .e-ripple-element,
.e-listboxtool-wrapper .e-sortableclone.e-ripple .e-ripple-element {
  background-color: transparent;
}

.e-listbox-wrapper.e-list-template .e-list-wrapper,
.e-listbox-container.e-list-template .e-list-wrapper,
.e-listboxtool-wrapper.e-list-template .e-list-wrapper {
  height: inherit;
  position: relative;
}
.e-listbox-wrapper.e-list-template .e-list-wrapper:not(.e-list-multi-line),
.e-listbox-container.e-list-template .e-list-wrapper:not(.e-list-multi-line),
.e-listboxtool-wrapper.e-list-template .e-list-wrapper:not(.e-list-multi-line) {
  padding: 0.2667em 1.0666em;
}
.e-listbox-wrapper.e-list-template .e-list-wrapper.e-list-multi-line,
.e-listbox-container.e-list-template .e-list-wrapper.e-list-multi-line,
.e-listboxtool-wrapper.e-list-template .e-list-wrapper.e-list-multi-line {
  padding: 1.0666em;
}
.e-listbox-wrapper.e-list-template .e-list-wrapper.e-list-multi-line .e-list-item-header,
.e-listbox-container.e-list-template .e-list-wrapper.e-list-multi-line .e-list-item-header,
.e-listboxtool-wrapper.e-list-template .e-list-wrapper.e-list-multi-line .e-list-item-header {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  overflow: hidden;
  padding: 0.115em 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-listbox-wrapper.e-list-template .e-list-wrapper.e-list-multi-line .e-list-content,
.e-listbox-container.e-list-template .e-list-wrapper.e-list-multi-line .e-list-content,
.e-listboxtool-wrapper.e-list-template .e-list-wrapper.e-list-multi-line .e-list-content {
  color: rgba(0, 0, 0, 0.54);
  display: block;
  font-size: 13px;
  margin: 0;
  padding: 0.115em 0;
  word-wrap: break-word;
}
.e-listbox-wrapper.e-list-template .e-list-wrapper.e-list-multi-line .e-list-content:not(.e-text-overflow),
.e-listbox-container.e-list-template .e-list-wrapper.e-list-multi-line .e-list-content:not(.e-text-overflow),
.e-listboxtool-wrapper.e-list-template .e-list-wrapper.e-list-multi-line .e-list-content:not(.e-text-overflow) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-listbox-wrapper.e-list-template .e-list-wrapper.e-list-avatar .e-avatar,
.e-listbox-container.e-list-template .e-list-wrapper.e-list-avatar .e-avatar,
.e-listboxtool-wrapper.e-list-template .e-list-wrapper.e-list-avatar .e-avatar {
  height: 2.6667em;
  left: 1.0667em;
  position: absolute;
  top: 0.2666em;
  width: 2.6667em;
}
.e-listbox-wrapper.e-list-template .e-list-wrapper.e-list-avatar:not(.e-list-badge),
.e-listbox-container.e-list-template .e-list-wrapper.e-list-avatar:not(.e-list-badge),
.e-listboxtool-wrapper.e-list-template .e-list-wrapper.e-list-avatar:not(.e-list-badge) {
  padding-left: 4.8em;
  padding-right: 1.0666em;
}
.e-listbox-wrapper.e-list-template .e-list-wrapper.e-list-avatar-right:not(.e-list-badge),
.e-listbox-container.e-list-template .e-list-wrapper.e-list-avatar-right:not(.e-list-badge),
.e-listboxtool-wrapper.e-list-template .e-list-wrapper.e-list-avatar-right:not(.e-list-badge) {
  padding-left: 1.0666em;
  padding-right: 4.8em;
}
.e-listbox-wrapper.e-list-template .e-list-wrapper.e-list-avatar-right:not(.e-list-badge) .e-avatar,
.e-listbox-container.e-list-template .e-list-wrapper.e-list-avatar-right:not(.e-list-badge) .e-avatar,
.e-listboxtool-wrapper.e-list-template .e-list-wrapper.e-list-avatar-right:not(.e-list-badge) .e-avatar {
  height: 2.6667em;
  position: absolute;
  right: 1.0667em;
  top: 0.2666em;
  width: 2.6667em;
}
.e-listbox-wrapper.e-list-template .e-list-wrapper.e-list-multi-line.e-list-avatar .e-avatar,
.e-listbox-container.e-list-template .e-list-wrapper.e-list-multi-line.e-list-avatar .e-avatar,
.e-listboxtool-wrapper.e-list-template .e-list-wrapper.e-list-multi-line.e-list-avatar .e-avatar {
  top: 1.0666em;
}
.e-listbox-wrapper.e-list-template .e-list-wrapper.e-list-multi-line.e-list-avatar-right:not(.e-list-badge) .e-avatar,
.e-listbox-container.e-list-template .e-list-wrapper.e-list-multi-line.e-list-avatar-right:not(.e-list-badge) .e-avatar,
.e-listboxtool-wrapper.e-list-template .e-list-wrapper.e-list-multi-line.e-list-avatar-right:not(.e-list-badge) .e-avatar {
  top: 1.0666em;
}
.e-listbox-wrapper.e-list-template .e-list-wrapper.e-list-badge .e-badge,
.e-listbox-container.e-list-template .e-list-wrapper.e-list-badge .e-badge,
.e-listboxtool-wrapper.e-list-template .e-list-wrapper.e-list-badge .e-badge {
  font-size: 12px;
  height: 1.6666em;
  line-height: 1.8666em;
  padding: 0;
  position: absolute;
  right: 1.33em;
  top: 50%;
  transform: translateY(-50%);
  width: 2.5em;
}
.e-listbox-wrapper.e-list-template .e-list-wrapper.e-list-badge.e-list-avatar,
.e-listbox-container.e-list-template .e-list-wrapper.e-list-badge.e-list-avatar,
.e-listboxtool-wrapper.e-list-template .e-list-wrapper.e-list-badge.e-list-avatar {
  padding-left: 4.8em;
  padding-right: 4.1333em;
}
.e-listbox-wrapper.e-list-template .e-list-wrapper.e-list-badge:not(.e-list-avatar),
.e-listbox-container.e-list-template .e-list-wrapper.e-list-badge:not(.e-list-avatar),
.e-listboxtool-wrapper.e-list-template .e-list-wrapper.e-list-badge:not(.e-list-avatar) {
  padding-left: 1.0666em;
  padding-right: 4.1333em;
}
.e-listbox-wrapper.e-list-template .e-list-wrapper:not(.e-list-multi-line) .e-list-content,
.e-listbox-container.e-list-template .e-list-wrapper:not(.e-list-multi-line) .e-list-content,
.e-listboxtool-wrapper.e-list-template .e-list-wrapper:not(.e-list-multi-line) .e-list-content {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0.72em 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-listbox-wrapper.e-list-template .e-list-item.e-list-item:hover .e-list-item-header,
.e-listbox-container.e-list-template .e-list-item.e-list-item:hover .e-list-item-header,
.e-listboxtool-wrapper.e-list-template .e-list-item.e-list-item:hover .e-list-item-header {
  color: rgba(0, 0, 0, 0.87);
}
.e-listbox-wrapper.e-list-template .e-list-item.e-list-item:hover .e-list-content,
.e-listbox-container.e-list-template .e-list-item.e-list-item:hover .e-list-content,
.e-listboxtool-wrapper.e-list-template .e-list-item.e-list-item:hover .e-list-content {
  color: rgba(0, 0, 0, 0.87);
}
.e-listbox-wrapper.e-list-template .e-list-item.e-selected .e-list-item-header,
.e-listbox-container.e-list-template .e-list-item.e-selected .e-list-item-header,
.e-listboxtool-wrapper.e-list-template .e-list-item.e-selected .e-list-item-header {
  color: rgba(0, 0, 0, 0.87);
}
.e-listbox-wrapper.e-list-template .e-list-item.e-selected .e-list-content,
.e-listbox-container.e-list-template .e-list-item.e-selected .e-list-content,
.e-listboxtool-wrapper.e-list-template .e-list-item.e-selected .e-list-content {
  color: rgba(0, 0, 0, 0.87);
}
.e-listbox-wrapper.e-rtl.e-list-template .e-list-wrapper.e-list-avatar .e-avatar,
.e-listbox-container.e-rtl.e-list-template .e-list-wrapper.e-list-avatar .e-avatar,
.e-listboxtool-wrapper.e-rtl.e-list-template .e-list-wrapper.e-list-avatar .e-avatar {
  left: inherit;
  right: 1.0667em;
}
.e-listbox-wrapper.e-rtl.e-list-template .e-list-wrapper.e-list-avatar:not(.e-list-badge),
.e-listbox-container.e-rtl.e-list-template .e-list-wrapper.e-list-avatar:not(.e-list-badge),
.e-listboxtool-wrapper.e-rtl.e-list-template .e-list-wrapper.e-list-avatar:not(.e-list-badge) {
  padding-left: 1.0666em;
  padding-right: 4.8em;
}
.e-listbox-wrapper.e-rtl.e-list-template .e-list-wrapper.e-list-avatar-right:not(.e-list-badge),
.e-listbox-container.e-rtl.e-list-template .e-list-wrapper.e-list-avatar-right:not(.e-list-badge),
.e-listboxtool-wrapper.e-rtl.e-list-template .e-list-wrapper.e-list-avatar-right:not(.e-list-badge) {
  padding-left: 4.8em;
  padding-right: 1.0666em;
}
.e-listbox-wrapper.e-rtl.e-list-template .e-list-wrapper.e-list-avatar-right:not(.e-list-badge) .e-avatar,
.e-listbox-container.e-rtl.e-list-template .e-list-wrapper.e-list-avatar-right:not(.e-list-badge) .e-avatar,
.e-listboxtool-wrapper.e-rtl.e-list-template .e-list-wrapper.e-list-avatar-right:not(.e-list-badge) .e-avatar {
  left: 1.0667em;
  right: inherit;
}
.e-listbox-wrapper.e-rtl.e-list-template .e-list-wrapper.e-list-badge .e-badge,
.e-listbox-container.e-rtl.e-list-template .e-list-wrapper.e-list-badge .e-badge,
.e-listboxtool-wrapper.e-rtl.e-list-template .e-list-wrapper.e-list-badge .e-badge {
  left: 1.33em;
  right: inherit;
}
.e-listbox-wrapper.e-rtl.e-list-template .e-list-wrapper.e-list-badge.e-list-avatar,
.e-listbox-container.e-rtl.e-list-template .e-list-wrapper.e-list-badge.e-list-avatar,
.e-listboxtool-wrapper.e-rtl.e-list-template .e-list-wrapper.e-list-badge.e-list-avatar {
  padding-left: 4.1333em;
  padding-right: 4.8em;
}
.e-listbox-wrapper.e-rtl.e-list-template .e-list-wrapper.e-list-badge:not(.e-list-avatar),
.e-listbox-container.e-rtl.e-list-template .e-list-wrapper.e-list-badge:not(.e-list-avatar),
.e-listboxtool-wrapper.e-rtl.e-list-template .e-list-wrapper.e-list-badge:not(.e-list-avatar) {
  padding-left: 4.1333em;
  padding-right: 1.0666em;
}

.e-listbox-container.e-filter-list .e-list-parent {
  height: 100%;
}

.e-listbox-container.e-filter-list .e-selectall-parent + .e-list-parent {
  height: 100%;
}

.e-listbox-container .e-selectall-parent + .e-list-parent {
  height: 100%;
}

.e-listbox-container.e-filter-list .e-list-wrap {
  height: calc(100% - 43px) !important; /* stylelint-disable-line declaration-no-important */
}

.e-listbox-container.e-filter-list .e-selectall-parent + .e-list-wrap {
  height: calc(100% - 36px) !important; /* stylelint-disable-line declaration-no-important */
}

.e-listbox-container .e-selectall-parent + .e-list-wrap {
  height: calc(100% - 36px) !important; /* stylelint-disable-line declaration-no-important */
}

/* stylelint-disable property-no-vendor-prefix */
@keyframes material-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fabric-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}