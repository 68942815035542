.e-badge {
  background: #fafafa;
  border-color: transparent;
  border-radius: 0.25em;
  box-shadow: 0 0 0 2px transparent;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 0.75em;
  font-weight: 500;
  line-height: 1;
  overflow: hidden;
  padding: 0.25em 0.4em 0.25em 0.4em;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  vertical-align: middle;
}
.e-badge:hover {
  text-decoration: none;
}
.e-badge.e-badge-pill {
  border-radius: 5em;
}
.e-badge.e-badge-notification {
  border-radius: 1em;
  font-size: 12px;
  height: 18px;
  left: 100%;
  line-height: 18px;
  min-width: 24px;
  padding: 0 8px 0 8px;
  position: absolute;
  top: -10px;
  width: auto;
}
.e-badge.e-badge-notification.e-badge-ghost {
  line-height: 16px;
}
.e-badge.e-badge-circle {
  border-radius: 50%;
  height: 1.834em;
  line-height: 1.834em;
  min-width: 0;
  padding: 0;
  width: 1.834em;
}
.e-badge.e-badge-circle.e-badge-ghost {
  line-height: 1.8em;
}
.e-badge.e-badge-overlap {
  position: absolute;
  top: -10px;
  transform: translateX(-50%);
}
.e-badge.e-badge-dot {
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
  height: 6px;
  left: 100%;
  line-height: 1;
  margin: 0;
  min-width: 0;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: -3px;
  width: 6px;
}
.e-badge.e-badge-bottom.e-badge-dot {
  bottom: 3px;
  position: absolute;
  top: auto;
}
.e-badge.e-badge-bottom.e-badge-notification {
  bottom: -3px;
  position: absolute;
  top: auto;
}
button .e-badge {
  line-height: 0.9;
  position: relative;
  top: -2px;
}
button .e-badge.e-badge-circle {
  height: 2em;
  line-height: 2em;
  width: 2em;
}
button .e-badge.e-badge-circle.e-badge-ghost {
  line-height: 1.9em;
}

.e-badge.e-badge-primary:not(.e-badge-ghost):not([href]), .e-badge.e-badge-primary[href]:not(.e-badge-ghost) {
  background-color: #3f51b5;
  color: #fff;
}
.e-badge.e-badge-secondary:not(.e-badge-ghost):not([href]), .e-badge.e-badge-secondary[href]:not(.e-badge-ghost) {
  background-color: #e3165b;
  color: #fff;
}
.e-badge.e-badge-success:not(.e-badge-ghost):not([href]), .e-badge.e-badge-success[href]:not(.e-badge-ghost) {
  background-color: #4d841d;
  color: #fff;
}
.e-badge.e-badge-danger:not(.e-badge-ghost):not([href]), .e-badge.e-badge-danger[href]:not(.e-badge-ghost) {
  background-color: #d64113;
  color: #fff;
}
.e-badge.e-badge-warning:not(.e-badge-ghost):not([href]), .e-badge.e-badge-warning[href]:not(.e-badge-ghost) {
  background-color: #c15700;
  color: #fff;
}
.e-badge.e-badge-info:not(.e-badge-ghost):not([href]), .e-badge.e-badge-info[href]:not(.e-badge-ghost) {
  background-color: #0378d5;
  color: #fff;
}
.e-badge.e-badge-light:not(.e-badge-ghost):not([href]), .e-badge.e-badge-light[href]:not(.e-badge-ghost) {
  background-color: #fff;
  color: #000;
}
.e-badge.e-badge-dark:not(.e-badge-ghost):not([href]), .e-badge.e-badge-dark[href]:not(.e-badge-ghost) {
  background-color: #303030;
  color: #fff;
}
.e-badge.e-badge-primary[href]:not(.e-badge-ghost):hover {
  background-color: #32408f;
}
.e-badge.e-badge-secondary[href]:not(.e-badge-ghost):hover {
  background-color: #b51148;
}
.e-badge.e-badge-success[href]:not(.e-badge-ghost):hover {
  background-color: #355a14;
}
.e-badge.e-badge-danger[href]:not(.e-badge-ghost):hover {
  background-color: #a7330f;
}
.e-badge.e-badge-warning[href]:not(.e-badge-ghost):hover {
  background-color: #8e4000;
}
.e-badge.e-badge-info[href]:not(.e-badge-ghost):hover {
  background-color: #025ca3;
}
.e-badge.e-badge-light[href]:not(.e-badge-ghost):hover {
  background-color: #e6e6e6;
}
.e-badge.e-badge-dark[href]:not(.e-badge-ghost):hover {
  background-color: #171717;
}
.e-badge.e-badge-primary[href].e-badge-ghost:hover {
  border-color: #2b387c;
  color: #2b387c;
}
.e-badge.e-badge-secondary[href].e-badge-ghost:hover {
  border-color: #9d0f3f;
  color: #9d0f3f;
}
.e-badge.e-badge-success[href].e-badge-ghost:hover {
  border-color: #28450f;
  color: #28450f;
}
.e-badge.e-badge-danger[href].e-badge-ghost:hover {
  border-color: #902c0d;
  color: #902c0d;
}
.e-badge.e-badge-warning[href].e-badge-ghost:hover {
  border-color: #753500;
  color: #753500;
}
.e-badge.e-badge-info[href].e-badge-ghost:hover {
  border-color: #024e8a;
  color: #024e8a;
}
.e-badge.e-badge-light[href].e-badge-ghost:hover {
  border-color: #d9d9d9;
  color: #d9d9d9;
}
.e-badge.e-badge-dark[href].e-badge-ghost:hover {
  border-color: #0a0a0a;
  color: #0a0a0a;
}
.e-badge.e-badge-ghost.e-badge-primary {
  background-color: transparent;
  border: 1px solid #3f51b5;
  color: #3f51b5;
}
.e-badge.e-badge-ghost.e-badge-secondary {
  background-color: transparent;
  border: 1px solid #e3165b;
  color: #e3165b;
}
.e-badge.e-badge-ghost.e-badge-success {
  background-color: transparent;
  border: 1px solid #4d841d;
  color: #4d841d;
}
.e-badge.e-badge-ghost.e-badge-danger {
  background-color: transparent;
  border: 1px solid #d64113;
  color: #d64113;
}
.e-badge.e-badge-ghost.e-badge-warning {
  background-color: transparent;
  border: 1px solid #c15700;
  color: #c15700;
}
.e-badge.e-badge-ghost.e-badge-info {
  background-color: transparent;
  border: 1px solid #0378d5;
  color: #0378d5;
}
.e-badge.e-badge-ghost.e-badge-light {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.e-badge.e-badge-ghost.e-badge-dark {
  background-color: transparent;
  border: 1px solid #303030;
  color: #303030;
}

.e-toast .e-toast-close-icon::before {
  content: "\e7fc";
  font-family: "e-icons";
}
.e-toast .e-toast-success-icon::before {
  content: "\ea84";
  font-family: "e-icons";
}
.e-toast .e-toast-error-icon::before {
  content: "\e208";
  font-family: "e-icons";
}
.e-toast .e-toast-info-icon::before {
  content: "\e607";
  font-family: "e-icons";
}
.e-toast .e-toast-warning-icon::before {
  content: "\e92f";
  font-family: "e-icons";
}

/*! toast layout */
/* stylelint-disable property-no-vendor-prefix */
.e-bigger .e-toast-container.e-toast-top-left,
.e-toast-container.e-bigger.e-toast-top-left {
  left: 10px;
  top: 10px;
}
.e-bigger .e-toast-container.e-toast-bottom-left,
.e-toast-container.e-bigger.e-toast-bottom-left {
  bottom: 10px;
  left: 10px;
}
.e-bigger .e-toast-container.e-toast-top-right,
.e-toast-container.e-bigger.e-toast-top-right {
  right: 10px;
  top: 10px;
}
.e-bigger .e-toast-container.e-toast-bottom-right,
.e-toast-container.e-bigger.e-toast-bottom-right {
  bottom: 10px;
  right: 10px;
}
.e-bigger .e-toast-container.e-toast-bottom-center,
.e-toast-container.e-bigger.e-toast-bottom-center {
  bottom: 10px;
}
.e-bigger .e-toast-container.e-toast-bottom-center .e-toast,
.e-toast-container.e-bigger.e-toast-bottom-center .e-toast {
  margin: 0 auto 10px;
}
.e-bigger .e-toast-container.e-toast-top-center,
.e-toast-container.e-bigger.e-toast-top-center {
  top: 10px;
}
.e-bigger .e-toast-container.e-toast-top-center .e-toast,
.e-toast-container.e-bigger.e-toast-top-center .e-toast {
  margin: 0 auto 10px;
}
.e-bigger .e-toast-container.e-toast-full-width,
.e-toast-container.e-bigger.e-toast-full-width {
  left: 0;
  right: 0;
}
.e-bigger .e-toast-container.e-toast-full-width .e-toast,
.e-toast-container.e-bigger.e-toast-full-width .e-toast {
  margin: 0 auto 10px;
}
.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-message .e-toast-actions,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-message .e-toast-actions {
  text-align: left;
}
.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-message .e-toast-actions > *,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-message .e-toast-actions > * {
  margin-left: initial;
  margin-right: 10px;
}
.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-close-icon,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-close-icon {
  margin-left: initial;
  margin-right: auto;
}
.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-icon,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-icon {
  margin-left: 14px;
  margin-right: initial;
}
.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-progress,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-progress {
  left: auto;
  right: 0;
}
.e-bigger .e-toast-container .e-toast,
.e-toast-container.e-bigger .e-toast {
  border-radius: 2px;
  font-size: 14px;
  margin: 0 0 10px;
  min-height: 48px;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-content,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-content {
  padding: 14px 0 10px 0;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-content:first-child,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-content:first-child {
  padding: 0;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-content:last-child,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-content:last-child {
  padding-bottom: 0;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-content + .e-toast-actions,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-content + .e-toast-actions {
  padding: 0;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-actions,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-actions {
  padding: 10px 0 0 0;
  text-align: right;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-actions > *,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-actions > * {
  margin-left: 10px;
}
.e-bigger .e-toast-container .e-toast .e-toast-close-icon,
.e-toast-container.e-bigger .e-toast .e-toast-close-icon {
  font-size: 14px;
  height: 24px;
  width: 24px;
}
.e-bigger .e-toast-container .e-toast .e-toast-icon,
.e-toast-container.e-bigger .e-toast .e-toast-icon {
  font-size: 18px;
  height: 24px;
  margin-right: 14px;
  width: 24px;
}
.e-bigger .e-toast-container .e-toast .e-toast-progress,
.e-toast-container.e-bigger .e-toast .e-toast-progress {
  height: 4px;
}

.e-toast-container {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-direction: column;
      flex-direction: column;
  position: relative;
}
.e-toast-container.e-toast-top-left {
  left: 10px;
  top: 10px;
}
.e-toast-container.e-toast-bottom-left {
  bottom: 10px;
  left: 10px;
}
.e-toast-container.e-toast-top-right {
  right: 10px;
  top: 10px;
}
.e-toast-container.e-toast-bottom-right {
  bottom: 10px;
  right: 10px;
}
.e-toast-container.e-toast-bottom-center {
  bottom: 10px;
  pointer-events: none;
  right: 0;
  width: 100%;
}
.e-toast-container.e-toast-bottom-center .e-toast {
  margin: 0 auto 10px;
  pointer-events: auto;
}
.e-toast-container.e-toast-top-center {
  pointer-events: none;
  right: 0;
  top: 10px;
  width: 100%;
}
.e-toast-container.e-toast-top-center .e-toast {
  margin: 0 auto 10px;
  pointer-events: auto;
}
.e-toast-container.e-toast-full-width {
  left: 0;
  right: 0;
  width: 100%;
}
.e-toast-container.e-toast-full-width .e-toast {
  margin: 0 auto 10px;
  width: 96%;
}
.e-toast-container.e-rtl .e-toast .e-toast-actions {
  text-align: left;
}
.e-toast-container.e-rtl .e-toast .e-toast-actions > * {
  margin-left: initial;
  margin-right: 10px;
}
.e-toast-container.e-rtl .e-toast .e-toast-close-icon {
  margin-left: initial;
  margin-right: auto;
}
.e-toast-container.e-rtl .e-toast .e-toast-icon {
  margin-left: 14px;
  margin-right: initial;
}
.e-toast-container.e-rtl .e-toast .e-toast-progress {
  left: auto;
  right: 0;
}
.e-toast-container .e-toast {
  border-radius: 2px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  margin: 0 0 10px;
  overflow: hidden;
  padding: 14px;
  position: relative;
}
.e-toast-container .e-toast .e-toast-icon,
.e-toast-container .e-toast .e-toast-message {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.e-toast-container .e-toast > * {
  word-break: break-word;
  word-wrap: break-word;
}
.e-toast-container .e-toast .e-toast-message {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  -ms-flex-direction: column;
      flex-direction: column;
  overflow: hidden;
  width: inherit;
}
.e-toast-container .e-toast .e-toast-message .e-toast-title,
.e-toast-container .e-toast .e-toast-message .e-toast-content {
  overflow: hidden;
  text-overflow: ellipsis;
}
.e-toast-container .e-toast .e-toast-message .e-toast-title:first-child,
.e-toast-container .e-toast .e-toast-message .e-toast-content:first-child {
  padding: 0;
}
.e-toast-container .e-toast .e-toast-message .e-toast-title:last-child,
.e-toast-container .e-toast .e-toast-message .e-toast-content:last-child {
  padding-bottom: 0;
}
.e-toast-container .e-toast .e-toast-message .e-toast-title > *,
.e-toast-container .e-toast .e-toast-message .e-toast-content > * {
  overflow: hidden;
  text-overflow: ellipsis;
}
.e-toast-container .e-toast .e-toast-message .e-toast-title {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.5px;
}
.e-toast-container .e-toast .e-toast-message .e-toast-content {
  padding: 14px 0 10px 0;
  word-break: break-word;
  word-wrap: break-word;
}
.e-toast-container .e-toast .e-toast-message .e-toast-content + .e-toast-actions {
  padding-top: 0;
}
.e-toast-container .e-toast .e-toast-message .e-toast-actions {
  margin: 1px;
  padding: 10px 0 0 0;
  text-align: right;
}
.e-toast-container .e-toast .e-toast-message .e-toast-actions > * {
  margin-left: 10px;
}
.e-toast-container .e-toast .e-toast-close-icon {
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  height: 24px;
  -ms-flex-pack: center;
      justify-content: center;
  margin-left: auto;
  width: 24px;
  opacity: 0.7;
}
.e-toast-container .e-toast .e-toast-icon {
  -ms-flex-align: center;
      align-items: center;
  font-size: 18px;
  height: 24px;
  -ms-flex-pack: center;
      justify-content: center;
  margin-right: 14px;
  width: 24px;
}
.e-toast-container .e-toast .e-toast-progress {
  bottom: 0;
  height: 4px;
  left: 0;
  position: absolute;
}

.e-content-placeholder.e-toast.e-placeholder-toast {
  background-size: 400px 100px;
  min-height: 100px;
}

.e-bigger .e-content-placeholder.e-toast.e-placeholder-toast,
.e-bigger.e-content-placeholder.e-toast.e-placeholder-toast {
  background-size: 400px 100px;
  min-height: 100px;
}

.e-blazor-toast-hidden {
  visibility: hidden;
}

.e-toast-container .e-toast .e-toast-close-icon.blazor-toast-close-icon {
  background-color: transparent;
  border-color: transparent;
  border-radius: 50%;
  bottom: 6px;
  height: 20px;
  position: relative;
  width: 22px;
}

.e-toast-container .e-toast {
  background-color: #303030;
  border: none;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  color: #fff;
}
.e-toast-container .e-toast .e-toast-close-icon {
  color: #fff;
}
.e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon, .e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon:focus, .e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon:active {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  outline: 0;
}
.e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon:focus, .e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon:hover {
  background-color: #e0e0e0;
  color: #000;
}
.e-toast-container .e-toast .e-toast-close-icon.e-icons:hover,
.e-toast-container .e-toast .e-toast-close-icon.e-icons:focus {
  background-color: #e0e0e0;
  border-radius: 50%;
  color: #000;
}
.e-toast-container .e-toast.e-toast-success {
  background-color: #4d831e;
  color: #fff;
}
.e-toast-container .e-toast.e-toast-success .e-toast-message .e-toast-title {
  color: #fff;
}
.e-toast-container .e-toast.e-toast-success .e-toast-message .e-toast-content {
  color: #fff;
}
.e-toast-container .e-toast.e-toast-success .e-toast-icon {
  color: #fff;
}
.e-toast-container .e-toast.e-toast-success .e-toast-close-icon {
  color: #fff;
}
.e-toast-container .e-toast.e-toast-success:hover {
  background-color: #4d831e;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.26);
  color: #fff;
}
.e-toast-container .e-toast.e-toast-info {
  background-color: #0677d5;
  color: #fff;
}
.e-toast-container .e-toast.e-toast-info .e-toast-message .e-toast-title {
  color: #fff;
}
.e-toast-container .e-toast.e-toast-info .e-toast-message .e-toast-content {
  color: #fff;
}
.e-toast-container .e-toast.e-toast-info .e-toast-icon {
  color: #fff;
}
.e-toast-container .e-toast.e-toast-info .e-toast-close-icon {
  color: #fff;
}
.e-toast-container .e-toast.e-toast-info:hover {
  background-color: #0677d5;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.26);
  color: #fff;
}
.e-toast-container .e-toast.e-toast-warning {
  background-color: #c15601;
  color: #fff;
}
.e-toast-container .e-toast.e-toast-warning .e-toast-message .e-toast-title {
  color: #fff;
}
.e-toast-container .e-toast.e-toast-warning .e-toast-message .e-toast-content {
  color: #fff;
}
.e-toast-container .e-toast.e-toast-warning .e-toast-icon {
  color: #fff;
}
.e-toast-container .e-toast.e-toast-warning .e-toast-close-icon {
  color: #fff;
}
.e-toast-container .e-toast.e-toast-warning:hover {
  background-color: #c15601;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.26);
  color: #fff;
}
.e-toast-container .e-toast.e-toast-danger {
  background-color: #d74113;
  color: #fff;
}
.e-toast-container .e-toast.e-toast-danger .e-toast-message .e-toast-title {
  color: #fff;
}
.e-toast-container .e-toast.e-toast-danger .e-toast-message .e-toast-content {
  color: #fff;
}
.e-toast-container .e-toast.e-toast-danger .e-toast-icon {
  color: #fff;
}
.e-toast-container .e-toast.e-toast-danger .e-toast-close-icon {
  color: #fff;
}
.e-toast-container .e-toast.e-toast-danger:hover {
  background-color: #d74113;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.26);
  color: #fff;
}
.e-toast-container .e-toast:hover {
  background-color: #212121;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.26);
}
.e-toast-container .e-toast .e-toast-icon {
  color: #fff;
}
.e-toast-container .e-toast .e-toast-message .e-toast-title {
  color: #fff;
}
.e-toast-container .e-toast .e-toast-message .e-toast-content {
  color: #fff;
}
.e-toast-container .e-toast .e-toast-progress {
  background-color: #e3165b;
}
.e-toast-container .e-toast .e-toast-actions .e-btn {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color: #ef578a;
}
.e-toast-container .e-toast .e-toast-actions .e-btn:hover {
  background-color: transparent;
  color: #ef578a;
}
.e-toast-container .e-toast .e-toast-actions .e-btn:focus {
  background-color: transparent;
  color: #ef578a;
}
.e-toast-container .e-toast .e-toast-actions .e-btn:active {
  background-color: transparent;
  color: #ef578a;
}

/*! Message's fluent theme wise override definitions and variables */
.e-message .e-msg-icon::before,
.e-message .e-msg-close-icon::before {
  font-family: "e-icons";
}
.e-message .e-msg-icon::before {
  content: "\e607";
}
.e-message .e-msg-close-icon::before {
  content: "\e7fc";
}
.e-message.e-success .e-msg-icon::before {
  content: "\ea84";
}
.e-message.e-error .e-msg-icon::before {
  content: "\e208";
}
.e-message.e-info .e-msg-icon::before {
  content: "\e607";
}
.e-message.e-warning .e-msg-icon::before {
  content: "\e92f";
}

.e-message {
  border: 1px solid;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-weight: 400;
  font-style: normal;
  padding: 9px 7px;
  display: -ms-flexbox;
  display: flex;
}
.e-message.e-hidden {
  display: none;
}
.e-message .e-msg-icon,
.e-message .e-msg-close-icon {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 16px;
}
.e-message .e-msg-icon {
  font-size: 16px;
  height: 16px;
  margin: 3px 4px;
}
.e-message .e-msg-close-icon {
  border-width: 0;
  cursor: pointer;
  font-size: 14px;
  margin: 0 0 0 auto;
  padding: 3px 4px;
  height: 22px;
}
.e-message .e-msg-content {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 22px;
  padding: 0 4px;
  vertical-align: top;
}
.e-message.e-content-right {
  -ms-flex-pack: end;
      justify-content: flex-end;
}
.e-message.e-content-center .e-msg-content-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1;
  -ms-flex-pack: center;
      justify-content: center;
}
.e-message.e-content-right .e-msg-close-icon, .e-message.e-content-center .e-msg-close-icon {
  margin: 3px 4px;
}
.e-message.e-rtl .e-msg-close-icon {
  margin: 0 auto 0 0;
}

.e-bigger .e-message,
.e-message.e-bigger {
  padding: 11px 9px;
}
.e-bigger .e-message .e-msg-icon,
.e-bigger .e-message .e-msg-close-icon,
.e-message.e-bigger .e-msg-icon,
.e-message.e-bigger .e-msg-close-icon {
  line-height: 18px;
}
.e-bigger .e-message .e-msg-icon,
.e-message.e-bigger .e-msg-icon {
  font-size: 18px;
  height: 18px;
  margin: 3px 6px;
}
.e-bigger .e-message .e-msg-close-icon,
.e-message.e-bigger .e-msg-close-icon {
  font-size: 16px;
  margin: 0 2px 0 auto;
  padding: 3px 4px;
  height: 24px;
}
.e-bigger .e-message .e-msg-content,
.e-message.e-bigger .e-msg-content {
  line-height: 24px;
  font-size: 16px;
  padding: 0 6px;
}
.e-bigger .e-message.e-content-right .e-msg-close-icon, .e-bigger .e-message.e-content-center .e-msg-close-icon,
.e-message.e-bigger.e-content-right .e-msg-close-icon,
.e-message.e-bigger.e-content-center .e-msg-close-icon {
  margin: 3px 6px;
}
.e-bigger .e-message.e-rtl .e-msg-close-icon,
.e-message.e-bigger.e-rtl .e-msg-close-icon {
  margin: 0 auto 0 2px;
}

.e-message {
  background: #eee;
  border-color: #eee;
  color: #212121;
}
.e-message .e-msg-icon {
  color: #212121;
}
.e-message .e-msg-close-icon {
  background: transparent;
  color: #424242;
}
.e-message .e-msg-close-icon:hover, .e-message .e-msg-close-icon:focus {
  background-color: rgba(66, 66, 66, 0.12);
  box-shadow: 0 0 0 2px rgba(66, 66, 66, 0.12);
  border-radius: 50%;
}
.e-message.e-success {
  background-color: #ceedb3;
  border-color: #ceedb3;
  color: #212121;
}
.e-message.e-success .e-msg-icon {
  color: #4d831e;
}
.e-message.e-success .e-msg-close-icon {
  color: #424242;
}
.e-message.e-success .e-msg-close-icon:hover, .e-message.e-success .e-msg-close-icon:focus {
  background-color: rgba(66, 66, 66, 0.12);
  box-shadow: 0 0 0 2px rgba(66, 66, 66, 0.12);
}
.e-message.e-warning {
  background-color: #ffddc2;
  border-color: #ffddc2;
  color: #212121;
}
.e-message.e-warning .e-msg-icon {
  color: #c15601;
}
.e-message.e-warning .e-msg-close-icon {
  color: #424242;
}
.e-message.e-warning .e-msg-close-icon:hover, .e-message.e-warning .e-msg-close-icon:focus {
  background-color: rgba(66, 66, 66, 0.12);
  box-shadow: 0 0 0 2px rgba(66, 66, 66, 0.12);
}
.e-message.e-info {
  background-color: #dceffe;
  border-color: #dceffe;
  color: #212121;
}
.e-message.e-info .e-msg-icon {
  color: #0677d5;
}
.e-message.e-info .e-msg-close-icon {
  color: #424242;
}
.e-message.e-info .e-msg-close-icon:hover, .e-message.e-info .e-msg-close-icon:focus {
  background-color: rgba(66, 66, 66, 0.12);
  box-shadow: 0 0 0 2px rgba(66, 66, 66, 0.12);
}
.e-message.e-error {
  background-color: #fdf0ec;
  border-color: #fdf0ec;
  color: #212121;
}
.e-message.e-error .e-msg-icon {
  color: #d74113;
}
.e-message.e-error .e-msg-close-icon {
  color: #424242;
}
.e-message.e-error .e-msg-close-icon:hover, .e-message.e-error .e-msg-close-icon:focus {
  background-color: rgba(66, 66, 66, 0.12);
  box-shadow: 0 0 0 2px rgba(66, 66, 66, 0.12);
}
.e-message.e-outlined {
  background: transparent;
  border-color: #bdbdbd;
  color: #212121;
}
.e-message.e-outlined .e-msg-icon {
  color: #212121;
}
.e-message.e-outlined .e-msg-close-icon {
  color: #424242;
}
.e-message.e-outlined .e-msg-close-icon:hover, .e-message.e-outlined .e-msg-close-icon:focus {
  background-color: rgba(66, 66, 66, 0.12);
  box-shadow: 0 0 0 2px rgba(66, 66, 66, 0.12);
}
.e-message.e-outlined.e-success {
  border-color: #4d831e;
  color: #4d831e;
}
.e-message.e-outlined.e-success .e-msg-icon {
  color: #4d831e;
}
.e-message.e-outlined.e-success .e-msg-close-icon {
  color: #424242;
}
.e-message.e-outlined.e-success .e-msg-close-icon:hover, .e-message.e-outlined.e-success .e-msg-close-icon:focus {
  background-color: rgba(66, 66, 66, 0.12);
  box-shadow: 0 0 0 2px rgba(66, 66, 66, 0.12);
}
.e-message.e-outlined.e-warning {
  border-color: #c15601;
  color: #c15601;
}
.e-message.e-outlined.e-warning .e-msg-icon {
  color: #c15601;
}
.e-message.e-outlined.e-warning .e-msg-close-icon {
  color: #424242;
}
.e-message.e-outlined.e-warning .e-msg-close-icon:hover, .e-message.e-outlined.e-warning .e-msg-close-icon:focus {
  background-color: rgba(66, 66, 66, 0.12);
  box-shadow: 0 0 0 2px rgba(66, 66, 66, 0.12);
}
.e-message.e-outlined.e-info {
  border-color: #0677d5;
  color: #0677d5;
}
.e-message.e-outlined.e-info .e-msg-icon {
  color: #0677d5;
}
.e-message.e-outlined.e-info .e-msg-close-icon {
  color: #424242;
}
.e-message.e-outlined.e-info .e-msg-close-icon:hover, .e-message.e-outlined.e-info .e-msg-close-icon:focus {
  background-color: rgba(66, 66, 66, 0.12);
  box-shadow: 0 0 0 2px rgba(66, 66, 66, 0.12);
}
.e-message.e-outlined.e-error {
  border-color: #d74113;
  color: #d74113;
}
.e-message.e-outlined.e-error .e-msg-icon {
  color: #d74113;
}
.e-message.e-outlined.e-error .e-msg-close-icon {
  color: #424242;
}
.e-message.e-outlined.e-error .e-msg-close-icon:hover, .e-message.e-outlined.e-error .e-msg-close-icon:focus {
  background-color: rgba(66, 66, 66, 0.12);
  box-shadow: 0 0 0 2px rgba(66, 66, 66, 0.12);
}
.e-message.e-filled {
  background: #212121;
  border-color: #212121;
  color: #fff;
}
.e-message.e-filled .e-msg-icon {
  color: #fff;
}
.e-message.e-filled .e-msg-close-icon {
  color: #fff;
}
.e-message.e-filled .e-msg-close-icon:hover, .e-message.e-filled .e-msg-close-icon:focus {
  background-color: rgba(255, 255, 255, 0.12);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.12);
}
.e-message.e-filled.e-success {
  background-color: #355a14;
  border-color: #355a14;
  color: #fff;
}
.e-message.e-filled.e-success .e-msg-icon {
  color: #fff;
}
.e-message.e-filled.e-success .e-msg-close-icon {
  color: #fff;
}
.e-message.e-filled.e-success .e-msg-close-icon:hover, .e-message.e-filled.e-success .e-msg-close-icon:focus {
  background-color: rgba(255, 255, 255, 0.12);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.12);
}
.e-message.e-filled.e-warning {
  background-color: #8e3f01;
  border-color: #8e3f01;
  color: #fff;
}
.e-message.e-filled.e-warning .e-msg-icon {
  color: #fff;
}
.e-message.e-filled.e-warning .e-msg-close-icon {
  color: #fff;
}
.e-message.e-filled.e-warning .e-msg-close-icon:hover, .e-message.e-filled.e-warning .e-msg-close-icon:focus {
  background-color: rgba(255, 255, 255, 0.12);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.12);
}
.e-message.e-filled.e-info {
  background-color: #044d8b;
  border-color: #044d8b;
  color: #fff;
}
.e-message.e-filled.e-info .e-msg-icon {
  color: #fff;
}
.e-message.e-filled.e-info .e-msg-close-icon {
  color: #fff;
}
.e-message.e-filled.e-info .e-msg-close-icon:hover, .e-message.e-filled.e-info .e-msg-close-icon:focus {
  background-color: rgba(255, 255, 255, 0.12);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.12);
}
.e-message.e-filled.e-error {
  background-color: #79250b;
  border-color: #79250b;
  color: #fff;
}
.e-message.e-filled.e-error .e-msg-icon {
  color: #fff;
}
.e-message.e-filled.e-error .e-msg-close-icon {
  color: #fff;
}
.e-message.e-filled.e-error .e-msg-close-icon:hover, .e-message.e-filled.e-error .e-msg-close-icon:focus {
  background-color: rgba(255, 255, 255, 0.12);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.12);
}

.e-bigger .e-message .e-msg-close-icon:hover, .e-bigger .e-message .e-msg-close-icon:focus,
.e-message.e-bigger .e-msg-close-icon:hover,
.e-message.e-bigger .e-msg-close-icon:focus {
  box-shadow: 0 0 0 4px rgba(66, 66, 66, 0.12);
}
.e-bigger .e-message.e-success .e-msg-close-icon:hover, .e-bigger .e-message.e-success .e-msg-close-icon:focus,
.e-message.e-bigger.e-success .e-msg-close-icon:hover,
.e-message.e-bigger.e-success .e-msg-close-icon:focus {
  box-shadow: 0 0 0 4px rgba(66, 66, 66, 0.12);
}
.e-bigger .e-message.e-warning .e-msg-close-icon:hover, .e-bigger .e-message.e-warning .e-msg-close-icon:focus,
.e-message.e-bigger.e-warning .e-msg-close-icon:hover,
.e-message.e-bigger.e-warning .e-msg-close-icon:focus {
  box-shadow: 0 0 0 4px rgba(66, 66, 66, 0.12);
}
.e-bigger .e-message.e-info .e-msg-close-icon:hover, .e-bigger .e-message.e-info .e-msg-close-icon:focus,
.e-message.e-bigger.e-info .e-msg-close-icon:hover,
.e-message.e-bigger.e-info .e-msg-close-icon:focus {
  box-shadow: 0 0 0 4px rgba(66, 66, 66, 0.12);
}
.e-bigger .e-message.e-error .e-msg-close-icon:hover, .e-bigger .e-message.e-error .e-msg-close-icon:focus,
.e-message.e-bigger.e-error .e-msg-close-icon:hover,
.e-message.e-bigger.e-error .e-msg-close-icon:focus {
  box-shadow: 0 0 0 4px rgba(66, 66, 66, 0.12);
}
.e-bigger .e-message.e-outlined .e-msg-close-icon:hover, .e-bigger .e-message.e-outlined .e-msg-close-icon:focus,
.e-message.e-bigger.e-outlined .e-msg-close-icon:hover,
.e-message.e-bigger.e-outlined .e-msg-close-icon:focus {
  box-shadow: 0 0 0 4px rgba(66, 66, 66, 0.12);
}
.e-bigger .e-message.e-outlined.e-success .e-msg-close-icon:hover, .e-bigger .e-message.e-outlined.e-success .e-msg-close-icon:focus,
.e-message.e-bigger.e-outlined.e-success .e-msg-close-icon:hover,
.e-message.e-bigger.e-outlined.e-success .e-msg-close-icon:focus {
  box-shadow: 0 0 0 4px rgba(66, 66, 66, 0.12);
}
.e-bigger .e-message.e-outlined.e-warning .e-msg-close-icon:hover, .e-bigger .e-message.e-outlined.e-warning .e-msg-close-icon:focus,
.e-message.e-bigger.e-outlined.e-warning .e-msg-close-icon:hover,
.e-message.e-bigger.e-outlined.e-warning .e-msg-close-icon:focus {
  box-shadow: 0 0 0 4px rgba(66, 66, 66, 0.12);
}
.e-bigger .e-message.e-outlined.e-info .e-msg-close-icon:hover, .e-bigger .e-message.e-outlined.e-info .e-msg-close-icon:focus,
.e-message.e-bigger.e-outlined.e-info .e-msg-close-icon:hover,
.e-message.e-bigger.e-outlined.e-info .e-msg-close-icon:focus {
  box-shadow: 0 0 0 4px rgba(66, 66, 66, 0.12);
}
.e-bigger .e-message.e-outlined.e-error .e-msg-close-icon:hover, .e-bigger .e-message.e-outlined.e-error .e-msg-close-icon:focus,
.e-message.e-bigger.e-outlined.e-error .e-msg-close-icon:hover,
.e-message.e-bigger.e-outlined.e-error .e-msg-close-icon:focus {
  box-shadow: 0 0 0 4px rgba(66, 66, 66, 0.12);
}
.e-bigger .e-message.e-filled .e-msg-close-icon:hover, .e-bigger .e-message.e-filled .e-msg-close-icon:focus,
.e-message.e-bigger.e-filled .e-msg-close-icon:hover,
.e-message.e-bigger.e-filled .e-msg-close-icon:focus {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.12);
}
.e-bigger .e-message.e-filled.e-success .e-msg-close-icon:hover, .e-bigger .e-message.e-filled.e-success .e-msg-close-icon:focus,
.e-message.e-bigger.e-filled.e-success .e-msg-close-icon:hover,
.e-message.e-bigger.e-filled.e-success .e-msg-close-icon:focus {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.12);
}
.e-bigger .e-message.e-filled.e-warning .e-msg-close-icon:hover, .e-bigger .e-message.e-filled.e-warning .e-msg-close-icon:focus,
.e-message.e-bigger.e-filled.e-warning .e-msg-close-icon:hover,
.e-message.e-bigger.e-filled.e-warning .e-msg-close-icon:focus {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.12);
}
.e-bigger .e-message.e-filled.e-info .e-msg-close-icon:hover, .e-bigger .e-message.e-filled.e-info .e-msg-close-icon:focus,
.e-message.e-bigger.e-filled.e-info .e-msg-close-icon:hover,
.e-message.e-bigger.e-filled.e-info .e-msg-close-icon:focus {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.12);
}
.e-bigger .e-message.e-filled.e-error .e-msg-close-icon:hover, .e-bigger .e-message.e-filled.e-error .e-msg-close-icon:focus,
.e-message.e-bigger.e-filled.e-error .e-msg-close-icon:hover,
.e-message.e-bigger.e-filled.e-error .e-msg-close-icon:focus {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.12);
}

.e-skeleton {
  display: inline-block;
  position: relative;
  overflow: hidden;
  border: none;
}
.e-skeleton.e-skeleton-text, .e-skeleton.e-skeleton-square, .e-skeleton.e-skeleton-rectangle {
  border-radius: 2px;
}

.e-skeleton.e-skeleton-circle {
  border-radius: 50%;
}

.e-skeleton.e-visible-none {
  display: none;
}

.e-skeleton.e-shimmer-wave::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: calc(200% + 200px);
  animation: e-shimmer-wave 2s infinite;
  animation-timing-function: ease-in-out;
}

.e-skeleton.e-shimmer-fade {
  animation: e-shimmer-fade 2s infinite;
  animation-timing-function: ease-in-out;
}

.e-skeleton.e-shimmer-pulse {
  animation: e-shimmer-pulse 2s infinite;
  animation-timing-function: ease-in-out;
}

.e-rtl .e-shimmer-wave::after,
.e-rtl.e-shimmer-wave::after {
  animation: e-shimmer-wave-rtl 2s infinite;
}

@keyframes e-shimmer-wave {
  to {
    transform: translateX(calc(50% + 100px));
  }
}
@keyframes e-shimmer-wave-rtl {
  from {
    transform: translateX(calc(50% + 100px));
  }
}
@keyframes e-shimmer-fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes e-shimmer-pulse {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.975);
  }
  100% {
    transform: scale(1);
  }
}
.e-skeleton.e-shimmer-wave::after {
  background-image: linear-gradient(90deg, transparent calc(50% - 100px), #e6e1e5 50%, transparent calc(50% + 100px));
}

.e-skeleton.e-skeleton-text, .e-skeleton.e-skeleton-square, .e-skeleton.e-skeleton-rectangle, .e-skeleton.e-skeleton-circle {
  background-color: #eee;
}